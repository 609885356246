import {
  Tab,
  Tabs,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import SearchListTable from "./SearchListTable";
import { SearchHeader } from "./SearchHeader";
import ToggleSwitch from "./ToggleSwitch";
import { useDispatch, useSelector } from "react-redux";
import {
  getSearchProspects,
  setProspectsUserFilter,
  setProspectsUserFilterDetail,
} from "../../../../store/campaignSlice";
import { useParams } from "react-router-dom";
import OpenProfileTable from "./OpenProfileTable";
import AlreadyConnectedTable from "./AlreadyConnectedTable";
import { InfoOutlined } from "@mui/icons-material";
import { MySwitch } from "../time-zone/myComponents";
import OutOfNetworkTable from "./OutOfNetworkTable";
import SearchURLDialog from "./SearchURLDialog";

export const SearchList = () => {
  const dispatch = useDispatch();
  const { id, name } = useParams();
  const [value, setValue] = useState("1");
  const [showTable, setShowTable] = useState(false);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const user = useSelector((state) => state.userProfile.userProfile);
  const themeMode = useSelector((state) => state.themeMode.mode);
  const campaign = useSelector((state) => state.campaign.campaign);
  const prospectUser = useSelector((state) => state.campaign.prospectUser);
  const prospectUserDetail = useSelector(
    (state) => state.campaign.prospectUserDetail
  );

  const searchProspectsMeta = useSelector(
    (state) => state.campaign.searchProspectsMeta
  );
  const searchProspects = useSelector(
    (state) => state.campaign.searchProspects
  );

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    dispatch(setProspectsUserFilter(campaign?.admins?.[0]?.uuid));
    dispatch(setProspectsUserFilterDetail(campaign?.admins?.[0]));

    dispatch(
      getSearchProspects({
        campaign: id,
        user_uuid: campaign?.admins?.[0]?.uuid,
      })
    ).then(() => setShowTable(true));
  }, []);

  return (
    <div>
      <div className="lg:flex lg:w-3/4 w-full lg:space-x-6 items-center search-list-info-bar mt-4 py-2 px-2">
        <div className="imp-note flex items-center space-x-2">
          <img src="/high.png" alt="high_priority" />{" "}
          <span>IMPORTANT NOTE </span>
        </div>
        <div className="imp-note-det">
          Old prospects will not be removed when entering new URLs.
        </div>
      </div>
      <SearchHeader handleClickOpenURL={handleClickOpen} />
      <div
        className={
          themeMode === "dark"
            ? "p-8 my-8 dashboard-table-layout"
            : "p-8 my-8 dashboard-table-layout-light"
        }
      >
        <div className="pb-8 flex flex-col lg:flex-row items-center lg:space-x-16 space-y-4 lg:space-y-0">
          <div
            className={
              themeMode === "dark"
                ? "search-header-title"
                : "search-header-title-light"
            }
          >
            {prospectUserDetail?.first_name}’s Search List{" "}
          </div>

          <div className="lg:pt-1">
            <div className="flex space-x-2 items-center">
              <div
                className={
                  themeMode === "dark"
                    ? "dashboard-subtitle"
                    : "dashboard-subtitle-light"
                }
              >
                Send InMail to Open Profiles{" "}
              </div>
              <ToggleSwitch />
              {/* <MySwitch /> */}
            </div>
          </div>
          <OutlinedInput
            id="outlined-adornment-password"
            className={
              themeMode === "dark" ? "dashboard-field" : "dashboard-field-light"
            }
            onChange={(e) => {
              dispatch(
                getSearchProspects({
                  campaign: id,
                  user_uuid: prospectUser,
                  searchQuery: e.target.value,
                })
              );
            }}
            size="small"
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "& .MuiInputBase-input": {
                color: themeMode === "dark" ? "white" : "black",
              },
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" edge="end">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M5.2 0C6.57913 0 7.90177 0.547855 8.87696 1.52304C9.85215 2.49823 10.4 3.82087 10.4 5.2C10.4 6.488 9.928 7.672 9.152 8.584L9.368 8.8H10L14 12.8L12.8 14L8.8 10V9.368L8.584 9.152C7.672 9.928 6.488 10.4 5.2 10.4C3.82087 10.4 2.49823 9.85215 1.52304 8.87696C0.547855 7.90177 0 6.57913 0 5.2C0 3.82087 0.547855 2.49823 1.52304 1.52304C2.49823 0.547855 3.82087 0 5.2 0ZM5.2 1.6C3.2 1.6 1.6 3.2 1.6 5.2C1.6 7.2 3.2 8.8 5.2 8.8C7.2 8.8 8.8 7.2 8.8 5.2C8.8 3.2 7.2 1.6 5.2 1.6Z"
                      fill="white"
                    />
                  </svg>{" "}
                </IconButton>
              </InputAdornment>
            }
            placeholder="Search"
          />
        </div>
        <Tabs
          value={value}
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleChangeTab}
          aria-label="secondary tabs example"
          sx={{
            "& .MuiTab-root": {
              color: themeMode === "dark" ? "#FFF" : "black",
              fontWeight: "bold",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              textTransform: "none",
            },
            "& .MuiTab-root.Mui-selected": {
              color: themeMode === "dark" ? "#FFF" : "black",
              fontWeight: "bold",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
            },
            "& .MuiTabs-indicator": {
              backgroundColor: themeMode === "dark" ? "whitesmoke" : "#0C0404",
            },
          }}
        >
          <Tab
            value="1"
            label={`Prospects (${searchProspectsMeta?.total_count})`}
          />
          <Tab value="2" label="Prospects with open profile" />
          <Tab
            value="3"
            label={
              <span className="flex items-center">
                <span>Prospects - Already Connected</span>
                <Tooltip title="These prospects are already part of your network. Thus they are disqualified from Prospecting Automation.">
                  {" "}
                  <IconButton>
                    {" "}
                    <InfoOutlined
                      style={{
                        color: themeMode === "dark" ? "white" : "black",
                        fontSize: "17px",
                      }}
                    />{" "}
                  </IconButton>
                </Tooltip>
              </span>
            }
          />
          <Tab
            value="4"
            label={
              <span className="flex items-center">
                <span>Prospects - Out of Network</span>
                <Tooltip title="These prospects are out of your network. Thus they are disqualified from Prospecting Automation.">
                  {" "}
                  <IconButton>
                    {" "}
                    <InfoOutlined
                      style={{
                        color: themeMode === "dark" ? "white" : "black",
                        fontSize: "17px",
                      }}
                    />{" "}
                  </IconButton>
                </Tooltip>
              </span>
            }
          />
        </Tabs>

        <div className="py-8" style={{ color: "white" }}>
          {value === "1" && showTable && <SearchListTable />}
          {value === "2" && showTable && <OpenProfileTable />}
          {value === "3" && showTable && <AlreadyConnectedTable />}
          {value === "4" && showTable && <OutOfNetworkTable />}
        </div>
      </div>
      <SearchURLDialog
        open={open}
        handleClose={handleClose}
        handleClickOpen={handleClickOpen}
      />
    </div>
  );
};
