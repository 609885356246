import React from "react";
import { useSelector } from "react-redux";
import { Grid, Typography, Box, Button } from "@mui/material";
import theme from "../../../../../Theme/theme";

const blackBkg =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/blackcurvedBkg.png";
const whiteBkg =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/whitecurvedBkg.png";
const chartArrow =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/chartArrow.png";
const chartArrowBlack =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/chartArrrowBlack.png";

const GradientCircle = ({ value }) => {
  const themeMode = useSelector((state) => state.themeMode.mode);
  const gradientStop = `${100 - value}%`;
  return (
    <Box position="relative" display="inline-flex">
      <Grid
        sx={{
          // width: { md: "150px", sm: "150px", xs: "80px" },
          // height: { md: "150px", sm: "150px", xs: "80px" },
          width: "150px",
          height: "150px",
          background: `linear-gradient(0deg, rgba(255,255,255,1) 0%,rgba(0,0,0,0) ${gradientStop}, rgba(255,0,0,1) 100%)`,
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          [theme.breakpoints.down(1330)]: {
            width:"200px",
            height:"200px",
          },
          [theme.breakpoints.down(900)]: {
            width: "150px",
            height: "150px",
          },
          [theme.breakpoints.down(620)]: {
            width: "115px",
            height: "115px",
          },
          [theme.breakpoints.down(440)]: {
            width: "80px",
            height: "80px",
          }
        }}
      >
        <Grid
          sx={{
            background: themeMode === "dark" ? "#000000" : "#E3E3E3",
            // width: { md: "116.67px", sm: "116.67px", xs: "60px" },
            // height: { md: "116.67px", sm: "116.67px", xs: "60px" },
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "116.67px",
            height: "116.67px",
            [theme.breakpoints.down(1330)]: {
              width: "150px",
              height: "150px",
            },
            [theme.breakpoints.down(900)]: {
              width: "116.670px",
              height: "116.67px",
            },
            [theme.breakpoints.down(620)]: {
              width: "80px",
              height: "80px",
            },
            [theme.breakpoints.down(440)]: {
              width: "60px",
              height: "60px",
            }

          }}
        >
          <Typography
            sx={{
              fontSize: { md: "40px", sm: "40px", xs: "20px" },
              fontWeight: "700",
              color: themeMode === "dark" ? "#FFF" : "#000",
              [theme.breakpoints.down(1330)]: {
               fontSize:"40px",
              },
              [theme.breakpoints.down(620)]: {
                fontSize: "30px",
              },
              [theme.breakpoints.down(440)]: {
                fontSize: "20px",
              },
            }}
          >
            {value}%
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const PerformanceCard = ({
  btnBkgColor,
  title,
  about,
  value,
  max,
  rateType,
  rateType2,
  maxType,
  count,
  invitations,
  interested,
  conclusion,
  arrowClick,
  showRate,
  rateValue,
}) => {
  const themeMode = useSelector((state) => state.themeMode.mode);
  const findProgress = (value, max) => {
    if (max === 0) return 0;
    const result = (value / max) * 100;
    return result.toFixed(0);
  };

  return (
    <Grid
      sx={{
        backgroundImage:
          themeMode === "dark" ? `url(${blackBkg})` : `url(${whiteBkg})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left center",
        width: "100%",
        height: { md: "400px", sm: "350px", xs: "500px" },
        padding: "35px 20px",
        color: themeMode === "dark" ? "white" : "#000",
        display: "flex",
        alignItems: { md: "flex-end", sm: "center", xs: "flex-end" },
        justifyContent: "space-between",
        flexDirection: "column",
        boxSizing: "border-box",
        position: "relative",
        borderRadius: { md: "", sm: "", xs: "30px" },
        borderRight: {
          md: "1px solid transparent",
          sm: "1px solid transparent",
          xs: "1px solid #FFF",
        },
        backgroundSize: "contain",
        [theme.breakpoints.down(1330)]: {
          backgroundSize: "100% 100%",
        },
        [theme.breakpoints.down(600)]: {
          backgroundSize: "cover",
        }

      }}
      >
      <Button
        onClick={arrowClick}
        disableRipple
        sx={{
          borderRadius: "50%",
          padding: "15px",
          background: themeMode === "dark" ? "#00000099" : "#DEDEDE99",
          border: "1px solid #FFF",
          position: "absolute",
          width: "60px",
          height: "60px",
          boxShadow: themeMode === "dark" ? "" : "4px 4px 4px 0px #00000014",
          "&:hover": {
            background: themeMode === "dark" ? "#330101" : "",
            transform: "scale(1.05)",
            border:
            themeMode === "dark" ? "1px solid #FFF" : "1px solid #999999",
          },
          top: { md: "7%", sm: "-10%", xs: "4%" },
          left: { md: "-1%", sm: "-10%", xs: "10%" },
          [theme.breakpoints.down(1330)]: {
            top: "0px",
            left: "10px"
          },
          [theme.breakpoints.down(900)]: {
            left: "0px"
          },
          [theme.breakpoints.down(600)]: {
            top:"20px",
            left: "27px "
          }
        }}
      >
        <img src={themeMode === "dark" ? chartArrow : chartArrowBlack} alt="" />
      </Button>
      <Grid
        container
        sx={{
          width: { md: "100%", sm: "100%", xs: "100%" },
          height: { md: "70%", sm: "70%", xs: "100%" },
          padding: { md: "10px", sm: "10px", xs: "0px" },
        }}
      >
        <Grid
          item
          sm={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: { md: "flex-end", sm: "center", xs: "flex-end" },
            width: "100%",
          }}
        >
          <Typography
            variant="h6"
            mt={{ md: 2, sm: 0, xs: 9 }}
            sx={{
              color: themeMode === "dark" ? "#FFF" : "#000",
              fontWeight: "700",
              marginBottom: "10px",
              textAlign: { md: "left", sm: "center", xs: "right" },
              width: { md: "85%", sm: "85%", xs: "100%" },
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: themeMode === "dark" ? "#FFFFFFB2" : "#000000B2",
              marginBottom: { md: "20px", sm: "10px", xs: "10px" },
              textAlign: { md: "left", sm: "center" },
              width: { md: "85%", sm: "100%", xs: "100%" },
              fontSize: { md: "14px", sm: "14px", xs: "10px" },
            }}
          >
            {about}
          </Typography>
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          my={{ md: 3, sm: 1.5, xs: 0 }}
        >
          <Grid item>
            <GradientCircle value={rateValue} />
          </Grid>
          <Grid item sx={{ marginLeft: 2 }} mt={{ md: 0, sm: 0, xs: 1 }}>
            <Typography
              variant="h6"
              style={{
                color: themeMode === "dark" ? "#FFF" : "#000",
                fontWeight: "700",
              }}
            >
              {rateType} Rate
            </Typography>
            <Typography
              variant="body2"
              style={{
                color: themeMode === "dark" ? "#FFF" : "#000",
                marginBottom: "5px",
                textTransform: "lowercase",
              }}
            >
              {count} {rateType2} with
            </Typography>
            <Typography
              variant="body2"
              style={{
                color: themeMode === "dark" ? "#FFF" : "#000",
                marginBottom: "5px",
              }}
            >
              {invitations} {maxType} = {interested}%
            </Typography>
            <Typography
              variant="body2"
              style={{ color: themeMode === "dark" ? "'#FFFFFFB2'" : "#000" }}
            >
              {conclusion}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PerformanceCard;
