import { React, useState } from "react";
import { Grid, Button, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Backdrop, MenuItem, Select, TextField } from "@mui/material";
import { cancelSubscription } from "../../store/subcriptionsSlice";
import { getPayments } from "../../store/paymentsSlice";
import { error, success } from "../../store/alertSlice";

export default function CancelSubscriptionDialog({ open, handleClose }) {
  const themeMode = useSelector((state) => state.themeMode.mode);
  const dispatch = useDispatch();
  const organization = useSelector((state) => state.settings.organization);
  const user = useSelector((state) => state.userProfile.userProfile);
  const emailExists = organization?.admins?.some(
    (member) => member.email === user.email
  );

  const handleCancelSubscription = async () => {
    dispatch(cancelSubscription()).then((resp) => {
      if (resp?.payload?.status === 200) {
        dispatch(getPayments({ page: 1, per_page: 10 })); 
        dispatch(success("Subscription Canceled"));
      }
    });
    handleClose();
  }

  return (
    <Backdrop>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          background:
            themeMode === "dark" ? "rgba(0, 0, 0, 0.30)" : "#0000004D",
          backdropFilter: "blur(4px)",
        }}
        PaperProps={{
          sx: {
            backgroundColor:
              themeMode === "dark" ? "rgba(0, 0, 0, 0.60)" : "#FFFFFFBB", // Inner background color
            borderRadius: "10px",
            border: themeMode === "dark" ? "1px solid white" : "1px solid #000",
            color: themeMode === "dark" ? "white" : "#000",
            paddingLeft: "14px",
            paddingRight: "14px",
          },
        }}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle
          id="alert-dialog-title"
          className="w-full flex justify-end cursor-pointer pb-3"
          onClick={handleClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              d="M1.5 1L20.5 16.5M20.5 1L1.5 16.5"
              stroke={themeMode === "dark" ? "white" : "#000"}
              stroke-width="2"
            />
          </svg>{" "}
        </DialogTitle>

        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              color: themeMode === "dark" ? "white" : "#000",
              textAlign: "center",
              overflowX: "hidden",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                fontSize: "16px",
                fontWeight: "700",
                flexWrap: "wrap",
              }}
            >
              Cancel Subscription Plan
            </Typography>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                fontSize: "12px",
                fontWeight: "400",
                flexWrap: "wrap",
              }}
            >
              Currently, you are subscribed to the{" "}
              <span style={{ fontWeight: "700", margin: "0px 5px" }}>
                semi-annual plan
              </span>{" "}
              in FacoonLabs
            </Typography>

            {emailExists ? (
              <Grid
                item
                my={3}
                md={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "12px",
                    fontWeight: "400",
                    flexWrap: "wrap",
                  }}
                >
                  If you proceed then all members of organization including
                  admin's subscription will be cancelled
                </Typography>
              </Grid>
            ) : (
              <Grid
                item
                my={3}
                md={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "14px",
                    fontWeight: "400",
                    flexWrap: "wrap",
                  }}
                >
                  Please contact your organization admin for further changes
                </Typography>
              </Grid>
            )}

            <Grid
              item
              md={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {emailExists && (
                <Button
                  onClick={handleCancelSubscription}
                  className="mr-8"
                  sx={{
                    color: "#FFF",
                    border:
                      themeMode === "dark"
                        ? "1px solid #FFF"
                        : "1px solid #000",
                    borderRadius: "30px",
                    textTransform: "capitalize",
                    padding: "8px 25px",
                    background: themeMode === "dark" ? "#730101" : "#BE0000",
                    "&:hover": {
                      background:
                        themeMode === "dark" ? "#FF000099" : "#d10202",
                    },
                  }}
                >
                  Confirm
                </Button>
              )}

              <Button
                onClick={handleClose}
                sx={{
                  color: themeMode === "dark" ? "#FFF" : "black",
                  border:
                    themeMode === "dark" ? "1px solid #FFF" : "1px solid #000",
                  borderRadius: "30px",
                  textTransform: "capitalize",
                  padding: "8px 25px",
                  background: "transparent",
                  "&:hover": {
                    background: themeMode === "dark" ? "#FF000099" : "#d10202",
                  },
                }}
              >
                Go Back
              </Button>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
}
