import * as React from "react";
import Button from "@mui/material/Button";
import { ButtonGroup, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import __ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { updateCampaign } from "../../../../store/campaignSlice";
import { error, success } from "../../../../store/alertSlice";

const _ = __.runInContext();

_.mixin({
  setIn: (state, name, value) => {
    return _.setWith(_.clone(state), name, value, _.clone);
  },
});

export default function FollowUpSendTime({ open, handleClose }) {
  const dispatch = useDispatch();
  const [selected, setSelected] = React.useState("hours");
  const themeMode = useSelector((state) => state.themeMode.mode);
  const campaign = useSelector((state) => state.campaign.campaign);

  const buttons = [
    <Button
      key="Minutes"
      onClick={() => setSelected("minutes")}
      className={
        selected === "minutes"
          ? themeMode === "dark"
            ? "follow-up-time-btn-selected px-6"
            : "follow-up-time-btn-selected-light px-6"
          : themeMode === "dark"
          ? "follow-up-time-btn-outline px-6"
          : "follow-up-time-btn-outline-light px-6"
      }
    >
      <span
        className={
          selected === "minutes"
            ? themeMode === "dark"
              ? "table-filter-btn-text-light"
              : "table-filter-btn-text"
            : themeMode === "dark"
            ? "table-filter-btn-text"
            : "table-filter-btn-text-light"
        }
      >
        Minutes
      </span>
    </Button>,
    <Button
      key="Hours"
      onClick={() => setSelected("hours")}
      className={
        selected === "hours"
          ? themeMode === "dark"
            ? "follow-up-time-btn-selected px-6"
            : "follow-up-time-btn-selected-light px-6"
          : themeMode === "dark"
          ? "follow-up-time-btn-outline px-6"
          : "follow-up-time-btn-outline-light px-6"
      }
    >
      <span
        className={
          selected === "hours"
            ? themeMode === "dark"
              ? "table-filter-btn-text-light"
              : "table-filter-btn-text"
            : themeMode === "dark"
            ? "table-filter-btn-text"
            : "table-filter-btn-text-light"
        }
      >
        Hours
      </span>
    </Button>,
    <Button
      key="Days"
      onClick={() => setSelected("days")}
      className={
        selected === "days"
          ? themeMode === "dark"
            ? "follow-up-time-btn-selected px-6"
            : "follow-up-time-btn-selected-light px-6"
          : themeMode === "dark"
          ? "follow-up-time-btn-outline px-6"
          : "follow-up-time-btn-outline-light px-6"
      }
    >
      <span
        className={
          selected === "days"
            ? themeMode === "dark"
              ? "table-filter-btn-text-light"
              : "table-filter-btn-text"
            : themeMode === "dark"
            ? "table-filter-btn-text"
            : "table-filter-btn-text-light"
        }
      >
        Days
      </span>
    </Button>,
    <Button
      key="Weeks"
      onClick={() => setSelected("weeks")}
      className={
        selected === "weeks"
          ? themeMode === "dark"
            ? "follow-up-time-btn-selected px-6"
            : "follow-up-time-btn-selected-light px-6"
          : themeMode === "dark"
          ? "follow-up-time-btn-outline px-6"
          : "follow-up-time-btn-outline-light px-6"
      }
    >
      <span
        className={
          selected === "weeks"
            ? themeMode === "dark"
              ? "table-filter-btn-text-light"
              : "table-filter-btn-text"
            : themeMode === "dark"
            ? "table-filter-btn-text"
            : "table-filter-btn-text-light"
        }
      >
        Weeks
      </span>
    </Button>,
  ];
  const { control, watch, reset, handleSubmit, formState, getValues } = useForm(
    {
      mode: "onChange",
      // resolver: yupResolver(schema),
    }
  );

  React.useEffect(() => {
    reset(campaign);
    setSelected(campaign?.follow_up_interval_type);
  }, [reset, campaign]);

  const { isValid, dirtyFields, errors } = formState;

  const formData = watch();

  function onSubmit(data) {
    dispatch(
      updateCampaign({
        ...campaign,
        follow_up_interval_type: selected,
        follow_up_interval_frequency: data.follow_up_interval_frequency,
      })
    ).then((resp) => {
      if (resp?.payload?.status === 200) {
        dispatch(success("Campaign updated"));
      } else {
        dispatch(error(resp.payload.statusText));
      }
    });
  }

  return (
    <div>
      <div>
        <div id="alert-dialog-description" style={{ color: "white" }}>
          <div className="lg:flex lg:space-x-8 pt-4 lg:space-y-0 space-y-4">
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  className={
                    themeMode === "dark"
                      ? "create-campaign-dialog-field w-full lg:w-1/3"
                      : "create-campaign-dialog-field-light w-full lg:w-1/3"
                  }
                  size="small"
                  type="number"
                  // value={2}
                  sx={{
                    // maxWidth: "88px",
                    textAlign: "right",
                    "& .MuiInputBase-input": {
                      color: themeMode === "dark" ? "white" : "black", // Text color
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                        color: themeMode === "dark" ? "white" : "black",
                      },
                    },
                  }}
                />
              )}
              name="follow_up_interval_frequency"
              control={control}
            />
            <ButtonGroup size="large" aria-label="Large button group">
              {buttons}
            </ButtonGroup>
          </div>
          {selected === "minutes" &&
            formData.follow_up_interval_frequency < 60 && (
              <div
                className={
                  themeMode === "dark"
                    ? "dashboard-subtitle py-2"
                    : "dashboard-subtitle-light py-2"
                }
                style={{ color: "red", fontSize: "13px" }}
              >
                Must be atleast 60 when interval type is set to minutes
              </div>
            )}
          <Button
            className="create-button p-2 px-8 mt-4"
            variant="contained"
            disabled={
              selected === "minutes" &&
              formData.follow_up_interval_frequency < 60
            }
            onClick={handleSubmit(onSubmit)}
            style={{ backgroundColor: "white" }}
          >
            <span className="create-button-text" style={{ color: "red" }}>
              Save Follow up
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
}
