import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const { REACT_APP_API_URL } = process.env;

const initialState = {
  value: 0,
  login: [],
  fcmTokens: [],
  fcmToken: null,
};

export const loginUser = createAsyncThunk(
  "login/loginUser",
  async (user_request, { dispatch, getState }) => {
    try {
      const data = await axios.post(
        `${REACT_APP_API_URL}/auth/sign_in`,
        user_request
      );

      return { data: data.data, header: data.headers, status: data.status };
    } catch (err) {
      console.error(err);
      return null;
    }
  }
);

export const getTokens = createAsyncThunk(
  "login/getTokens",
  async (currenToken, { dispatch, getState }) => {
    try {
      const data = await axios.get(
        `${REACT_APP_API_URL}/devices/get_all_fcm_tokens`
      );

      return { data: data.data, status: data.status };
    } catch (err) {
      console.error(err);
      return null;
    }
  }
);

export const addRemoveToken = createAsyncThunk(
  "login/addRemoveToken",
  async (currenToken, { dispatch, getState }) => {
    try {
      const data = await axios.put(
        `${REACT_APP_API_URL}/devices/add_or_remove_fcm_tokens`,
        { user_devices: { fcm_tokens: currenToken } }
      );

      return { data: data.data, status: data.status };
    } catch (err) {
      console.error(err);
      return null;
    }
  }
);

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setFCMToken: (state, action) => {
      state.fcmToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(loginUser.fulfilled, (state, action) => {
        if (action.payload) {
          const { data, header } = action.payload;
          const { email } = data;
          const accessToken = header["access-token"];
          const client = header.client;

          state.login = data;

          localStorage.setItem("client", client);
          localStorage.setItem("uid", email);
          localStorage.setItem("access-token", accessToken);

          const userData = {
            "access-token": accessToken,
            client,
            uid: email,
          };
          localStorage.setItem("user", JSON.stringify(userData));

          axios.defaults.headers["client"] = action.payload.header.client;
          axios.defaults.headers["uid"] = action.payload.data.email;
          axios.defaults.headers["access-token"] =
            action.payload.header["access-token"];
        }
      })
      .addCase(loginUser.rejected, (state, action) => {
        console.error("Login Error:", action.payload || "Failed to login");
      })
      .addCase(getTokens.fulfilled, (state, action) => {
        state.fcmTokens = action.payload?.data?.fcm_tokens;
      });
  },
});

export const { setFCMToken } = loginSlice.actions;

export default loginSlice.reducer;
