import {
  Grid,
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Tabs,
  Tab,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import CampaignTable from "./CampaignTable";
import CreateCampaignDialog from "./CreateCampaignDialog";
import NavbarApp from "../navbar/NavbarApp";
import { useDispatch, useSelector } from "react-redux";
import { getCampaigns, getinActiveCampaigns } from "../../store/campaignSlice";
import InactiveCampaignTable from "./InactiveCampaignTable";
import { SearchOutlined } from "@mui/icons-material";

export const Campaigns = () => {
  const dispatch = useDispatch();
  const campaigns = useSelector((state) => state.campaign.campaigns);
  const inActiveCampaigns = useSelector(
    (state) => state.campaign.inActiveCampaigns
  );

  const themeMode = useSelector((state) => state.themeMode.mode);
  const [inputvalue, setInputValue] = useState("");

  const [type, setType] = useState("");
  const handleChange = (event) => {
    setType(event.target.value);
  };

  const [value, setValue] = useState("one");

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getCampaigns());
    dispatch(getinActiveCampaigns());
  }, []);

  return (
    <>
      <div
        className={themeMode === "dark" ? "dashboard-bg" : "dashboard-bg-light"}
      >
        <div className="py-24 md:px-16 px-8">
          <div
            className={
              themeMode === "dark" ? "dashboard-title" : "dashboard-title-light"
            }
          >
            Campaigns
          </div>
          <div
            className={
              themeMode === "dark"
                ? "dashboard-subtitle py-4"
                : "dashboard-subtitle-light py-4"
            }
          >
            View and manage your campaigns in this page.
          </div>
          <div className="lg:flex justify-between items-center">
            <Grid sx={{ display: "flex", justifyContent: "flex-start", flexDirection: { md: "row", sm: "row", xs: "column" } }}
            //className="lg:flex items-center lg:space-x-6 w-full space-y-6 lg:space-y-0">
            >
              <OutlinedInput
                value={inputvalue}
                onChange={(e) => {
                  setInputValue(e.target.value);
                  if (value === "one") {
                    dispatch(
                      getCampaigns({
                        query: e.target.value,
                      })
                    );
                  } else {
                    dispatch(
                      getinActiveCampaigns({
                        query: e.target.value,
                      })
                    );
                  }
                }}
                id="campaign-search"
                className={
                  themeMode === "dark"
                    ? "dashboard-field"
                    : "dashboard-field-light"
                }
                size="small"
                sx={{
                  width: {md: 300, sm: 300, xs: "100%" } ,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                  "& .MuiInputBase-input": {
                    color: themeMode === "dark" ? "white" : "black",
                  },
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <SearchOutlined
                        style={{
                          color: themeMode === "dark" ? "gray" : "darkgray",
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                }
                placeholder="Search"
              />
              {/* <div
                onClick={() => {
                  setInputValue("");
                  if (value === "one") {
                    dispatch(getCampaigns());
                  } else {
                    dispatch(getinActiveCampaigns());
                  }
                }}
              > */}
                <Button
                  className={
                    themeMode === "dark"
                      ? "dashboard-clear-filters cursor-pointer"
                      : "dashboard-clear-filters-light cursor-pointer"
                  }
                  onClick={() => {
                    setInputValue("");
                    if (value === "one") {
                      dispatch(getCampaigns());
                    } else {
                      dispatch(getinActiveCampaigns());
                    }
                  }}
                sx={{ textTransform: "none", color: themeMode === "dark" ? "#FFF" : "#000", textWrap: "nowrap", marginLeft: { md: 1.5, sm: 2, xs: 0 }, "&:hover": { background: "transparent" } }}
                
                >
                  {" "}
                  Clear Filters
                </Button>
              {/* </div>{" "} */}
            {/* </div> */}
            </Grid>
            <div className="flex w-full lg:w-2/3 justify-end">
              <Button
                className={
                  themeMode === "dark"
                    ? "create-button p-4"
                    : "create-button-light p-4"
                }
                variant="contained"
                onClick={handleClickOpen}
              >
                <span className="create-button-text">Create Campaign</span>
              </Button>
            </div>
          </div>

          <div
            className={
              themeMode === "dark"
                ? "p-8 my-8 dashboard-table-layout"
                : "p-8 my-8 dashboard-table-layout-light"
            }
          >
            <Tabs
              value={value}
              onChange={handleChangeTab}
              aria-label="secondary tabs example"
              sx={{
                "& .MuiTab-root": {
                  color: themeMode === "dark" ? "#FFF" : "black",
                  fontWeight: "bold",
                  color: themeMode === "dark" ? "#FFF" : "black",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  textTransform: "none",
                },
                "& .MuiTab-root.Mui-selected": {
                  color: themeMode === "dark" ? "#FFF" : "black",
                  fontWeight: "bold",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "normal",
                },
                "& .MuiTabs-indicator": {
                  backgroundColor:
                    themeMode === "dark" ? "whitesmoke" : "#0C0404",
                },
              }}
            >
              <Tab value="one" label="Active" />
              <Tab value="two" label="Inactive" />
            </Tabs>

            <div className="py-8" style={{ color: "white" }}>
              {value === "one" && (
                <CampaignTable campaigns={campaigns} key={campaigns} />
              )}
              {value === "two" && (
                <InactiveCampaignTable
                  campaigns={inActiveCampaigns}
                  key={campaigns}
                />
              )}
            </div>
          </div>
        </div>
        <CreateCampaignDialog
          open={open}
          handleClose={handleClose}
          handleClickOpen={handleClickOpen}
        />
      </div>
    </>
  );
};
