import { CheckBox, SmartToyOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Hidden,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getChat,
  getInboxMessages,
  sendChatMessage,
  setShowMessageReminder,
} from "../../../store/messagesSlice";
import { success } from "../../../store/alertSlice";
import moment from "moment";

const CustomIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
  >
    <path
      d="M10 0V1.2L5 6L0 1.2V0H10ZM5 3.736L7.225 1.6H2.775L5 3.736Z"
      fill="white"
    />
  </svg>
);

export const Conversation = () => {
  const dispatch = useDispatch();
  const openedChat = useSelector((state) => state.messages.openedChat);
  const [quickResponse, setQuickResponse] = useState("10");
  const [inputValue, setInputValue] = useState("");
  const [checked, setChecked] = useState(false);
  const user = useSelector((state) => state.userProfile.userProfile);

  const handleChangeChecked = (event) => {
    setChecked(event.target.checked);
    dispatch(setShowMessageReminder(event.target.checked));
  };

  const handleChange = (event) => {
    setQuickResponse(event.target.value);
  };

  return (
    <Box
      className="  flex flex-col justify-between h-screen"
      sx={{ maxHeight: { lg: "450px", md: "450px", sm: "600px", xs: "600px" } }}
    >
      <div className="" style={{ overflowY: "auto" }}>
        {" "}
        {openedChat?.chat_messages
          ?.filter((n) => n.state !== "queued")
          ?.map((message, index) => (
            <Box sx={{ p: 1, paddingLeft: "14px" }}>
              {index > 0 &&
              moment(message.created_at).format("DD MMM") ===
                moment(
                  openedChat?.chat_messages?.[index - 1]?.created_at
                ).format("DD MMM") ? (
                <div className="-mt-4"></div>
              ) : (
                <Box className="dateBox">
                  <Typography className="date received-text">
                    {moment(message.created_at).format("dddd, DD MMMM YYYY")}
                  </Typography>
                </Box>
              )}
              {message?.owner_type === "app_user" ? (
                <Box className="userConversationBox flex flex-col w-5/6">
                  <Box className="userNameTimeBox">
                    <Typography className="received-name">
                      {message?.owner_type === "app_user" ? (
                        <span>
                          {" "}
                          {user.first_name + " " + user.last_name} (You)
                        </span>
                      ) : (
                        <span> {openedChat?.prospect?.name}</span>
                      )}
                    </Typography>
                    <Typography className="received-text">
                      {" "}
                      {moment(message.updated_at).format("hh:mm")}
                    </Typography>
                  </Box>
                  <Typography className="received-text mt-2">
                    {message.message}
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ p: 2 }}>
                  <Box className="customerConversationBox">
                    <Box className="userNameTimeBox">
                      <span className="received-text">
                        {" "}
                        {moment(message.updated_at).format("hh:mm")}
                      </span>
                      <Typography className="received-name">
                        {openedChat?.prospect?.name}
                      </Typography>
                    </Box>
                    <Typography className="received-text mt-2 text-right">
                      {message.message}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          ))}
      </div>

      <Box className="actionBox mx-2 mt-4">
        <TextField
          variant="outlined"
          id="message"
          fullWidth
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          multiline
          rows={4}
          placeholder="Type your message..."
          className="custom-textfield"
          InputProps={{
            style: {
              fontSize: "12px",
            },
            disableUnderline: true,
          }}
        />

        <Hidden mdUp>
          <div className="flex flex-col space-y-4 items-center justify-between">
            <div>
              {" "}
              <Box className="flex items-center">
                <>
                  <Checkbox
                    // className="remind-check-box"
                    checked={checked}
                    onChange={handleChangeChecked}
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#FFF",
                      },
                    }}
                  />
                  <Typography variant="body2" className="reminder-text">
                    In case of no reply, remind me.
                  </Typography>
                </>
              </Box>
            </div>
            <div>
              <Box className="flex items-center">
                <>
                  <Tooltip
                    title="Feature not available now"
                    arrow
                    placement="top"
                  >
                    <span>
                      <Button
                        disabled
                        variant="outlined"
                        startIcon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                          >
                            <path
                              d="M4.36364 1.63636L5.72727 4.63636L8.72727 6L5.72727 7.36364L4.36364 10.3636L3 7.36364L0 6L3 4.63636L4.36364 1.63636ZM4.36364 4.27091L3.81818 5.45455L2.63455 6L3.81818 6.54545L4.36364 7.72909L4.90909 6.54545L6.09273 6L4.90909 5.45455L4.36364 4.27091ZM9.81818 4.36364L9.13091 2.86909L7.63636 2.18182L9.13091 1.5L9.81818 0L10.5 1.5L12 2.18182L10.5 2.86909L9.81818 4.36364ZM9.81818 12L9.13091 10.5055L7.63636 9.81818L9.13091 9.13636L9.81818 7.63636L10.5 9.13636L12 9.81818L10.5 10.5055L9.81818 12Z"
                              fill="white"
                            />
                          </svg>
                        }
                        className="smart-reply-btn py-2"
                      >
                        <span style={{ color: "white" }}>Smart Reply</span>
                      </Button>
                    </span>
                  </Tooltip>
                </>

                <div
                  style={{ border: "1px solid white", borderRadius: "30px" }}
                >
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      className="msg-quick"
                      disableUnderline
                      value={quickResponse}
                      onChange={handleChange}
                      sx={{
                        maxHeight: "16px",
                        margin: 0, // Remove margin
                        padding: 0,
                        "& .MuiSelect-icon": {
                          color: "white", // Customize the icon color
                        },
                      }}
                    >
                      <MenuItem className="" defaultValue value={10}>
                        Quick Response
                      </MenuItem>
                      <MenuItem className="" value={20}>
                        Quick Response
                      </MenuItem>
                      <MenuItem className="" value={30}>
                        Quick Response
                      </MenuItem>
                    </Select>
                  </FormControl>{" "}
                </div>
              </Box>
            </div>
            <div>
              <>
                <Tooltip
                  title={
                    openedChat?.prospect?.automation_enabled === true
                      ? "can't send message due to automation"
                      : "send message"
                  }
                  arrow
                  placement="top"
                >
                  <span>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#FF000099" }}
                      className=" smart-reply-btn py-2 m-0 mb-2"
                      disabled={
                        inputValue === "" ||
                        openedChat?.prospect?.automation_enabled === true
                      }
                      onClick={(e) => {
                        dispatch(
                          sendChatMessage({
                            id: openedChat.uuid,
                            message: inputValue,
                            owner_type: "app_user",
                          })
                        ).then((resp) => {
                          if (resp?.payload?.status === 200) {
                            setInputValue("");
                            dispatch(getChat(openedChat.uuid));
                            dispatch(success("Message sent"));
                          }
                        });
                      }}
                    >
                      <span style={{ fontSize: "13px", color: "white" }}>
                        Send
                      </span>
                    </Button>
                  </span>
                </Tooltip>
              </>
            </div>
          </div>
        </Hidden>

        <Hidden mdDown>
          <Box className="flex-container">
            <Box className="flex-left">
              <>
                <Checkbox
                  // className="remind-check-box"
                  checked={checked}
                  onChange={handleChangeChecked}
                  sx={{
                    color: "white",
                    "&.Mui-checked": {
                      color: "#FFF",
                    },
                  }}
                />
                <Typography variant="body2" className="reminder-text">
                  In case of no reply, remind me.
                </Typography>
              </>
            </Box>
            <Box className="flex-right">
              <>
                <Tooltip
                  title="Feature not available now"
                  arrow
                  placement="top"
                >
                  <span>
                    <Button
                      disabled
                      variant="outlined"
                      startIcon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                        >
                          <path
                            d="M4.36364 1.63636L5.72727 4.63636L8.72727 6L5.72727 7.36364L4.36364 10.3636L3 7.36364L0 6L3 4.63636L4.36364 1.63636ZM4.36364 4.27091L3.81818 5.45455L2.63455 6L3.81818 6.54545L4.36364 7.72909L4.90909 6.54545L6.09273 6L4.90909 5.45455L4.36364 4.27091ZM9.81818 4.36364L9.13091 2.86909L7.63636 2.18182L9.13091 1.5L9.81818 0L10.5 1.5L12 2.18182L10.5 2.86909L9.81818 4.36364ZM9.81818 12L9.13091 10.5055L7.63636 9.81818L9.13091 9.13636L9.81818 7.63636L10.5 9.13636L12 9.81818L10.5 10.5055L9.81818 12Z"
                            fill="white"
                          />
                        </svg>
                      }
                      className="smart-reply-btn py-2"
                    >
                      <span style={{ color: "white" }}>Smart Reply</span>
                    </Button>
                  </span>
                </Tooltip>
              </>

              <div style={{ border: "1px solid white", borderRadius: "30px" }}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    className="msg-quick"
                    disableUnderline
                    value={quickResponse}
                    onChange={handleChange}
                    sx={{
                      maxHeight: "16px",
                      margin: 0, // Remove margin
                      padding: 0,
                      "& .MuiSelect-icon": {
                        color: "white", // Customize the icon color
                      },
                    }}
                  >
                    <MenuItem className="" defaultValue value={10}>
                      Quick Response
                    </MenuItem>
                    <MenuItem className="" value={20}>
                      Quick Response
                    </MenuItem>
                    <MenuItem className="" value={30}>
                      Quick Response
                    </MenuItem>
                  </Select>
                </FormControl>{" "}
              </div>
              <>
                <Tooltip
                  title={
                    openedChat?.prospect?.automation_enabled === true
                      ? "can't send message due to automation"
                      : "send message"
                  }
                  arrow
                  placement="top"
                >
                  <span>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#FF000099" }}
                      className="smart-reply-btn py-2 m-0"
                      disabled={
                        inputValue === "" ||
                        openedChat?.prospect?.automation_enabled === true
                      }
                      onClick={(e) => {
                        dispatch(
                          sendChatMessage({
                            id: openedChat.uuid,
                            message: inputValue,
                            owner_type: "app_user",
                          })
                        ).then((resp) => {
                          if (resp?.payload?.status === 200) {
                            setInputValue("");
                            dispatch(getChat(openedChat.uuid));
                            dispatch(success("Message sent"));
                          }
                        });
                      }}
                    >
                      <span style={{ fontSize: "13px", color: "white" }}>
                        Send
                      </span>
                    </Button>
                  </span>
                </Tooltip>
              </>
            </Box>
          </Box>
        </Hidden>
      </Box>
    </Box>
  );
};
