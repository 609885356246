import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import { Faqs } from "./Faqs";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";

export const HelpCenter = () => {
  return (
    <div>
      <Navbar />
      <div className="dashboard-bg md:px-16 px-8 py-16 pt-24">
        <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 justify-between items-center">
          <img src="https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/helpLogo.png" alt="helo_logo" />
          <OutlinedInput
            id="outlined-adornment-password"
            className="dashboard-field"
            size="small"
            sx={{
              maxHeight: "38px",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "& .MuiInputBase-input": {
                color: "white",
              },
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" edge="end">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M5.2 0C6.57913 0 7.90177 0.547855 8.87696 1.52304C9.85215 2.49823 10.4 3.82087 10.4 5.2C10.4 6.488 9.928 7.672 9.152 8.584L9.368 8.8H10L14 12.8L12.8 14L8.8 10V9.368L8.584 9.152C7.672 9.928 6.488 10.4 5.2 10.4C3.82087 10.4 2.49823 9.85215 1.52304 8.87696C0.547855 7.90177 0 6.57913 0 5.2C0 3.82087 0.547855 2.49823 1.52304 1.52304C2.49823 0.547855 3.82087 0 5.2 0ZM5.2 1.6C3.2 1.6 1.6 3.2 1.6 5.2C1.6 7.2 3.2 8.8 5.2 8.8C7.2 8.8 8.8 7.2 8.8 5.2C8.8 3.2 7.2 1.6 5.2 1.6Z"
                      fill="white"
                    />
                  </svg>{" "}
                </IconButton>
              </InputAdornment>
            }
            placeholder="Search"
          />
        </div>
        <Faqs />
      </div>
      <Footer />
    </div>
  );
};
