import { Avatar, Box, Button, styled, TextField } from "@mui/material";
import FileUploadButton from "./FileUploadButton";
import { DeleteOutline } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getUserProfile,
  updateUserProfile,
} from "../../../store/userProfileSlice";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import __ from "lodash";
import { success } from "../../../store/alertSlice";

const userDp =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/ben.png";

const defaultValues = {
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  image_data_uri: "",
  image_url: "",
};

const schema = yup
  .object()
  .shape({
    first_name: yup.string().required("You must enter first name"),
    image_data_uri: yup.mixed().notRequired(),
    image_url: yup.string().notRequired(),
  })
  .required();

export const UserProfile = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userProfile.userProfile);
  const themeMode = useSelector((state) => state.themeMode.mode);

  const { handleSubmit, register, reset, setValue, control, watch, formState } =
    useForm({
      defaultValues,
      mode: "onSubmit",
      resolver: yupResolver(schema),
    });

  const { errors } = formState;

  useEffect(() => {
    reset({ ...user });
  }, [user, reset]);

  function onSubmit(data) {
    dispatch(updateUserProfile(data)).then((resp) => {
      if (resp.payload.status === 200) {
        dispatch(success("User updated"));
        dispatch(getUserProfile());
      }
    });
  }

  const CustomTextField = styled(TextField)({
    "& .MuiInput-underline:before": {
      borderBottomColor: themeMode === "dark" ? "white" : "#000",
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: themeMode === "dark" ? "white" : "#000",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: themeMode === "dark" ? "white" : "#000",
    },
    "& .MuiInputBase-input": {
      color: themeMode === "dark" ? "white" : "#000",
      fontSize: "13px",
    },
    //   minWidth: "239px",
  });

  return (
    <div>
      <div
        className={`settings-heading ${
          themeMode === "dark" ? "text-light" : "text-dark"
        }`}
      >
        Profile
      </div>
      <div className="py-6">
        <div className="flex text-white space-x-16 items-center mb-10">
          <div
            className={`profile-field-title ${
              themeMode === "dark" ? "text-light" : "text-dark"
            }`}
          >
            First Name:
          </div>
          <Controller
            render={({ field }) => (
              <CustomTextField
                className="md:w-1/2 lg:w-1/4"
                {...field}
                error={!!errors.first_name}
                helperText={errors?.first_name?.message}
                required
                variant="standard"
              />
            )}
            name="first_name"
            control={control}
          />
        </div>
        <div className="flex text-white space-x-16 items-center mb-10">
          <div
            className={`profile-field-title w-1/9 ${
              themeMode === "dark" ? "text-light" : "text-dark"
            }`}
          >
            Last Name:
          </div>
          <Controller
            render={({ field }) => (
              <CustomTextField
                className="md:w-1/2 lg:w-1/4"
                {...field}
                error={!!errors.last_name}
                helperText={errors?.last_name?.message}
                required
                variant="standard"
              />
            )}
            name="last_name"
            control={control}
          />{" "}
        </div>
        <div className="flex text-white space-x-24 items-center mb-10">
          <div
            className={`profile-field-title ${
              themeMode === "dark" ? "text-light" : "text-dark"
            }`}
          >
            Email:
          </div>
          <Controller
            render={({ field }) => (
              <CustomTextField
                className="md:w-1/2 lg:w-1/4"
                {...field}
                error={!!errors.email}
                helperText={errors?.email?.message}
                required
                variant="standard"
              />
            )}
            name="email"
            control={control}
          />
        </div>
        <div className="flex text-white space-x-8 items-center mb-10">
          <div
            className={`profile-field-title ${
              themeMode === "dark" ? "text-light" : "text-dark"
            }`}
          >
            Phone Number:
          </div>
          <Controller
            render={({ field }) => (
              <CustomTextField
                className="md:w-1/2 lg:w-1/4"
                {...field}
                error={!!errors.phone_number}
                helperText={errors?.phone_number?.message}
                required
                variant="standard"
              />
            )}
            name="phone_number"
            control={control}
          />{" "}
        </div>
        <div className="lg:flex text-white lg:space-x-16 space-x-0 items-center mb-10 space-y-4 lg:space-y-0">
          <div
            className={`profile-field-title ${
              themeMode === "dark" ? "text-light" : "text-dark"
            }`}
          >
            Profile Picture:
          </div>
          <Avatar
            alt="Remy Sharp"
            src={
              watch("image_data_uri") ||
              watch("image_url") ||
              watch("linkedin_profile_picture_url")
            }
            sx={{ width: 100, height: 100 }}
          />{" "}
          <div>
            {" "}
            <FileUploadButton setValue={setValue} />
          </div>
          <div>
            <Button
              component="label"
              variant="outlined"
              tabIndex={-1}
              className="py-2 px-4"
              startIcon={<DeleteOutline />}
              sx={{
                borderRadius: "30px",
                border:
                  themeMode === "dark"
                    ? "1px solid white"
                    : "1px solid #00000080",
                color:
                  themeMode === "dark" && user?.image_url === null
                    ? "rgba(255, 255, 255, 0.4)"
                    : themeMode === "dark" && user?.image_url !== null
                    ? "white"
                    : themeMode === "light" && user?.image_url === null
                    ? "black"
                    : "#00000066",
                textTransform: "none",
                "&:hover": {
                  border:
                    themeMode === "dark"
                      ? "1px solid white"
                      : "1px solid #00000080",
                },
              }}
              onClick={() => {
                setValue("remove_image", true);
                setValue("image_data_uri", "");
                setValue("image_url", "");
              }}
            >
              Remove
            </Button>
          </div>
        </div>
        <Button
          className="create-button p-2 px-4"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          <span className="create-button-text">Save</span>
        </Button>
      </div>
    </div>
  );
};
