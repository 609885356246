import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TablePagination } from "@mui/material";
import Paper from "@mui/material/Paper";
import { Link, useNavigate } from "react-router-dom";
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from "react-redux";
import { getOverviewMembers } from "../../../app/store/overviewSlice";
const tick = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/doubleTick.png";

function createData(
  name,
  linkedInSync,
  campaignIssues,
  connectionRate,
  replyRate,
  meetingsBooked
) {
  return {
    name,
    linkedInSync,
    campaignIssues,
    connectionRate,
    replyRate,
    meetingsBooked
  };
}

const rows = [
  createData("Ben Metzker",true, 2,"20","3","0"),
  createData("Anthony Martinez", true, 0, "41", "12", "4"),
];

export default function OverviewTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const members = useSelector((state) => state.overview.overviewMembers);
  const [page, setPage] = useState(0); 
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    dispatch(getOverviewMembers({ page: page + 1, per_page: rowsPerPage })); 
  }, [page, rowsPerPage, dispatch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); 
  };

  return (
    <TableContainer sx={{ width: "100%", overflowX: "auto" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={`table-col-title ${themeMode === "dark" ? "text-light" : "text-dark"}`} align="left">
              Name
            </TableCell>
            <TableCell className={`table-col-title ${themeMode === "dark" ? "text-light" : "text-dark"}`} align="left">
              Linkedin Sync{" "}
            </TableCell>
            <TableCell className={`table-col-title ${themeMode === "dark" ? "text-light" : "text-dark"}`} align="left">
              Connection Rate{" "}
            </TableCell>
            <TableCell className={`table-col-title ${themeMode === "dark" ? "text-light" : "text-dark"}`} align="left">
              Reply Rate{" "}
            </TableCell>
            <TableCell className={`table-col-title ${themeMode === "dark" ? "text-light" : "text-dark"}`} align="left">
              Meetings Booked{" "}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {members?.data?.length === 0 ? (
            <TableRow
              className="cursor-pointer"
            >
              <TableCell
                colSpan={5}
                align="center"
                className={`table-row-content ${themeMode === "dark" ? "text-light" : "text-dark"}`}
              >
                No Records
              </TableCell>
            </TableRow>
          ) : (
            members?.data?.map((row, index) => (
              <TableRow
                key={row.uuid}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                // onClick={() =>
                //   navigate(`/dashboard/campaigns/${row?.first_name} ${row?.last_name}/${index}`)
                // }
                className="cursor-pointer"
              >
                <TableCell className={`table-row-content ${themeMode === "dark" ? "text-light" : "text-dark"}`} align="left" >
                  {row?.first_name} {row?.last_name}
                </TableCell>
                <TableCell className={`table-row-content ${themeMode === "dark" ? "text-light" : "text-dark"}`} align="left" >
                  {row?.linkedin_connected_account?.connected ?
                    <img src={tick} alt="" />
                  :
                    <ClearIcon  sx={{color:"red"}}/>
                  }

                </TableCell>
                <TableCell className={`table-row-content ${themeMode === "dark" ? "text-light" : "text-dark"}`} align="left" >
                  {row?.connection_rate} %
                </TableCell>
                <TableCell className={`table-row-content ${themeMode === "dark" ? "text-light" : "text-dark"}`} align="left" >
                  {row?.reply_rate} %
                </TableCell>
                <TableCell className={`table-row-content ${themeMode === "dark" ? "text-light" : "text-dark"}`} align="left" >
                  {row?.meetings_booked}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={members?.meta?.total_count || 0} 
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          "& .Mui-disabled": {
            color: themeMode === "dark" ? "darkgray" : "gray",
          },
          "& .MuiSelect-icon": {
            color: themeMode === "dark" ? "white" : "black",
          },
          "& .MuiTablePagination-displayedRows": {
            color: themeMode === "dark" ? "white" : "black",
            fontSize: "13px",
          },
          "& .MuiTablePagination-toolbar": {
            padding: "10px",
          },
          "& .MuiTablePagination-actions": {
            color: themeMode === "dark" ? "white" : "black",
          },
          "& .MuiTablePagination-selectLabel, & .MuiTablePagination-input": {
            color: themeMode === "dark" ? "white" : "black",
            fontSize: "13px",
          },
        }}
      />
    </TableContainer>
  );
}
