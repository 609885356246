import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import "./Dashboard.css";
import { Login } from "./app/auth/Login";
import { Register } from "./app/auth/Register";
import { AboutUs } from "./app/website/about-us/AboutUs";
import { ContactUs } from "./app/website/contact-us/ContactUs";
import { HomePage } from "./app/website/homepage/HomePage";
import { Route, Routes } from "react-router-dom";
import { Pricing } from "./app/website/pricing/Pricing";
import Dashboard from "./app/dashboard/Dashboard";
import { HelpCenter } from "./app/website/help/Help";
import ProtectedRoute from "./utils/protectedRoutes";
import NonProtectedRoute from "./utils/nonProtectedRoutes";
import axios from "axios";
import Welcome from "./app/onboarding/Welcome";
import SetPassword from "./app/onboarding/SetPassword";
import PlanSubscription from "./app/onboarding/PlanSubscription";
import CompleteOnboarding from "./app/onboarding/Complete";
import { getUserFromLocalStorage } from "./app/services/localStorageService";
import { getUserProfile } from "./app/store/userProfileSlice";
import ConnectLinkedIn from "./app/onboarding/ConnectLinkedIn";
import ConfirmDetails from "./app/onboarding/ConfirmDetails";
import StripePaymentForm from "./app/onboarding/StripePaymentForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import LimitationsBestPractice from "./app/website/limitation/LimitationsBestPractice";
import { getLinkedInConnectedStatus } from "./app/store/settingsSlice";
import General from "./app/website/limitation/LimitationsBestPractice";
import PrivacyPolicy from "./app/website/privacyPolicy/PrivacyPage";
import TermsAndCondition from "./app/website/terms&condition/Terms&Condition";
import { requestForToken, onMessageListener } from "./firebase";
import { getTokens } from "./app/store/loginSlice";
import { getChat, setOpenChatBox } from "./app/store/messagesSlice";

axios.defaults.headers["client"] = localStorage.getItem("client");
axios.defaults.headers["uid"] = localStorage.getItem("uid");
axios.defaults.headers["access-token"] = localStorage.getItem("access-token");

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userProfile.userProfile);

  useEffect(() => {
    const user = getUserFromLocalStorage();
    if (user && user["access-token"] && user["client"] && user["uid"]) {
      dispatch(getUserProfile());
      dispatch(getLinkedInConnectedStatus());
      requestForToken(dispatch);
    }
  }, []);

  let stripePromise;
  const stripe_public_key =
    "pk_test_51PsUpbF8kxBKGugnUqY04ciBlZfym1stI57X0fJZE1AgUGQbXWzdEZcAsLZgtqWBonpu68Cq06LVjMkUPb2PWyyQ00jOhqOw7d";
  if (stripe_public_key) {
    try {
      stripePromise = loadStripe(stripe_public_key);
      console.info("stripe laoded");
    } catch (error) {
      console.error("Failed to load Stripe:", error);
    }
  }

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        console.log("Message received from service worker:", event.data);

        const { notificationable_uuid } = event.data;

        if (notificationable_uuid) {
          dispatch(getChat(notificationable_uuid)).then(() => {
            dispatch(setOpenChatBox(true));
          });
        }
      });
    }
  }, [dispatch]);

  onMessageListener()
    .then((payload) => {
      console.log("Received foreground notification:", payload);
      // if (payload?.data?.notificationable_type === "Chat") {
      //   dispatch(getChat(payload?.data?.notificationable_uuid)).then(() => {
      //     dispatch(setOpenChatBox(true));
      //   });
      // }
    })
    .catch((err) => console.error("Failed to receive notification:", err));

  return (
    <div>
      <Routes>
        <Route element={<NonProtectedRoute user={user} />}>
          <Route path="/login" index element={<Login />} />
        </Route>
        <Route path="/register" index element={<Register />} />
        <Route path="/" index element={<HomePage />} />
        <Route path="/about" index element={<AboutUs />} />
        <Route path="/contact" index element={<ContactUs />} />
        <Route path="/pricing" index element={<Pricing />} />
        <Route path="/faqs" index element={<HelpCenter />} />
        <Route path="/privacy-policy" index element={<PrivacyPolicy />} />
        <Route path="/terms-condition" index element={<TermsAndCondition />} />
        <Route
          path="/limitations-and-best-practices"
          index
          element={<General />}
        />

        <Route path="/welcome" index element={<Welcome />} />
        <Route path="/confirm-details" index element={<ConfirmDetails />} />
        <Route path="/set-password" index element={<SetPassword />} />
        <Route path="/plan-subscription" index element={<PlanSubscription />} />

        <Route
          path="/payment-form"
          index
          element={
            <Elements stripe={stripePromise}>
              <StripePaymentForm />
            </Elements>
          }
        />

        <Route
          path="/onboarding-complete"
          index
          element={<CompleteOnboarding />}
        />
        <Route path="/connect-linkedIn" index element={<ConnectLinkedIn />} />

        <Route element={<ProtectedRoute user={user} />}>
          <Route path="/dashboard/*" index element={<Dashboard />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
