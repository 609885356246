import { Grid, Button, Typography, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import __ from "lodash";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getCampaignDetail,
  updateInMailMessages,
} from "../../../../store/campaignSlice";
import { error, success } from "../../../../store/alertSlice";
const idea =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/idea.png";
const ideaBlack =
  "	https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/ideaBlack.png";
const defaultValues = {
  inmail_subject: "",
  inmail_body: "",
};

const schema = yup
  .object()
  .shape({
    inmail_subject: yup.string().required("You must enter subject"),
    inmail_body: yup.string().required("You must enter body"),
  })
  .required();

const InMailMessaging = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const inMailMessage = useSelector((state) => state.campaign.campaign);
  const { handleSubmit, getValues, reset, control, setValue, formState } =
    useForm({
      defaultValues,
      mode: "onSubmit",
      resolver: yupResolver(schema),
    });

  const { errors } = formState;

  useEffect(() => {
    reset({ ...inMailMessage });
  }, [inMailMessage, reset]);

  function onSubmit(data) {
    const updatedData = {
      inmail_subject: data.inmail_subject,
      inmail_body: data.inmail_body,
      id: id,
    };
    dispatch(updateInMailMessages(updatedData)).then((resp) => {
      if (resp.payload.status === 200) {
        dispatch(getCampaignDetail(id));
        dispatch(success("InMail message updated"));
      } else {
        dispatch(error(resp.payload.statusText));
      }
    });
  }
  return (
    <div>
      {" "}
      <Grid className="my-8">
        <Grid
          sx={{
            background: themeMode === "dark" ? "#00000099" : "#DEDEDE",
            border: "1px solid #FFF",
            borderRadius: "10px",
            padding: { md: "30px 30px", sm: "15px 15px", xs: "10px 10px" },
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "600",
              color: themeMode === "dark" ? "white" : "black",
            }}
          >
            InMails are sent directly to a person's inbox. Your lead can still
            look up your profile and company through it, which increases trust.
          </Typography>
          <Grid container sx={{ display: "flex" }}>
            <Grid
              item
              md={6}
              mt={2}
              sx={{
                padding: "15px",
                borderRadius: "10px",
                border: "1px solid #FFF",
                background: "#FF000080",
              }}
            >
              <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
                First InMail Message (1st Message)
              </Typography>
              <Grid my={2}>
                <div className="create-campaign-dialog pb-2">
                  InMail Subject Line
                </div>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.inmail_subject}
                      disabled={!inMailMessage.allow_inmail_to_open_profiles}
                      helperText={errors?.inmail_subject?.message}
                      className="create-campaign-dialog-field w-full"
                      required
                      size="small"
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "lightgray",
                        },
                        "& .MuiInputBase-input": {
                          color: "white",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                            color: "white",
                          },
                        },
                      }}
                    />
                  )}
                  name="inmail_subject"
                  control={control}
                />
              </Grid>
              <Grid my={2}>
                <div className="create-campaign-dialog pb-2">
                  InMail Message*
                </div>
                <Controller
                  render={({ field }) => (
                    <TextField
                      id="inmail_body"
                      {...field}
                      disabled={!inMailMessage.allow_inmail_to_open_profiles}
                      error={!!errors.inmail_body}
                      helperText={errors?.inmail_body?.message}
                      multiline
                      className="create-campaign-dialog-field w-full"
                      rows={4}
                      size="small"
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "lightgray",
                        },
                        "& .MuiInputBase-input": {
                          color: "white",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                            color: "white",
                          },
                        },
                      }}
                    />
                  )}
                  name="inmail_body"
                  control={control}
                />
              </Grid>
              <Grid my={2}>
                <Typography sx={{ fontSize: "13px", fontWeight: "400" }}>
                  Use these buttons to pull a prospect's name directly from
                  their LinkedIn profile to personlize your messages:
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex" }}>
                <Button
                  disabled={!inMailMessage.allow_inmail_to_open_profiles}
                  sx={{
                    "&.Mui-disabled": {
                      backgroundColor: "lightgray", // Button background when disabled
                      color: "darkgray", // Text color when disabled
                    },
                    fontSize: { md: "14px", sm: "14px", xs: "13px" },
                    padding: { md: "8px 20px", sm: "8px 20px", xs: "5px 10px" },
                    color: "#FFF",
                    background: "transparent",
                    border: "1px solid #FFF",
                    borderRadius: "30px",
                    textTransform: "capitalize",
                    textWrap: "nowrap",
                  }}
                  onClick={() => {
                    const currentValue = getValues("inmail_body") || "";
                    setValue("inmail_body", currentValue + "{first_name}", {
                      shouldValidate: true,
                    });
                  }}
                >
                  {"{"}First Name{"}"}
                </Button>
                <Button
                  disabled={!inMailMessage.allow_inmail_to_open_profiles}
                  sx={{
                    "&.Mui-disabled": {
                      backgroundColor: "lightgray", // Button background when disabled
                      color: "darkgray", // Text color when disabled
                    },
                    marginLeft: "15px",
                    fontSize: { md: "14px", sm: "14px", xs: "13px" },
                    padding: { md: "8px 20px", sm: "8px 20px", xs: "5px 10px" },
                    color: "#FFF",
                    background: "transparent",
                    border: "1px solid #FFF",
                    borderRadius: "30px",
                    textTransform: "capitalize",
                    textWrap: "nowrap",
                  }}
                  onClick={() => {
                    const currentValue = getValues("inmail_body") || "";
                    setValue("inmail_body", currentValue + "{last_name}", {
                      shouldValidate: true,
                    });
                  }}
                >
                  {"{"}Last Name{"}"}
                </Button>
              </Grid>
              <Button
                className="create-button p-2 px-8 mt-4"
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                style={{ backgroundColor: "white" }}
                disabled={!inMailMessage.allow_inmail_to_open_profiles}
              >
                <span className="create-button-text" style={{ color: "red" }}>
                  Save
                </span>
              </Button>
            </Grid>
            <Grid item md={6} mt={2} sx={{ padding: "15px 15px 0px 25px" }}>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "600",
                  color: themeMode === "dark" ? "white" : "black",
                  display: "flex",
                }}
              >
                <img
                  src={themeMode === "dark" ? idea : ideaBlack}
                  alt=""
                  style={{ marginRight: "10px" }}
                />
                Tips
              </Typography>
              <Grid my={2}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "600",
                    color: themeMode === "dark" ? "white" : "black",
                    display: "flex",
                  }}
                >
                  Increase Response Rate
                </Typography>
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontWeight: "400",
                    color: themeMode === "dark" ? "white" : "black",
                    display: "flex",
                  }}
                >
                  The character limit is shorter than LinkedIn's to account for
                  email signatures. We recommend keeping messages under 200
                  characters for a 22% better response rate.
                </Typography>
              </Grid>

              <Grid my={2}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "600",
                    color: themeMode === "dark" ? "white" : "black",
                    display: "flex",
                  }}
                >
                  Introduction:
                </Typography>
                <ul
                  style={{
                    padding: "0px 15px 15px",
                    color: themeMode === "dark" ? "white" : "black",
                  }}
                >
                  <li>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontWeight: "400",
                        color: themeMode === "dark" ? "white" : "black",
                        display: "flex",
                      }}
                    >
                      Clarify who you are - name, current role, and your
                      professional background.
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontWeight: "400",
                        color: themeMode === "dark" ? "white" : "black",
                        display: "flex",
                      }}
                    >
                      Share the context of the connection, such as mutual
                      connections or shared interests, as you would in your
                      second message of the automated messaging sequence.
                    </Typography>
                  </li>
                </ul>
              </Grid>

              <Grid my={2}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "600",
                    color: themeMode === "dark" ? "white" : "black",
                    display: "flex",
                  }}
                >
                  Body of message:
                </Typography>
                <ul
                  style={{
                    padding: "0px 15px 15px",
                    color: themeMode === "dark" ? "white" : "black",
                  }}
                >
                  <li>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontWeight: "400",
                        color: themeMode === "dark" ? "white" : "black",
                        display: "flex",
                      }}
                    >
                      State your intention of why you are reaching out
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontWeight: "400",
                        color: themeMode === "dark" ? "white" : "black",
                        display: "flex",
                      }}
                    >
                      Make a clear request: Specify what you are seeking from
                      the recipient or what you can offer.
                    </Typography>
                  </li>
                </ul>
              </Grid>

              <Grid mt={2}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "600",
                    color: themeMode === "dark" ? "white" : "black",
                    display: "flex",
                  }}
                >
                  Wrap up:
                </Typography>
                <ul
                  style={{
                    padding: "0px 15px 15px",
                    color: themeMode === "dark" ? "white" : "black",
                  }}
                >
                  <li>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontWeight: "400",
                        color: themeMode === "dark" ? "white" : "black",
                        display: "flex",
                      }}
                    >
                      Direct request: End with a clear call to action (e.g.
                      offer more information, ask to book a meeting, etc).
                    </Typography>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default InMailMessaging;
