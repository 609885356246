import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TablePagination } from "@mui/material";
import { useState } from "react";
import {
  getCampaignDetail,
  getinActiveCampaigns,
} from "../../store/campaignSlice";

export default function InactiveCampaignTable({ campaigns }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const inActiveCampaignMeta = useSelector(
    (state) => state.campaign.inActiveCampaignMeta
  );

  const [page, setPage] = useState(
    inActiveCampaignMeta?.current_page
      ? inActiveCampaignMeta?.current_page - 1
      : 0
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    inActiveCampaignMeta?.per_page ? inActiveCampaignMeta?.per_page : 10
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(
      getinActiveCampaigns({
        page: newPage - 1,
        per_page: rowsPerPage,
      })
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(
      getinActiveCampaigns({
        page: 1,
        per_page: parseInt(event.target.value, 10),
      })
    );
  };

  return (
    <TableContainer sx={{ width: "100%", overflowX: "auto" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              className={` ${
                themeMode === "dark"
                  ? "table-col-title text-light"
                  : "table-col-title-light text-dark"
              }`}
              align="left"
            >
              Campaign Name{" "}
            </TableCell>
            <TableCell
              className={` ${
                themeMode === "dark"
                  ? "table-col-title text-light"
                  : "table-col-title-light text-dark"
              }`}
              align="left"
            >
              Type{" "}
            </TableCell>
            <TableCell
              className={` ${
                themeMode === "dark"
                  ? "table-col-title text-light"
                  : "table-col-title-light text-dark"
              }`}
              align="left"
            >
              Total Reach{" "}
            </TableCell>
            <TableCell
              className={` ${
                themeMode === "dark"
                  ? "table-col-title text-light"
                  : "table-col-title-light text-dark"
              }`}
              align="left"
            >
              Connection Rate{" "}
            </TableCell>
            <TableCell
              className={` ${
                themeMode === "dark"
                  ? "table-col-title text-light"
                  : "table-col-title-light text-dark"
              }`}
              align="left"
            >
              Reply Rate{" "}
            </TableCell>
            <TableCell
              className={` ${
                themeMode === "dark"
                  ? "table-col-title text-light"
                  : "table-col-title-light text-dark"
              }`}
              align="left"
            >
              Meetings Booked{" "}
            </TableCell>
            <TableCell
              className={` ${
                themeMode === "dark"
                  ? "table-col-title text-light"
                  : "table-col-title-light text-dark"
              }`}
              align="left"
            >
              Automation Status{" "}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {campaigns
            ?.filter((n) => n.status_type === "deactivated")
            ?.map((row, index) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onClick={() => {
                  dispatch(getCampaignDetail(row.uuid)).then((res) => {
                    if (res?.payload?.status === 200) {
                      navigate(`/dashboard/campaigns/${row.title}/${row.uuid}`);
                    }
                  });
                }}
                className="cursor-pointer"
              >
                <TableCell
                  className={` ${
                    themeMode === "dark"
                      ? "table-row-content text-light"
                      : "table-row-content-light text-dark"
                  }`}
                >
                  {row.title}
                </TableCell>
                <TableCell
                  className={` ${
                    themeMode === "dark"
                      ? "table-row-content text-light"
                      : "table-row-content-light text-dark"
                  }`}
                  align="left"
                >
                  {row.campaign_type}
                </TableCell>
                <TableCell
                  className={` ${
                    themeMode === "dark"
                      ? "table-row-content text-light"
                      : "table-row-content-light text-dark"
                  }`}
                  align="left"
                >
                  {row.total_reach}
                </TableCell>
                <TableCell
                  className={` ${
                    themeMode === "dark"
                      ? "table-row-content text-light"
                      : "table-row-content-light text-dark"
                  }`}
                  align="left"
                >
                  <div className="flex items-center space-x-2">
                    <span> {row.connection_rate}%</span>
                    <span>
                      {row.connection_rate < 30 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                        >
                          <path
                            d="M3.73737 5.47496e-07L6.26263 3.26732e-07L6.26263 5.68182L8.47222 3.47222L10 5L5 10L-4.37114e-07 5L1.52778 3.47222L3.73737 5.68182L3.73737 5.47496e-07Z"
                            fill="#FF0000"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                        >
                          <path
                            d="M6.26263 10H3.73737V4.31818L1.52778 6.52778L0 5L5 0L10 5L8.47222 6.52778L6.26263 4.31818V10Z"
                            fill="#00FF1A"
                          />
                        </svg>
                      )}
                    </span>
                  </div>
                </TableCell>
                <TableCell
                  className={`${
                    themeMode === "dark"
                      ? "table-row-stats text-light"
                      : "table-row-stats-light text-dark"
                  }`}
                  align="left"
                >
                  {row.reply_rate}%
                </TableCell>
                <TableCell
                  className={`${
                    themeMode === "dark"
                      ? "table-row-stats text-light"
                      : "table-row-stats-light text-dark"
                  }`}
                  align="left"
                >
                  {row.meetings_booked}
                </TableCell>
                <TableCell
                  className={`${
                    themeMode === "dark"
                      ? "table-row-stats text-light"
                      : "table-row-stats-light text-dark"
                  }`}
                  align="left"
                >
                  {row.status_type}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={campaigns?.length ? campaigns?.length : 0}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          "& .Mui-disabled": {
            color: themeMode === "dark" ? "darkgray" : "gray",
          },
          "& .MuiSelect-icon": {
            color: themeMode === "dark" ? "white" : "black",
          },
          "& .MuiTablePagination-displayedRows": {
            color: themeMode === "dark" ? "white" : "black",
            fontSize: "13px",
          },
          "& .MuiTablePagination-toolbar": {
            padding: "10px",
          },
          "& .MuiTablePagination-actions": {
            color: themeMode === "dark" ? "white" : "black",
          },
          "& .MuiTablePagination-selectLabel, & .MuiTablePagination-input": {
            color: themeMode === "dark" ? "white" : "black",
            fontSize: "13px",
          },
        }}
      />
    </TableContainer>
  );
}
