const overviewWhite =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/overviewWhite.png";
const campaignsWhite =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/campaignWhite.png";
const connectionWhite =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/connectionsWhite.png";
const msgWhite =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/msgWhite.png";
const meetingsWhite =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/meetingsWhite.png";
const paymentsWhite =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/paymentWhite.png";
const settingsWhite =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/settingsWhite.png";

const overview =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/overview.png";
const campaigns =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/campaign.png";
const connection =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/connection.png";
const msg =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/message.png";
const meetings =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/meetings.png";
const payments =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/payment.png";
const settings =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/settings.png";

const overviewDark =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/overviewDark.png";
const campaignsDark =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/campaignDark.png";
const msgDark =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/messageDark.png";
const connectionDark =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/connectionDark.png";
const meetingsDark =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/meetingsDark.png";
const paymentsDark =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/paymentDark.png";
const settingsDark =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/settingsDark.png";

export const ToolbarList = [
  {
    id: 1,
    name: "Overview",
    img: overview,
    imgD: overviewDark,
    imgW: overviewWhite,
    link: "/dashboard/overview",
  },
  {
    id: 2,
    name: "Messages",
    img: msg,
    imgD: msgDark,
    imgW: msgWhite,
    link: "/dashboard/messages",
  },
  {
    id: 3,
    name: "Campaigns",
    img: campaigns,
    imgD: campaignsDark,
    imgW: campaignsWhite,
    link: "/dashboard/campaigns",
  },
  {
    id: 4,
    name: "Connections",
    img: connection,
    imgD: connectionDark,
    imgW: connectionWhite,
    link: "/dashboard/connections",
  },
  {
    id: 5,
    name: "Meetings",
    img: meetings,
    imgD: meetingsDark,
    imgW: meetingsWhite,
    link: "/dashboard/meetings",
  },
  {
    id: 6,
    name: "Payments",
    img: payments,
    imgD: paymentsDark,
    imgW: paymentsWhite,
    link: "/dashboard/payments",
  },
  {
    id: 5,
    name: "Settings",
    img: settings,
    imgD: settingsDark,
    imgW: settingsWhite,
    link: "/dashboard/settings",
  },
];
