import { Grid, Typography } from "@mui/material";
import { OurTeamCard } from "./OurTeamCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const OurTeam = () => {
  return (
    <div className="text-white">
      <Typography
        sx={{
          typography: {
            md: "h3",
            sm: "h4",
            xs: "h4",
          },
        }}
        style={{ fontWeight: 700 }}
        className="pt-10 text-center"
      >
        Meet our team{" "}
      </Typography>
      <div className="flex justify-center pt-4">
        <div className="our-team-content flex md:w-2/3 px-8">
          when an unknown printer took a galley of type and scrambled it to make
          a type specimen book. It has survived not only five centuries,
        </div>
      </div>

      <div className="my-24 md:px-8 px-8">
        <Carousel responsive={responsive}>
          <OurTeamCard name={"Ben Metzker"} designation={"Co-Founder"} />
          <OurTeamCard name={"Winston Cook"} designation={"Co-Founder"} />
          <OurTeamCard name={"M. Hamza"} designation={"Co-Founder"} />
          <OurTeamCard name={"Nabeel A."} designation={"Co-Founder"} />
          <OurTeamCard name={"Syed Anab"} designation={"Co-Founder"} />
          <OurTeamCard name={"Winston Cook"} designation={"Co-Founder"} />
          <OurTeamCard name={"M. Hamza"} designation={"Co-Founder"} />
        </Carousel>
      </div>
       
    </div>
  );
};
