import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import counterReducer from "../../features/counter/counterSlice";
import alertSlice from "./alertSlice";
import campaignReducer from "./campaignSlice";
import registerReducer from "./registerSlice";
import loginReducer from "./loginSlice";
import userProfileReducer from "./userProfileSlice";
import settingsReducer from "./settingsSlice";
import connectionReducer from "./connectionSlice";
import themeModeReducer from "./themeModeSlice";
import onboardingReducer from "./onboardingSlice";
import overviewReducer from "./overviewSlice";
import messagesReducer from "./messagesSlice";
import meetingReducer from "./meetingSlice";
import paymentsReducer from "./paymentsSlice";
import paymentMethodReducer from "./paymentMethodSlice";
import notificationReducer from "./notificationSlice";

export const store = configureStore({
  reducer: {
    alert: alertSlice,
    counter: counterReducer,
    campaign: campaignReducer,
    register: registerReducer,
    login: loginReducer,
    userProfile: userProfileReducer,
    settings: settingsReducer,
    connection: connectionReducer,
    meeting: meetingReducer,
    themeMode: themeModeReducer,
    onboarding: onboardingReducer,
    overview: overviewReducer,
    messages: messagesReducer,
    payments: paymentsReducer,
    paymentMethods: paymentMethodReducer,
    notification: notificationReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      createLogger({
        collapsed: (getState, action, logEntry) => !logEntry.error,
      })
    ),
});
