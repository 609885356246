import React from 'react';
import { Box, Typography, Button, Card, CardContent, IconButton, Tabs, Tab } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from "react-redux";

const AutomatedMessages = ({ setSelectedSubTab }) => {
    const themeMode = useSelector((state) => state.themeMode.mode);

    return (
        <>
            <Typography variant="h5" gutterBottom sx={{ color: themeMode === "dark" ? 'white': 'black', marginBottom: '20px'}}>
                Edit your follow up messages
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    gap: '20px',
                    marginBottom: '20px',
                }}
            >
                <Card
                    sx={{
                        width: '30%',
                        backgroundColor: themeMode === "dark" ? 'rgba(0, 0, 0, 0.6)': 'white',
                        borderRadius: '10px',
                        color: '#fff',
                    }}
                >
                <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end'}}>
                        <IconButton color="inherit">
                            <EditIcon sx={{ color: themeMode === "dark" ? 'white': 'black' }} />
                        </IconButton>
                        <IconButton color="inherit">
                            <DeleteIcon sx={{ color: 'red' }} />
                        </IconButton>
                    </Box>
                    <Typography variant="h6" sx={{ color: themeMode === "dark" ? 'white': 'black'}}>Connection Request Message</Typography>
                    <Typography variant="body2" sx={{ color: themeMode === "dark" ? 'white': 'black', marginTop: '10px' }}>
                        Hey [firstname]! I saw you’re in the roofing industry. Would love to Connect!
                    </Typography>
                </CardContent>
                </Card>
                <Card
                    sx={{
                        width: '30%',
                        backgroundColor: themeMode === "dark" ? 'rgba(0, 0, 0, 0.6)': 'white',
                        borderRadius: '10px',
                        color: '#fff',
                    }}
                >
                    <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end'}}>
                            <IconButton color="inherit">
                                <EditIcon sx={{ color: themeMode === "dark" ? 'white': 'black' }} />
                            </IconButton>
                            <IconButton color="inherit">
                                <DeleteIcon sx={{ color: 'red' }} />
                            </IconButton>
                        </Box>
                        <Typography variant="h6" sx={{ color: themeMode === "dark" ? 'white': 'black'}}>Second Message</Typography>
                        <Typography variant="body2" sx={{ color: themeMode === "dark" ? 'white': 'black', marginTop: '10px' }}>
                            Thanks for connecting [firstname]! The reason I’m reaching out, we developed a software specifically...
                        </Typography>
                    </CardContent>
                </Card>
                <Card
                    sx={{
                        width: '30%',
                        backgroundColor: themeMode === "dark" ? 'rgba(0, 0, 0, 0.6)': 'white',
                        borderRadius: '10px',
                        color: '#fff',
                    }}
                >
                    <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end'}}>
                            <IconButton color="inherit">
                                <EditIcon sx={{ color: themeMode === "dark" ? 'white': 'black' }} />
                            </IconButton>
                            <IconButton color="inherit">
                                <DeleteIcon sx={{ color: 'red' }} />
                            </IconButton>
                        </Box>
                        <Typography variant="h6" sx={{ color: themeMode === "dark" ? 'white': 'black' }}>Third Message</Typography>
                        <Typography variant="body2" sx={{  color: themeMode === "dark" ? 'white': 'black', marginTop: '10px' }}>
                            Hi [firstname]! Just reaching back out. Not sure you’ll saw that last message but I’d love to get on...
                        </Typography>
                    </CardContent>
                </Card>
            </Box>
            <Button
                variant="contained"
                sx={{
                    backgroundColor: '#b11b1b', 
                    color: '#fff',
                    width: '77px',
                    Height: '41px',
                    borderRadius: '30px',
                    padding: '10px 40px',
                    '&:hover': {
                        backgroundColor: '#9b1717',
                    },
                }}
                onClick={() => setSelectedSubTab(2)}
            >
                Next
            </Button>
        </>
    )
}
export default AutomatedMessages;