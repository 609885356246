import { LinearProgress } from "@mui/material";

export const LoaderProgress = () => {
  return (
    <LinearProgress
      style={{
        width: "100%",
        height: "7px", // Adjust the thickness
        borderRadius: "5px", // Round the borders
        backgroundColor: "#f0f0f0",
      }}
      sx={{
        "& .MuiLinearProgress-bar": {
          backgroundColor: "#730101", // Set the progress bar color
          animationDuration: "3s",
        },
      }}
    />
  );
};
