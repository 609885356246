import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../services/api";

const initialState = {
  overviewAnalyticalData: {},
  overviewMembers: {},
  overviewActivities: {},
};

export const getOverviewAnalyticalData = createAsyncThunk(
  "overview/getOverviewAnalyticalData",
  async (params, { dispatch }) => {
    try {
      const res = await api.get(`/overview/analytical_data`);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  }
);

export const getOverviewMembers = createAsyncThunk(
  "overview/getOverviewMembers",
  async ({ page = 1, per_page = 10 }, { dispatch }) => {
    try {
      const res = await api.get(
        `/overview/all_members?page=${page}&per_page=${per_page}`
      );
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  }
);

export const getOverviewActivities = createAsyncThunk(
  "overview/getOverviewActivities",
  async () => {
    try {
      const res = await api.get(`/overview/today_activities`);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  }
);

export const overviewSlice = createSlice({
  name: "overview",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getOverviewAnalyticalData.fulfilled, (state, action) => {
        state.overviewAnalyticalData = action.payload;
      })
      .addCase(getOverviewMembers.fulfilled, (state, action) => {
        state.overviewMembers = action.payload;
      })
      .addCase(getOverviewActivities.fulfilled, (state, action) => {
        state.overviewActivities = action.payload;
      });
  },
});

export const {} = overviewSlice.actions;

export default overviewSlice.reducer;
