import { useEffect, useState } from "react";
import { Grid, Button,useMediaQuery } from "@mui/material";
import PaymentTable from "./PaymentTable";
import ChangePaymentDialog from "./ChangePaymentDialogBox";
import { useDispatch, useSelector } from "react-redux";
import CancelSubscriptionDialog from "./CancelSubscriptionDialog";
import { getOrganization } from "../../store/settingsSlice";
import PayNowDialog from "./PayNowDialog";
import { capitalizeFirstLetter } from "../../../utils/globalFunctions.js";
import dayjs from 'dayjs';

export const Payment = () => {
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const payments_invoices = useSelector((state) => state.payments.payments_invoices);
  const [open, setOpen] = useState(false);
  const [plan, setPlan] = useState(null);
  const isCustomRange = useMediaQuery(
    "(min-width:900px) and (max-width:1056px)"
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setOpen(false);
    }
  };

  const [openCancel, setOpenCancel] = useState(false);

  const handleClickOpenCancel = () => {
    setOpenCancel(true);
  };

  const handleCloseCancel = () => {
    setOpenCancel(false);
  };

  const [openPay, setOpenPay] = useState(false);

  const handleClickOpenPay = (plan) => {
    setPlan(plan);
    setOpenPay(true);
  };

  const handleClosePay = () => {
    setOpenPay(false);
  };

  useEffect(() => {
    dispatch(getOrganization());
  }, []);

  return (
    <>
      <div>
        <div className="pt-24 pb-6 md:px-16 px-8">
          <div
            className={`dashboard-title ${
              themeMode === "dark" ? "text-light" : "text-dark"
            }`}
          >
            Payments & Invoices
          </div>
          <div
            className={`dashboard-subtitle py-4 ${
              themeMode === "dark" ? "text-light" : "text-dark"
            }`}
          >
            View list of previous generated invoices here. Also you can see your
            subscription plan and payment cycle.{" "}
          </div>
        </div>
        <Grid
          container
          className="md:px-16 px-8"
          sx={{ display: "flex", flexDirection: "row" }}
        >
          <Grid
            item
            md={isCustomRange?12:6}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: { md: "row", sm: "row", xs: "column" },
            }}
          >
            <Grid
              item
              md={6}
              sm={12}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: { md: "left", sm: "center" },
                  color: themeMode === "dark" ? "#FFF" : "#000",
                }}
              >
                Current Plan
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  color: themeMode === "dark" ? "#BDFF00" : "#608104",
                  fontSize: "22px",
                  fontWeight: "700",
                  justifyContent: { md: "left", sm: "center" },
                }}
              >
                { payments_invoices?.data?.current_plan?.payment_plan?.name ? capitalizeFirstLetter(payments_invoices.data.current_plan.payment_plan.name) : '' }
              </Grid>
            </Grid>
            <Grid
              item
              md={6}
              sm={12}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: { md: "left", sm: "center" },
                  color: themeMode === "dark" ? "#FFF" : "#000",
                }}
              >
                Next Payment Date
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  color: themeMode === "dark" ? "#BDFF00" : "#608104",
                  fontSize: "22px",
                  fontWeight: "700",
                  justifyContent: { md: "left", sm: "center" },
                }}
              >
                { payments_invoices?.data?.current_plan?.payment_plan?.price ? `$${payments_invoices.data.current_plan.payment_plan.price}` : '' }
                <span
                  style={{
                    color: themeMode === "dark" ? "#FFF" : "#000",
                    marginLeft: "10px",
                  }}
                >
                  { payments_invoices?.data?.current_plan?.next_date ? `on ${dayjs(payments_invoices.data.current_plan.next_date).format('D MMM YYYY')}` : '' }
                </span>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            my={{ md: "", sm: 3, xs: 3 }}
            item
            md={isCustomRange?12:6}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: { md: "flex-end", sm: "center",xs:"center"},
              alignItems: "center",flexWrap:{md:"nowrap",sm:"wrap",xs:"wrap"}
            }}
          >
            <Button
              onClick={() => (window.location.href = "payment-methods")}
              sx={{
                color: themeMode === "dark" ? "#FFF" : "#000",
                border:
                  themeMode === "dark" ? "1px solid #FFF" : "1px solid #000",
                borderRadius: "30px",
                textTransform: "capitalize",
                padding: "8px 15px",
                height: "fit-content",
                width: "fit-content",
                marginRight: "10px",
                textWrap: "nowrap",
                fontSize: { md: "14px", sm: "14px", xs: "12px" }
              }}
            >
              Payment Method
            </Button>
            <Button
              onClick={handleClickOpen}
              sx={{
                color: themeMode === "dark" ? "#FFF" : "#000",
                border:
                  themeMode === "dark" ? "1px solid #FFF" : "1px solid #000",
                borderRadius: "30px",
                textTransform: "capitalize",
                padding: "8px 15px",
                height: "fit-content",
                width: "fit-content",
                marginY:{md:"0px",sm:"0px",xs:"10px"},
                marginRight: "10px",
                textWrap: "nowrap",
                fontSize:{md:"14px",sm:"14px",xs:"12px"}
              }}
            >
              Change Plan
            </Button>
            <Button
              onClick={handleClickOpenCancel}
              sx={{
                padding: "8px 15px",
                height: "fit-content",
                color: themeMode === "dark" ? "#FFF" : "#FFF",
                border:
                  themeMode === "dark" ? "1px solid #FFF" : "1px solid #000",
                background: themeMode === "dark" ? "#FF0000" : "#BE0000",
                borderRadius: "30px",
                textTransform: "capitalize",
                textWrap: "nowrap",
                width:"fit-content",
                fontSize: { md: "14px", sm: "14px", xs: "12px" },
                "&:hover": {
                  color: "#FFF",
                  background: themeMode === "dark" ? "#BE0000" : "#FF0000",
                },
              }}
            >
              Cancel Subscription{" "}
            </Button>
          </Grid>
        </Grid>
        <div className="py-4 pb-12 md:px-16 px-8">
          <div
            className={`p-4 px-8 my-8 ${
              themeMode === "dark"
                ? "dashboard-table-layout"
                : "dashboard-table-layout-light"
            }`}
          >
            <div
              className=""
              style={{ color: themeMode === "dark" ? "white" : "#000" }}
            >
              <PaymentTable />
            </div>
          </div>
        </div>
        <ChangePaymentDialog
          open={open}
          handleClose={handleClose}
          handleClickOpen={handleClickOpen}
          handleClickOpenPay={handleClickOpenPay}
          current_plan={payments_invoices?.data?.current_plan}
        />
        <CancelSubscriptionDialog
          open={openCancel}
          handleClose={handleCloseCancel}
          handleClickOpen={handleClickOpenCancel}
        />
        <PayNowDialog
          open={openPay}
          plan={plan}
          handleClosePay={handleClosePay}
          handleClose={handleClose}
        />
      </div>
    </>
  );
};
