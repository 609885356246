import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Backdrop,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import __ from "lodash";
import {
  connectLinkedInAccount,
  getLinkedInConnectedStatus,
  setShowCaptcha,
  solveCodeCheckpoint,
} from "../../../store/settingsSlice";
import { ArkoseCaptchaIntegration } from "./captcha-integration/ArkoseCaptchaIntegration";
import { success } from "../../../store/alertSlice";
import { CookieConnect } from "./CookieConnect";

const defaultValues = {
  username: "",
  password: "",
};

const schema = yup
  .object()
  .shape({
    username: yup.string().required("You must enter email"),
    password: yup.string().required("You must enter password"),
  })
  .required();

export default function FindCookiesDialog({ open, handleClose }) {
  const themeMode = useSelector((state) => state.themeMode.mode);
  const connectAccount = useSelector(
    (state) => state.settings.connectLinkedInAccount
  );

  const [value, setValue] = React.useState("one");

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();
  const { handleSubmit, register, reset, control, watch, formState } = useForm({
    defaultValues,
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  function onSubmit(data) {
    dispatch(connectLinkedInAccount(data)).then((res) => {
      if (res.payload.status === 200) {
        dispatch(success(res.payload.data.message));
        handleClose();
        reset(defaultValues);
      } else {
        if (res.payload.status === 202) {
          dispatch(success(res.payload.data.message));

          if (res.payload.data?.checkpoint_details?.type === "CAPTCHA") {
            dispatch(setShowCaptcha(true));
            handleClose();
            reset(defaultValues);
            const publicKey = res.payload.data?.checkpoint_details?.public_key;
            const data = res.payload.data?.checkpoint_details?.data;

            // 1. Instanciate the captcha frame and logic
            const captcha = new ArkoseCaptchaIntegration(publicKey, data);

            captcha.onLoaded = () => {
              console.log(
                "Captcha has been successfully loaded into the DOM !"
              );
            };

            captcha.onSuccess = (token) => {
              console.log("Captcha has been resolved !", { token });
              dispatch(
                solveCodeCheckpoint({
                  code: token,
                })
              );
              captcha.unload("captcha-frame");
            };

            captcha.onError = () => {
              console.log("Captcha resolution failed !");
            };

            // 3. Load the captcha
            captcha.load("captcha-frame");
          } else {
            reset(defaultValues);
            dispatch(success("OTP Flow"));
          }
        } else {
          handleClose();
          reset(defaultValues);
        }
      }
    });
  }

  return (
    <Backdrop>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ background: themeMode === "dark" ? "rgba(0, 0, 0, 0.30)" : "#0000004D", backdropFilter: "blur(4px)" }}
        PaperProps={{
          sx: {
            backgroundColor: themeMode === "dark" ? "rgba(0, 0, 0, 0.60)" : "#FFFFFFBB", // Inner background color
            borderRadius: "10px",
            border: themeMode === "dark" ? "1px solid white" : "1px solid #000",
            color: themeMode === "dark" ? "white" : "#000",
            paddingLeft: "16px",
            paddingRight: "16px",
          },
        }}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle
          id="alert-dialog-title"
          className="w-full flex justify-end cursor-pointer pb-4"
          onClick={handleClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
          >
            <path
              d="M1.5 1L20.5 16.5M20.5 1L1.5 16.5"
              stroke={themeMode === "dark" ? "white" : "#000"}
              stroke-width="2"
            />
          </svg>{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: themeMode === "dark" ? "white" : "#000" }}
            className="flex flex-col  "
          >
            <div className={`create-campaign-dialog text-center pb-4 ${themeMode === "dark" ? "text-light" : "text-dark"}`}>
              How to find my cookies?
            </div>
            <div className={`create-campaign-dialog pb-4 ${themeMode === "dark" ? "text-light" : "text-dark"}`}>
              Follow the steps to find your Linkedin cookies (not available on
              mobile)
            </div>
            <div className={`create-campaign-dialog pb-4 ${themeMode === "dark" ? "text-light" : "text-dark"}`}>
              1- Open LinkedIn in new tab{" "}
              <a href="https://www.linkedin.com/">
                {" "}
                (or click here: <span style={{ color: "red" }}>
                  linkedin
                </span> ){" "}
              </a>
              <div className="pt-2">2- Log in to your account</div>
              <div className="pt-2">
                {" "}
                3- Open your browser's developer console (F12 for Chrome and
                Firefox, option + command + I for Safari) then go to the
                "application" or "storage" tab.{" "}
              </div>
              <div className="pt-2">
                4- Open the cookies folder and click on the one called
                "https://www.linkedin.com".
              </div>
              <div className="pt-2">
                {" "}
                5- Copy the values for "li_at" into the field below, then click
                on the connect button{" "}
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
}
