import React, { useState } from 'react';
import { Box, Typography, Select, MenuItem, Button, Switch, styled } from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import SendingHoursSlider from "./../../time-zone/SendingHoursSlider";
import {
    setCampaignSendingHours,
} from "../../../../../store/campaignSlice";

const CustomSwitch = styled(Switch)(({ theme }) => ({
    width: 45,
    height: 26,
    padding: 0.05,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#FF0000",
        "& + .MuiSwitch-track": {
          backgroundColor: "#6A0000",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#FF0000",
        border: "6px solid cyan",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: "#292929",
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: "#292929",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
}));

const TimeZoneOptimization = ({ setSelectedTab }) => {
    const dispatch = useDispatch();
    const campaign = useSelector((state) => state.campaign.campaign);
    const themeMode = useSelector((state) => state.themeMode.mode);
    const campaignSendingHours = useSelector(
      (state) => state.campaign.campaignSendingHours
    );
    const [selectedTimeZone, setSelectedTimeZone] = useState(
        campaign.time_zone || "US Mountain +7"
    );
    const sending_hours = campaign.sending_hours;
    const week_days = Object?.keys?.(sending_hours);
  
    const handleTimeZoneChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedTimeZone(selectedValue);
    };
  
    const marks = [
      { value: 0, label: '6:00 a.m.' },
      { value: 100, label: '8:30 p.m.' },
    ];

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    maxWidth: 800,
                    padding: '20px',
                    color: '#dedede99',
                    marginTop: '20px',
                }}
            >
                <Typography
                    mb={4}
                    sx={{
                        fontSize: "18px",
                        fontWeight: "600",
                        color: themeMode === "dark" ? "white" : "black",
                    }}
                >
                    Set your Timezone and Sending Hours
                </Typography>
                <Box my={4}>
                    <Typography
                        mb={2}
                        sx={{
                            fontSize: "12px",
                            fontWeight: "700",
                            color: themeMode === "dark" ? "white" : "black",
                        }}
                    >
                        TimeZone
                    </Typography>
                    <Select
                        value={selectedTimeZone}
                        onChange={handleTimeZoneChange}
                        defaultValue={campaign.time_zone}
                        className={
                            themeMode === "dark"
                            ? "dashboard-field"
                            : "dashboard-field-light"
                        }
                        size="small"
                        sx={{
                            minWidth: { md: "259px", sm: "259px", xs: "200px" },
                            "& .MuiSelect-icon": {
                            color: themeMode === "dark" ? "white" : "black",
                            },
                            "& .MuiSelect-select.MuiSelect-select": {
                            color: themeMode === "dark" ? "grey" : "black",
                            },
                            "& .MuiSelect-select": {
                            color: "blue",
                            },
                            "& .MuiInputLabel-root": {
                            color: "red",
                            },
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                                border: 0,
                            },
                        }}
                    >
                    <MenuItem value="" disabled>
                        Choose TimeZone
                    </MenuItem>
                    <MenuItem value={"US East +5"}>Eastern Time Zone</MenuItem>
                    <MenuItem value={"US Central +6"}>Central Time Zone</MenuItem>
                    <MenuItem value={"US Mountain +7"}>
                        Mountain Time (US & Canada)
                    </MenuItem>
                    <MenuItem value={"US Pacific +8"}>Pacific Time Zone</MenuItem>
                    <MenuItem value={"US Alaskan +9"}>Alaskan Time Zone</MenuItem>
                    <MenuItem value={"US Hawaiian +10"}>Hawaiian Time Zone</MenuItem>
                    </Select>
                </Box>
                <Typography
                    sx={{ color: themeMode === "dark" ? "white" : "black" }}
                >
                    Sending Hours
                </Typography>
                {week_days.map((day) => (
                    <Box
                        key={day}
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: { md: 2, sm: 1, xs: "10px 5px" },
                            marginBottom: "10px",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                width: "30%", 
                            }}
                        >
                        <CustomSwitch
                            defaultChecked={sending_hours[day]?.enabled}
                            onChange={(ev) => {
                            dispatch(
                                setCampaignSendingHours({
                                ...campaignSendingHours,
                                [day]: {
                                    start: campaignSendingHours?.[day]?.start,
                                    end: campaignSendingHours?.[day]?.end,
                                    enabled: ev.target.checked,
                                },
                                })
                            );
                            }}
                        />
                            <Typography
                                sx={{ marginLeft: 2, color: themeMode === "dark" ? "white" : "black" }}
                            >
                                {day.charAt(0).toUpperCase() + day.slice(1)}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                width: "60%", 
                            }}
                        >
                            <SendingHoursSlider
                                dayTimeRange={sending_hours?.[day]}
                                sendingHours={sending_hours}
                                day={day}
                            />
                        </Box>
                        <Box sx={{ width: "10%" }} />
                    </Box>
                ))}
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: '#b11b1b', 
                        color: '#fff',
                        width: '77px',
                        Height: '41px',
                        borderRadius: '30px',
                        padding: '10px 40px',
                        '&:hover': {
                            backgroundColor: '#9b1717',
                        },
                    }}
                    onClick={() => setSelectedTab(10)}
                >
                    Next
                </Button>
            </Box>
        </>
    )
}
export default TimeZoneOptimization;