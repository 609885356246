import { useDispatch } from "react-redux";
import {
  deleteAutomatedMessages,
  getCampaignDetail,
  setAutomatedMessage,
} from "../../../../store/campaignSlice";
import { useParams } from "react-router-dom";
import { error, success } from "../../../../store/alertSlice";

export const AutomatedMessagingCard = ({
  handleClickOpen,
  automatedMessage,
  themeMode,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const maxLength = 110;
  const ordinals = [
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth",
    "Sixth",
    "Seventh",
    "Eighth",
    "Ninth",
    "Tenth",
  ];
  function getOrdinal(number) {
    return ordinals[number];
  }
  return (
    <div
      className={
        themeMode === "dark"
          ? "messaging-card p-6 h-full"
          : "messaging-card-light p-6 h-full"
      }
    >
      <div className="flex w-full justify-end space-x-4 pb-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          className="cursor-pointer"
          onClick={() => {
            dispatch(
              setAutomatedMessage({
                ...automatedMessage,
                title:
                  automatedMessage?.message_type === "connection_message"
                    ? "Connection Request Message"
                    : `${getOrdinal(automatedMessage?.position)} Message`,
              })
            );
            handleClickOpen();
          }}
        >
          <path
            d="M14.7563 3.36828C15.0812 3.04333 15.0812 2.50174 14.7563 2.19345L12.8066 0.243716C12.4983 -0.0812387 11.9567 -0.0812387 11.6317 0.243716L10.0986 1.7685L13.2232 4.89307M0 11.8754V15H3.12457L12.34 5.77628L9.21539 2.65171L0 11.8754Z"
            fill={themeMode==="dark"?"white":"#000"}
          />
        </svg>
        {automatedMessage.position > 1 && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="16"
            viewBox="0 0 12 16"
            fill="none"
            className="cursor-pointer"
            onClick={() => {
              dispatch(
                deleteAutomatedMessages({
                  messageId: automatedMessage.uuid,
                  campaignId: id,
                })
              ).then((resp) => {
                if (resp.payload?.status === 200) {
                  dispatch(getCampaignDetail(id));
                  dispatch(success("Automated message deleted"));
                } else {
                  dispatch(error(resp.payload.statusText));
                }
              });
            }}
          >
            <path
              d="M12 0.888889H9L8.14286 0H3.85714L3 0.888889H0V2.66667H12M0.857143 14.2222C0.857143 14.6937 1.03775 15.1459 1.35925 15.4793C1.68074 15.8127 2.11677 16 2.57143 16H9.42857C9.88323 16 10.3193 15.8127 10.6408 15.4793C10.9622 15.1459 11.1429 14.6937 11.1429 14.2222V3.55556H0.857143V14.2222Z"
              fill="#FF0000"
            />
          </svg>
        )}
      </div>
      <div
        className={
          themeMode === "dark"
            ? "messaging-card-heading"
            : "messaging-card-heading-light"
        }
      >
        {automatedMessage?.message_type === "connection_message"
          ? "Connection Request Message"
          : `${getOrdinal(automatedMessage?.position)} Message`}
      </div>
      <div
        className={
          themeMode === "dark"
            ? "messaging-card-content pt-3"
            : "messaging-card-content-light pt-3"
        }
      >
        {automatedMessage?.message.length > maxLength
          ? automatedMessage?.message.substring(0, maxLength - 3) + "..."
          : automatedMessage?.message}
      </div>
    </div>
  );
};
