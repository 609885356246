export const OurTeamCard = ({ name, designation }) => {
  return (
    <div className="our-team-card h-full" style={{ maxWidth: "290px" }}>
      <div className="flex flex-col justify-end h-full p-6">
        <div className="team-member-name ">{name}</div>
        <div className="team-member-designation ">{designation}</div>
      </div>
    </div>
  );
};
