/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Grid,useMediaQuery } from "@mui/material";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import { BeginYourJourney } from "./BeginYourJourney";
import { Hero } from "./Hero";
import { HowItWorks } from "./HowItWorks";
import { Partners } from "./Partners";
import { WhyChooseUs } from "./WhyChooseUs";
import { Testimonials } from "./testimonials/Testimonials";

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 0%;
  }
  25%{
    background-position: 100% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  75%{
    background-position: 0% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
`;


export const HomePage = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.userProfile.userProfile);
  const isCustomRange = useMediaQuery('(min-width:900px) and (max-width:1200px)');

  const handleNavigateDashboard = () => {
    navigate("/dashboard");
    console.log("USer", user)
  };

  const GoToSignUp = () => {
    navigate("register");
  };

  const LearnMore = () => {
    navigate("/about");
  };

  console.log("USer",user)
  return (
    <div>
      {/* Desktop Display */}
      <Navbar />
      <Grid sx={{display:{md:"flex",sm:"none",xs:"none"}, flexDirection:"column"}}
        className="md:pt-24 sm:pt-10 xs:pt-8"
        css={css`
          background-color: #07070a;
          background-image: radial-gradient( #07070a 0%, #0c0c34 50%, #690303 100%);

          background-size: 240% 240%;
          animation: ${gradientAnimation} 30s ease infinite;
      `}
      >
        <Hero
          pageTitle={"Welcome to FacoonLabs"}
          mainHeading={"Your Partner in Automated Prospecting"}
          subHeading={"Turn Prospects into Clients with Ease"}
          primarayButton={user && Object.keys(user).length>0 ? "Go To Dashboard" : "Get Started"}
          primayButtonLink={user && Object.keys(user).length > 0 ? handleNavigateDashboard : GoToSignUp}
          secondaryButton={"Learn More"}
          secondaryButtonLink={LearnMore}
          imageUrl={"https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/heroNew.png"}
          
        />
        <WhyChooseUs imageUrl={isCustomRange ? "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/Vector1Tablet.png":"https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/Vector1.png"} />
      </Grid>



      {/* Tablet Display */}
      <Grid sx={{ display: { md: "none", sm: "flex", xs: "none" }, flexDirection: "column" }}
        css={css`
          background-color: #07070a;
          background-image: radial-gradient( #07070a 0%, #0c0c34 50%, #690303 100%);

          background-size: 240% 240%;
          animation: ${gradientAnimation} 30s ease infinite;
      `}
      >
        <Hero
          pageTitle={"Welcome to FacoonLabs"}
          mainHeading={"Your Partner in Automated Prospecting"}
          subHeading={"Turn Prospects into Clients with Ease"}
          primarayButton={user && Object.keys(user).length > 0 ? "Go To Dashboard" : "Get Started"}
          primayButtonLink={user && Object.keys(user).length > 0 ? handleNavigateDashboard : GoToSignUp}
          secondaryButton={"Learn More"}
          secondaryButtonLink={LearnMore}
          imageUrl={"https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/heroNew.png"}
        />
        <WhyChooseUs imageUrl={"https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/Vector1Tablet.png"} />
      </Grid>
      {/* Mobile Display */}

      <Grid sx={{ display: { md: "none", sm: "none", xs: "flex" }, flexDirection: "column" }}
        css={css`
          background-color: #07070a;
          background-image: radial-gradient( #07070a 0%, #0c0c34 50%, #690303 100%);
          background-size: 240% 240%;
          animation: ${gradientAnimation} 30s ease infinite;
      `}
      >
        <Hero
          pageTitle={"Welcome to FacoonLabs"}
          mainHeading={"Your Partner in Automated Prospecting"}
          subHeading={"Turn Prospects into Clients with Ease"}
          primarayButton={user && Object.keys(user).length > 0 ? "Go To Dashboard" : "Get Started"}
          primayButtonLink={user && Object.keys(user).length > 0 ? handleNavigateDashboard : GoToSignUp}
          secondaryButton={"Learn More"}
          secondaryButtonLink={LearnMore}
          imageUrl={"https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/heroNew.png"}
        />
        <WhyChooseUs imageUrl={"	https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/Vector1Mbl.png"} />
      </Grid>
      
      <Partners />
      <HowItWorks />
      <Testimonials />
      <BeginYourJourney />
      <Footer />
    </div>
  );
};

