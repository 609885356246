import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Backdrop,
  Chip,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import __ from "lodash";
import {
  createQuickResponse,
  getQuickResponses,
  updateQuickResponse,
} from "../../../store/settingsSlice";
import { error, success } from "../../../store/alertSlice";

const defaultValues = {
  title: "",
  message: "",
  campaign_uuid: "",
};

const schema = yup
  .object()
  .shape({
    title: yup.string().required("You must enter  title"),
    message: yup.string().required("You must enter message"),
  })
  .required();

export default function EditQuickResponseDialog({ open, handleClose }) {
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const quickResponse = useSelector((state) => state.settings.quickResponse);
  const campaigns = useSelector((state) => state.campaign.campaigns);

  const { handleSubmit, setValue, reset, control, getValues, formState } =
    useForm({
      defaultValues,
      mode: "onSubmit",
      resolver: yupResolver(schema),
    });

  const { errors } = formState;

  React.useEffect(() => {
    reset({ ...quickResponse });
  }, [quickResponse, reset]);

  function onSubmit(data) {
    dispatch(updateQuickResponse({ ...data, uuid: quickResponse.uuid })).then(
      (resp) => {
        if (resp.payload.status === 200) {
          dispatch(getQuickResponses());
          dispatch(success("Quick Response updated"));
        } else {
          dispatch(error(resp.payload.statusText));
        }
      }
    );
    handleClose();
  }
  return (
    <Backdrop>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ background: themeMode === "dark" ? "rgba(0, 0, 0, 0.30)" : "#0000004D", backdropFilter: "blur(4px)" }}
        PaperProps={{
          sx: {
            backgroundColor: themeMode === "dark" ? "rgba(0, 0, 0, 0.60)" : "#FFFFFFBB",
            borderRadius: "10px",
            border: themeMode === "dark" ? "1px solid white" : "1px solid #000",
            color: themeMode === "dark" ? "white" : "#000",
            paddingLeft: "16px",
            paddingRight: "16px",
          },
        }}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle
          id="alert-dialog-title"
          className="w-full flex justify-end cursor-pointer pb-1"
          onClick={handleClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
          >
            <path
              d="M1.5 1L20.5 16.5M20.5 1L1.5 16.5"
              stroke={themeMode === "dark" ? "white" : "#000"}
              stroke-width="2"
            />
          </svg>{" "}
        </DialogTitle>
        <DialogContent>
          <div className="text-center pb-8">
            <div className="font-semibold"> Edit Quick Response</div>
            <div className="text-sm">
              {" "}
              Save your frequently used messages as Quick Responses for easy
              access.
            </div>
          </div>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: themeMode === "dark" ? "white" : "#000" }}
          >
            <div className={`create-campaign-dialog-text pb-1 ${themeMode === "dark" ? "text-light" : "text-dark"}`}>Title</div>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.title}
                  helperText={errors?.title?.message}
                  required
                  className={`w-full ${themeMode === "dark" ? "create-campaign-dialog-field" : "create-campaign-dialog-field-light"}`}
                  size="small"
                  sx={{
                    "& .MuiInputBase-input": {
                      color: themeMode === "dark" ? "#FFF" : "#000",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                        color: themeMode === "dark" ? "#FFF" : "#000",
                      },
                    },
                  }}
                />
              )}
              name="title"
              control={control}
            />
            <div className={`create-campaign-dialog-text pt-4 pb-1 ${themeMode === "dark" ? "text-light" : "text-dark"}`}>
              Message
            </div>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.message}
                  helperText={errors?.message?.message}
                  required
                  className={`w-full ${themeMode === "dark" ? "create-campaign-dialog-field" : "create-campaign-dialog-field-light"}`}
                  multiline
                  rows={3}
                  size="small"
                  sx={{
                    "& .MuiInputBase-input": {
                      color: themeMode === "dark" ? "#FFF" : "#000",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                        color: themeMode === "dark" ? "#FFF" : "#000",
                      },
                    },
                  }}
                />
              )}
              name="message"
              control={control}
            />

            <div className={`create-campaign-dialog-text pt-4 pb-1 ${themeMode === "dark" ? "text-light" : "text-dark"}`}>
              Personalization Token
            </div>
            <div className="flex items-center space-x-4">
              <Chip
                label={"First Name"}
                variant="outlined"
                className={themeMode === "dark" ? "table-row-tags" : "table-row-tags-light"}
                sx={{ color: themeMode === "dark" ? "#FFF" : "#000", fontSize: "12px" }}
                onClick={() => {
                  const currentValue = getValues("message") || "";
                  setValue("message", currentValue + "{first_name}", {
                    shouldValidate: true,
                  });
                }}
              />
              <Chip
                label={"Last Name"}
                variant="outlined"
                className={themeMode === "dark" ? "table-row-tags" : "table-row-tags-light"}
                sx={{ color: themeMode === "dark" ? "#FFF" : "#000", fontSize: "12px" }}
                onClick={() => {
                  const currentValue = getValues("message") || "";
                  setValue("message", currentValue + "{last_name}", {
                    shouldValidate: true,
                  });
                }}
              />
            </div>

            <div className={`create-campaign-dialog-text pt-4 pb-1 ${themeMode === "dark" ? "text-light" : "text-dark"}`}>
              Assign to Campaign
            </div>

            <Controller
              render={({ field }) => (
                <FormControl error={!!errors.campaign_uuid} fullWidth>
                  <Select
                    className={`w-full ${themeMode === "dark" ? "create-campaign-dialog-field" : "create-campaign-dialog-field-light"}`}
                    displayEmpty
                    size="small"
                    defaultValue="0f3ce770-47b5-4cf5-b1fb-4deb0236d114"
                    sx={{
                      minWidth: "259px",
                      "& .MuiSelect-icon": {
                        color: themeMode === "dark" ? "#FFF" : "#000",
                      },
                      "& .MuiSelect-select.MuiSelect-select": {
                        color: themeMode === "dark" ? "#FFF" : "#000",
                        fontWeight: "100px",
                      },
                      "& .MuiSelect-select": {
                        color: "blue", // Text color of the selected item
                      },
                      "& .MuiInputLabel-root": {
                        color: "red",
                      },
                      ".MuiOutlinedInput-notchedOutline": { border: 0 },
                      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          border: 0,
                        },
                    }}
                    {...field}
                  >
                    {campaigns?.map((campaign) => (
                      <MenuItem key={campaign.uuid} value={campaign.uuid}>
                        {campaign.title}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {errors?.campaign_uuid?.message}
                  </FormHelperText>
                </FormControl>
              )}
              name="campaign_uuid"
              control={control}
            />

            <div className="flex w-full justify-center pt-8">
              <Button
                className={`p-2 ${themeMode === "dark" ? "create-button" : "create-button-light"}`}
                variant="contained"
                onClick={handleSubmit(onSubmit)}
              >
                <span className="create-button-text">Save</span>
              </Button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
}
