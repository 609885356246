import { Grid } from "@mui/material";
import NavbarApp from "./navbar/NavbarApp";
import Toolbar from "./toolbar/Toolbar";
import Preview from "./Preview";
import { useSelector } from "react-redux";


const Dashboard = () => {
  const themeMode = useSelector((state) => state.themeMode.mode);
  return (
    <>
      <Grid
        className={
          themeMode === "dark"
            ? "dashboard-bg h-full"
            : "dashboard-bg-light h-full"
        }
      >
        <NavbarApp />
        <Grid
          item
          md={1}
          sm={0}
          sx={{ display: { md: "flex", sm: "none", xs: "none" } }}
        >
          <Toolbar />
        </Grid>
        <Grid
          item
          pl={{ md: 16, sm: 0, xs: 0 }}
          md={10}
          sm={12}
          xs={12}

        >
          <Preview />
        </Grid>
      </Grid>

    </>
  );
};
export default Dashboard;
