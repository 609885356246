import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { error } from "./alertSlice";
import api from "../services/api";

const initialState = {
    payments_invoices: [],
};

export const getPayments = createAsyncThunk(
  "payments/getPayments",
  async ({ page = 1, per_page = 10 }, { dispatch }) => {
    try {
      const res = await api.get(`/payments`, {
        params: {
          page: page ? page : 1,
          per_page: per_page ? per_page : 10,
        },
      });
      return { data: res?.data, status: res?.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPayments.fulfilled, (state, action) => {
        state.payments_invoices = action.payload;
      })
  },
});

export const { } = paymentsSlice.actions;

export default paymentsSlice.reducer;