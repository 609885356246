import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { UserProfile } from "./profile/UserProfile";
import { LinkedIn } from "./linkedin/LinkedIn";
import { Tags } from "./tags/Tags";
import { QuickResponses } from "./quick-responses/QuickResponses";
import { EmailNotifications } from "./email-notifications/EmailNotifications";
import { Organization } from "./organization/Organization";
import { getUserProfile } from "../../store/userProfileSlice";
import { useDispatch } from "react-redux";
import {
  getEmailNotifications,
  getLinkedInConnectedStatus,
  getOrganization,
  getQuickResponses,
  getTags,
} from "../../store/settingsSlice";
import { AddMembers } from "./add-member/AddMember";
import { PushNotifications } from "./push-notifications/PushNotifications";

export const Settings = () => {
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(
    useSelector((state) => state.settings.selectedTabValue)
  );
  const themeMode = useSelector((state) => state.themeMode.mode);

  useEffect(() => {
    dispatch(getUserProfile());
    dispatch(getLinkedInConnectedStatus());
    dispatch(getTags());
    dispatch(getQuickResponses());
    dispatch(getEmailNotifications());
    dispatch(getOrganization());
  }, []);

  return (
    <div
      className={themeMode === "dark" ? "dashboard-bg" : "dashboard-bg-light"}
    >
      <div className="py-24 md:px-16 px-8">
        <div
          className={`dashboard-title ${
            themeMode === "dark" ? "text-light" : "text-dark"
          }`}
        >
          Settings
        </div>
        <div
          className={`dashboard-subtitle py-4 ${
            themeMode === "dark" ? "text-light" : "text-dark"
          }`}
        >
          Manage the settings of your profile and other general features.{" "}
        </div>
        <div
          className={`my-4 ${
            themeMode === "dark" ? "settings-layout" : "settings-layout-light"
          }`}
        >
          <div className="lg:flex w-full">
            <div
              className={`xl:w-1/5 lg:w-1/5 lg:border-r-2 ${
                themeMode === "light" ? "greySidePanel" : ""
              }`}
            >
              <Button
                className={`py-4 w-full
                  ${
                    themeMode === "dark" && tabValue == 1
                      ? "settings-panel-btn-contained"
                      : themeMode === "dark" && tabValue != 1
                      ? "settings-panel-btn-outlined"
                      : themeMode === "light" && tabValue == 1
                      ? "settings-panel-btn-contained-light"
                      : themeMode === "light" && tabValue != 1
                      ? "settings-panel-btn-outlined-light"
                      : ""
                  }`}
                sx={{
                  borderRadius: {
                    md: "10px 0px 0px 0px",
                    sm: "10px 10px 0px 0px",
                    xs: "10px 10px 0px 0px",
                    overflow: "none",
                  },
                }}
                onClick={() => setTabValue(1)}
                variant="text"
              >
                <span
                  className={
                    tabValue == 1
                      ? "settings-panel-text"
                      : themeMode === "dark"
                      ? "settings-panel-text-inactive"
                      : "settings-panel-text-inactive-light"
                  }
                >
                  User Profile
                </span>
              </Button>
              <Button
                className={`py-4 w-full
                  ${
                    themeMode === "dark" && tabValue == 2
                      ? "settings-panel-btn-contained"
                      : themeMode === "dark" && tabValue != 2
                      ? "settings-panel-btn-outlined"
                      : themeMode === "light" && tabValue == 2
                      ? "settings-panel-btn-contained-light"
                      : themeMode === "light" && tabValue != 2
                      ? "settings-panel-btn-outlined-light"
                      : ""
                  }`}
                onClick={() => setTabValue(2)}
                variant="text"
              >
                <span
                  className={
                    tabValue == 2
                      ? "settings-panel-text"
                      : themeMode === "dark"
                      ? "settings-panel-text-inactive"
                      : "settings-panel-text-inactive-light"
                  }
                >
                  Linkedin
                </span>
              </Button>
              <Button
                className={`py-4 w-full
                  ${
                    themeMode === "dark" && tabValue == 3
                      ? "settings-panel-btn-contained"
                      : themeMode === "dark" && tabValue != 3
                      ? "settings-panel-btn-outlined"
                      : themeMode === "light" && tabValue == 3
                      ? "settings-panel-btn-contained-light"
                      : themeMode === "light" && tabValue != 3
                      ? "settings-panel-btn-outlined-light"
                      : ""
                  }`}
                onClick={() => setTabValue(3)}
                variant="text"
              >
                <span
                  className={
                    tabValue == 3
                      ? "settings-panel-text"
                      : themeMode === "dark"
                      ? "settings-panel-text-inactive"
                      : "settings-panel-text-inactive-light"
                  }
                >
                  Tags
                </span>
              </Button>
              <Button
                className={`py-4 w-full
                  ${
                    themeMode === "dark" && tabValue == 4
                      ? "settings-panel-btn-contained"
                      : themeMode === "dark" && tabValue != 4
                      ? "settings-panel-btn-outlined"
                      : themeMode === "light" && tabValue == 4
                      ? "settings-panel-btn-contained-light"
                      : themeMode === "light" && tabValue != 4
                      ? "settings-panel-btn-outlined-light"
                      : ""
                  }`}
                onClick={() => setTabValue(4)}
                variant="text"
              >
                <span
                  className={
                    tabValue == 4
                      ? "settings-panel-text"
                      : themeMode === "dark"
                      ? "settings-panel-text-inactive"
                      : "settings-panel-text-inactive-light"
                  }
                >
                  Quick Response
                </span>
              </Button>
              <Button
                className={`py-4 w-full
                  ${
                    themeMode === "dark" && tabValue == 5
                      ? "settings-panel-btn-contained"
                      : themeMode === "dark" && tabValue != 5
                      ? "settings-panel-btn-outlined"
                      : themeMode === "light" && tabValue == 5
                      ? "settings-panel-btn-contained-light"
                      : themeMode === "light" && tabValue != 5
                      ? "settings-panel-btn-outlined-light"
                      : ""
                  }`}
                onClick={() => setTabValue(5)}
                variant="text"
              >
                <span
                  className={
                    tabValue == 5
                      ? "settings-panel-text"
                      : themeMode === "dark"
                      ? "settings-panel-text-inactive"
                      : "settings-panel-text-inactive-light"
                  }
                >
                  Email Notifications
                </span>
              </Button>
              <Button
                className={`py-4 w-full
                  ${
                    themeMode === "dark" && tabValue == 6
                      ? "settings-panel-btn-contained"
                      : themeMode === "dark" && tabValue != 6
                      ? "settings-panel-btn-outlined"
                      : themeMode === "light" && tabValue == 6
                      ? "settings-panel-btn-contained-light"
                      : themeMode === "light" && tabValue != 6
                      ? "settings-panel-btn-outlined-light"
                      : ""
                  }`}
                onClick={() => setTabValue(6)}
                variant="text"
              >
                <span
                  className={
                    tabValue == 6
                      ? "settings-panel-text"
                      : themeMode === "dark"
                      ? "settings-panel-text-inactive"
                      : "settings-panel-text-inactive-light"
                  }
                >
                  Organization
                </span>
              </Button>
              <Button
                className={`py-4 w-full
                  ${
                    themeMode === "dark" && tabValue == 7
                      ? "settings-panel-btn-contained"
                      : themeMode === "dark" && tabValue != 7
                      ? "settings-panel-btn-outlined"
                      : themeMode === "light" && tabValue == 7
                      ? "settings-panel-btn-contained-light"
                      : themeMode === "light" && tabValue != 7
                      ? "settings-panel-btn-outlined-light"
                      : ""
                  }`}
                onClick={() => setTabValue(7)}
                variant="text"
              >
                <span
                  className={
                    tabValue == 7
                      ? "settings-panel-text"
                      : themeMode === "dark"
                      ? "settings-panel-text-inactive"
                      : "settings-panel-text-inactive-light"
                  }
                >
                  Add Member
                </span>
              </Button>
              <Button
                className={`py-4 w-full
                  ${
                    themeMode === "dark" && tabValue == 8
                      ? "settings-panel-btn-contained"
                      : themeMode === "dark" && tabValue != 8
                      ? "settings-panel-btn-outlined"
                      : themeMode === "light" && tabValue == 8
                      ? "settings-panel-btn-contained-light"
                      : themeMode === "light" && tabValue != 8
                      ? "settings-panel-btn-outlined-light"
                      : ""
                  }`}
                onClick={() => setTabValue(8)}
                variant="text"
              >
                <span
                  className={
                    tabValue == 8
                      ? "settings-panel-text"
                      : themeMode === "dark"
                      ? "settings-panel-text-inactive"
                      : "settings-panel-text-inactive-light"
                  }
                >
                  Push Notifications
                </span>
              </Button>
            </div>
            <div
              className="w-full xl:w-4/5 lg:w-4/5 p-8 lg:px-16"
              style={{ minHeight: "580px" }}
            >
              {tabValue == 1 && <UserProfile />}
              {tabValue == 2 && <LinkedIn />}
              {tabValue == 3 && <Tags />}
              {tabValue == 4 && <QuickResponses />}
              {tabValue == 5 && <EmailNotifications />}
              {tabValue == 6 && <Organization />}
              {tabValue == 7 && <AddMembers />}
              {tabValue == 8 && <PushNotifications />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
