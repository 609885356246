import { Link } from "react-router-dom";
import { Grid, Typography, Divider, Button } from "@mui/material";
import {company, product, footLinks } from "./links";
import { useStyles } from "./useStyles";
import { useNavigate } from "react-router-dom";
const logo = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/logoFullName%20Grey.png";

const Footer = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleNavigateHome = () => {
        navigate("/");
    };

    return (
        <Grid
            item
            container
            // spacing={1}
            className={classes.body}
            sx={{
                display:"flex",
                justifyContent: { md: "center", sm: "center", xs: "center" },
                padding: {
                    md: "40px 70px 30px",
                    sm: "40px 70px 30px",
                    xs: "40px 10px 30px",
                },
                alignItems: { md: "start", sm: "center", xs: "center" },
                
            }}
        >
            <Grid item lg={6} md={4} sm={12} xs={12}>
                <Grid 
                    sx={{
                        display:"flex",
                        flexDirection:"column", 
                        textAlign: { md: "left", sm: "center", xs: "center" }, 
                        justifyContent: { md: "left", sm: "center", xs: "center" },
                        alignItems:{md:"flex-start",sm:"center",xs:"center"} }}>
                    
                    <Grid item md={12} sm={12} xs={9} my={4} sx={{ display: "flex", justifyContent: { md: "left", sm: "center", xs: "center" }, textAlign: { md: "left", sm: "center", xs: "center" } }}>
                        <Button disableRipple onClick={handleNavigateHome} sx={{padding:"0px",minWidth:"0px","&:hover":{background:"transparent"}}}>
                            <img src={logo} alt="" style={{width:"300px"}}/>
                        </Button>
                    </Grid>
                    <Typography
                        my={2}
                        sx={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: "700", color:"#979797" }}
                    >
                        Follow us on
                    </Typography>

                    <Grid container className={classes.fitRow} sx={{justifyContent:{md:"flex-start",sm:"center",xs:"center"}}}>
                        {footLinks.map((item) => (
                            <Grid item key={item.id} className={classes.my15}>
                                <Link to={item.link}>
                                    <item.img />
                                </Link>
                            </Grid>
                        ))}
                    </Grid>

                </Grid>
            </Grid>

            <Grid item lg={6} md={8} sm={6} xs={10}>
                <Grid
                    container item
                    md={12} sm={12} xs={12}
                    columnGap={{md:8,sm:8,xs:4}}
                    className={classes.flex}
                    sx={{ justifyContent: { md: "flex-end", sm: "left", xs: "center" },paddingRight:{md:"40px",sm:"",xs:""} }}
                >
                    
                    <Grid sx={{ my: 3 }}>
                        <Typography className={classes.head}>Company</Typography>
                        {company?.map((user) => (
                            <li key={user.id} className={classes.list} >
                                <Link className={classes.link} to={user.link} sx={{ fontSize: {md:"16px",sm:"16px",xs:"12px"}, }}>
                                    {user.name}
                                </Link>
                            </li>
                        ))}
                    </Grid>

                    <Grid sx={{ my: 3 }}>
                        <Typography className={classes.head}>Product</Typography>
                        {product?.map((user) => (
                            <li key={user.id} className={classes.list}>
                                <Link className={classes.link} to={user.link} sx={{ fontSize: { md: "16px", sm: "16px", xs: "12px" }, }}>
                                    {user.name}
                                </Link>
                            </li>
                        ))}
                    </Grid>



                  
                </Grid>
            </Grid>
            
            <Grid item md={12} sm={12} xs={12} sx={{display:"flex",alignItems:"center",flexDirection:"column"}}>
            <Divider className={classes.divider} color="#FFF" sx={{ mt: 8, }} />
            <Typography mt={3} sx={{textAlign:"center"}}>
                    © 2024 FacoonLabs. All rights reserved.
            </Typography>

            </Grid>
        </Grid>
    );
};
export default Footer;
