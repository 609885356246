/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import {
  Autocomplete,
  Button,
  Checkbox,
  Grid,
  createFilterOptions,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Hidden,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  InputAdornment,
  useMediaQuery
} from "@mui/material";
import { countriesList } from "../onboarding/countries";
import theme from "../../Theme/theme.jsx";
import { useEffect, useState } from "react";
import { useController } from "react-hook-form";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import __ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptRequest,
  getOrganizationDetail,
  getOrganizations,
  registerUser,
} from "../store/registerSlice";
import { error, success } from "../store/alertSlice";
import Navbar from "../website/navbar/Navbar";
import Footer from "../website/footer/Footer";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";

const filter = createFilterOptions();

const defaultValues = {
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  organization_name: "",
  organization_website: "",
  reason_to_join: "",
  terms_and_conditions: false,
  payment_plan_uuid: "",
  signup_discovery_call_meeting_url: "",
  signup_discovery_call_booked_at: null,
};

const schema = yup
  .object()
  .shape({
    email: yup.string().required("You must type email"),
    reason_to_join: yup.string().required("You must type reason"),

    first_name: yup.string().required("You must type your name"),
    last_name: yup.string().required("You must type your name"),
    organization_name: yup
      .string()
      .required("You must type your organization name"),
    payment_plan_uuid: yup.string().required("You must select payment plan"),
    signup_discovery_call_meeting_url: yup
      .string()
      .required("You must book a demo with us"),
    organization_website: yup
      .string()
      .required("You must type your organization website"),
    phone_number: yup
      .string()
      .required("You must type your phone number")
      .test(
        "starts-with-plus",
        "Phone number must include dial code for e.g (+1)",
        (value) => value && value.startsWith("+")
      ),
    terms_and_conditions: yup.boolean().oneOf([true], "You must check"),
  })
  .required();

export const Register = () => {
  const dispatch = useDispatch();
  const [value, setOrgValue] = useState(null);
  const [orgWebsiteDisabled, setOrgWebsiteDisabled] = useState(false);
  const [showRegisterMessage, setShowRegisterMessage] = useState(false);
  const [flag, setFlag] = useState(null); // State to store the selected flag
  const [phoneNumber, setPhoneNumber] = useState(''); // Store phone number

  const isCustomRange = useMediaQuery('(max-width:385px)');
  const registeredUser = useSelector((state) => state.register.registeredUser);
  const organizations = useSelector((state) => state.register.organizations);
  const preselected_payment_plan = useSelector(
    (state) => state.register.preselected_payment_plan
  );

  const organizationDetail = useSelector(
    (state) => state.register.organizationDetail
  );

  const [selectedTab, setSelectedTab] = useState(
    preselected_payment_plan ? preselected_payment_plan.uuid : null
  );
  const [selectedPlan, setSelectedPlan] = useState(preselected_payment_plan);

  const { handleSubmit, setValue, reset, control, watch, formState } = useForm({
    defaultValues,
    mode: "all",
    resolver: yupResolver(schema),
  });

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: (e) => console.log(e),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: (e) => {
      console.log(e.data.payload);
      setValue("signup_discovery_call_meeting_url", e.data.payload.invitee.uri);
    },
    onPageHeightResize: (e) => console.log(e.data.payload.height),
  });

  const handleChange = (tabValue) => {
    setSelectedTab(tabValue.uuid);
    setSelectedPlan(tabValue);
    setValue("payment_plan_uuid", tabValue.uuid);
  };

  const { errors } = formState;

  useEffect(() => {
    dispatch(getOrganizations());
  }, [dispatch]);

  const formData = watch();
  console.log("🚀 ~ Register ~ formData:", formData);
  const prefill = {
    email: formData.email,
    firstName: formData.first_name,
    lastName: formData.last_name,
    name: `${formData.first_name} ${formData.last_name}`,
  };
  const handleEventScheduled = (event) => {
    console.log("Event scheduled:", event);
  };

  function onSubmit(data) {
    dispatch(registerUser(data)).then((resp) => {
      if (resp.payload?.status === 201) {
        dispatch(success(`Please check your email`));
        setShowRegisterMessage(true);
        // dispatch(acceptRequest(resp.payload.data.uuid)).then((resps) => {
        //   if (resps.payload.status === 200) {
        //     dispatch(
        //       success(`Password is ${resps.payload.data.inferred_password}`)
        //     );
        //   }
        // });
      } else {
      }
    });
  }

  useEffect(() => {
    // Find country based on phone number starting with its dial_code
    const findCountry = countriesList.find((country) =>
      formData.phone_number.startsWith(country.dial_code)
    );

    // If country is found, set the flag, else set it to null
    if (findCountry) {
      setFlag(findCountry.img);
    } else {
      setFlag(null);
    }
  }, [formData.phone_number]);

  const handlePhoneNumberChange = (value) => {
    if (value.startsWith("+")) {
      setPhoneNumber(value);
    } else {
      setPhoneNumber("+" + value);
    }
  };
  const gradientAnimation = keyframes`
    0% {
    background-position: bottom left, top right;
  }
  25% {
    background-position: top right, bottom left;
  }
  50% {
    background-position: bottom right, top left;
  }
  75% {
    background-position: bottom left, bottom right;
  }
  100% {
    background-position: top left, top right;
  }
`;


  return (
    <div>
      <div style={{ backgroundColor: "red" }}>
        <Navbar />
      </div>
      <div
        className="lg:flex w-full h-full mt-24"
        // style={{
        //   backgroundImage: `url(https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/signUp.gif)`,
        //   backgroundSize: "cover",
        //   // height: "150vh",
        // }}
        css={css`
          background-color: #4844E4;
      background-image:
        radial-gradient(circle at top right, #000121 10%, #4844E4 24%, #282876 42%, #4844E4 61%, #282876 93%),
        radial-gradient(circle at bottom left, #062144 10%, #4405CA 24%, #062144 42%, #4405CA 61%, #062144 93%);
      background-size: 240% 240%;
      animation: ${gradientAnimation} 20s ease infinite;
      `}
      >
        <Hidden mdDown>
          <div className="w-full lg:w-1/2 px-16">
            <div className="flex justify-start mt-10">
              <img
                src="https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/logoIcon.png"
                alt="logo-icon"
                className=""
              />
            </div>
            <div className="login-container">
              <div className="login-text">Welcome to</div>
              <div className="login-text2">FacoonLabs</div>
            </div>
          </div>
        </Hidden>
        <div style={{}}>
          {" "}
          <div
            className="w-full h-full"
            style={{
              backgroundColor: "#EFEFEF",
              borderRadius: "30px 0px 0px 30px",
            }}
          >
            <div className="p-8 px-16">
              {" "}
              <div className="login-heading">Sign up</div>
              <div className="login-content pt-6">
                Join FacoonLabs and explore a world of seamless automation.
                Streamline your workflows and connect with a community of
                innovators. Your journey to smarter solutions starts here!"
              </div>
            </div>

            <div
              className=""
              style={{
                backgroundImage: `url(	https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/signUpBg.png)`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "bottom right",
              }}
            >
              <Grid 
                sx={{height:"100%",width:"100%",paddingX:{md:"4rem",sm:"4rem",xs:"2rem"}}}
                //className="px-16 w-full h-full"
              >
                <div className="md:flex md:space-x-8 space-y-8 md:space-y-0 justify-between">
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="auth-form-input w-full"
                        placeholder="First Name"
                        error={!!errors.first_name}
                        helperText={errors?.first_name?.message}
                        required
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                          },
                        }}
                      />
                    )}
                    name="first_name"
                    control={control}
                  />

                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="auth-form-input w-full"
                        placeholder="Last Name"
                        error={!!errors.last_name}
                        helperText={errors?.last_name?.message}
                        required
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                          },
                        }}
                      />
                    )}
                    name="last_name"
                    control={control}
                  />
                </div>
                <div className="md:flex md:space-x-8 justify-between pt-8 space-y-8 md:space-y-0">
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="auth-form-input w-full"
                        placeholder="Email"
                        error={!!errors.email}
                        helperText={errors?.email?.message}
                        type="email"
                        required
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                          },
                        }}
                      />
                    )}
                    name="email"
                    control={control}
                  />
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="auth-form-input w-full"
                        placeholder="Phone Number"
                        error={!!errors.phone_number}
                        helperText={errors?.phone_number?.message}
                        required
                        InputProps={{
                          startAdornment: flag ? (
                            <InputAdornment position="start">
                              <img
                                src={flag}
                                alt="Country flag"
                                style={{ width: "24px", height: "24px" }}
                              />
                            </InputAdornment>
                          ) : null,
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                          },
                        }}
                      />
                    )}
                    name="phone_number"
                    control={control}
                  />
                </div>
                <div className="md:flex md:space-x-8 justify-between pt-8 space-y-8 md:space-y-0 w-full">
                  <Controller
                    control={control}
                    name="members"
                    className="w-full"
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        className="w-full"
                        value={value}
                        onChange={(event, newValue) => {
                          if (typeof newValue === "string") {
                            setOrgValue({
                              title: newValue,
                            });
                            dispatch(getOrganizationDetail(newValue?.title));
                            setValue("organization_name", newValue?.title, {
                              shouldValidate: true,
                              shouldDirty: true,
                            });
                            setValue(
                              "organization_website",
                              newValue?.website,
                              {
                                shouldValidate: true,
                                shouldDirty: true,
                              }
                            );
                            setSelectedPlan(newValue?.preselected_payment_plan);
                            setSelectedTab(
                              newValue?.preselected_payment_plan?.uuid
                            );

                            setValue(
                              "payment_plan_uuid",
                              newValue?.preselected_payment_plan?.uuid,
                              {
                                shouldValidate: true,
                                shouldDirty: true,
                              }
                            );

                            setOrgWebsiteDisabled(true);
                          } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setOrgValue({
                              title: newValue.inputValue,
                            });
                            dispatch(
                              getOrganizationDetail(newValue.inputValue)
                            );
                            setValue("organization_name", newValue.inputValue, {
                              shouldValidate: true,
                              shouldDirty: true,
                            });
                            setValue("organization_website", "", {
                              shouldValidate: true,
                              shouldDirty: true,
                            });
                            setSelectedPlan(newValue?.preselected_payment_plan);
                            setSelectedTab(
                              newValue?.preselected_payment_plan?.uuid
                            );
                            setValue(
                              "payment_plan_uuid",
                              newValue?.preselected_payment_plan?.uuid,
                              {
                                shouldValidate: true,
                                shouldDirty: true,
                              }
                            );
                            setOrgWebsiteDisabled(false);
                          } else {
                            setOrgValue(newValue);
                            dispatch(getOrganizationDetail(newValue?.title));
                            setValue("organization_name", newValue?.title, {
                              shouldValidate: true,
                              shouldDirty: true,
                            });
                            setValue(
                              "organization_website",
                              newValue?.website,
                              {
                                shouldValidate: true,
                                shouldDirty: true,
                              }
                            );
                            setSelectedPlan(newValue?.preselected_payment_plan);
                            setSelectedTab(
                              newValue?.preselected_payment_plan?.uuid
                            );
                            setValue(
                              "payment_plan_uuid",
                              newValue?.preselected_payment_plan?.uuid,
                              {
                                shouldValidate: true,
                                shouldDirty: true,
                              }
                            );
                            setOrgWebsiteDisabled(true);
                          }
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;
                          // Suggest the creation of a new value
                          const isExisting = options.some(
                            (option) => inputValue === option.title
                          );
                          if (inputValue !== "" && !isExisting) {
                            filtered.push({
                              inputValue,
                              title: `Add "${inputValue}"`,
                            });
                          }

                          return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="free-solo-with-text-demo"
                        options={organizations}
                        getOptionLabel={(option) => {
                          // Value selected with enter, right from the input
                          if (typeof option === "string") {
                            return option;
                          }
                          // Add "xxx" option created dynamically
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          // Regular option
                          return option.title;
                        }}
                        renderOption={(props, option) => {
                          const { key, ...optionProps } = props;
                          return (
                            <li key={key} {...optionProps}>
                              {option.title}
                            </li>
                          );
                        }}
                        // freeSolo
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="auth-form-input w-full"
                            placeholder="Organization Name"
                            error={!!errors.organization_name}
                            helperText={errors?.organization_name?.message}
                            required
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  border: "none",
                                },
                              },
                            }}
                          />
                        )}
                      />
                    )}
                  />
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        disabled={orgWebsiteDisabled}
                        className="auth-form-input w-full"
                        placeholder="Organization Website"
                        error={!!errors.organization_website}
                        helperText={errors?.organization_website?.message}
                        required
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                          },
                        }}
                      />
                    )}
                    name="organization_website"
                    control={control}
                  />
                </div>
                <div className="md:flex md:space-x-8 justify-between pt-8 space-y-8 md:space-y-0">
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        multiline
                        rows={3}
                        className="auth-form-input w-full"
                        placeholder="Reason to join"
                        error={!!errors.reason_to_join}
                        helperText={errors?.reason_to_join?.message}
                        required
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                          },
                        }}
                      />
                    )}
                    name="reason_to_join"
                    control={control}
                  />
                </div>

                {value && (
                  <div className="confirm-details-field-label-light pt-8 pb-2">
                    Select Plan*
                  </div>
                )}

                {value && (
                  <div className="flex items-center justify-between md:justify-start md:space-y-0 flex-wrap md:space-x-4  ">
                    {organizationDetail?.map((plan, index) => (
                      <Button
                        disabled={preselected_payment_plan}
                        className={
                          selectedTab === plan.uuid
                            ? "select-plan-btn-contained-reg p-3 px-6 mb-2 md:mt-2"
                            : "select-plan-btn-outlined-reg p-3 px-6 mb-2 md:mt-2"
                        }
                        onClick={() => handleChange(plan)}
                      >
                        <span className="select-plan-btn-text-reg">
                          {plan.name}
                        </span>
                      </Button>
                    ))}
                    {value && selectedPlan?.custom === true && (
                      <Button
                        disabled={preselected_payment_plan}
                        className={
                          "select-plan-btn-contained-reg p-3 px-6 mb-2 md:mt-2"
                        }
                      >
                        <span className="select-plan-btn-text-reg">
                          {selectedPlan.name}
                        </span>
                      </Button>
                    )}
                  </div>
                )}

                {selectedPlan && (
                  <div className="select-plan-btn-text-reg-det pb-2">
                    ${selectedPlan.price + "/" + selectedPlan.tier}
                  </div>
                )}

                {value && formData.payment_plan_uuid === "" && (
                  <div
                    className="select-plan-btn-text-reg-det pb-2"
                    style={{ color: "red" }}
                  >
                    Please select plan
                  </div>
                )}

                <div
                  className="sign-button flex w-full justify-center mt-8 mb-4"
                  style={{ color: "black", fontSize: "20px" }}
                >
                  Book a demo with us*
                </div>
                <Grid mb={2} sx={{
                  transform: { md: "scale(1)", sm: "scale(1)", xs: "scale(0.82)" }, 
                  position:{md:"block",sm:"block",xs:isCustomRange?"relative":"block"},right:"30px" ,
                  width:"100%",
                  
                  [theme.breakpoints.down(425)]: {
                     width: "70vh",
                   },
                   [theme.breakpoints.down(360)]: {
                     width: "60vh",
                   }
                }}>
                  <InlineWidget
                    url="https://calendly.com/ben-facoonlabs/30min"
                    prefill={prefill}
                    sx={{width:"100%"}}
                  />
                </Grid>
                {formData.signup_discovery_call_meeting_url === "" && (
                  <div
                    className="select-plan-btn-text-reg-det pb-2"
                    style={{ color: "red" }}
                  >
                    Please book a demo with us to proceed.
                  </div>
                )}

                <div className="text-black pt-6">
                  <Controller
                    name="terms_and_conditions"
                    control={control}
                    render={({ field: { onChange, value, onBlur, ref } }) => (
                      <FormControl
                        error={!!errors.terms_and_conditions}
                        required
                      >
                        <FormControlLabel
                          label={
                            <>
                              By checking this box, you are agreeing to our{" "}
                              <Link to="/terms-condition">
                                <span style={{ color: "#2200f2" }}>
                                  {" "}
                                  Terms and Conditions{" "}
                                </span>
                              </Link>
                              &{" "}
                              <Link to="/privacy-policy">
                                {" "}
                                <span style={{ color: "#2200f2" }}>
                                  {" "}
                                  Privacy Policy{" "}
                                </span>
                              </Link>
                            </>
                          }
                          control={
                            <Checkbox
                              size="large"
                              sx={{
                                color: "#676767",
                                "&.Mui-checked": {
                                  color: "#2200f2",
                                },
                              }}
                              checked={value}
                              onBlur={onBlur}
                              onChange={(ev) => onChange(ev.target.checked)}
                              inputRef={ref}
                              required
                            />
                          }
                        />
                        <FormHelperText>
                          {errors?.terms_and_conditions?.message}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </div>
                <div className="pt-10">
                  {showRegisterMessage && (
                    <div
                      className="select-plan-btn-text-reg-det pb-2"
                      style={{ color: "green" }}
                    >
                      Account creation request successful. It will be reviewed
                      by our team and upon approval, you will be receiving an
                      email.
                    </div>
                  )}

                  <Button
                    variant="contained"
                    size="large"
                    style={{
                      backgroundColor: "#3633A7",
                      color: "white",
                      borderRadius: "30px",
                    }}
                    onClick={handleSubmit(onSubmit)}
                  >
                    <span className="sign-button">Create Account</span>
                  </Button>

                  <div className="text-black pt-6 pb-16">
                    Already have an account?{" "}
                    <span className="font-bold text-[#2200F2]">
                      {" "}
                      <Link to={"/login"}>Log in here</Link>
                    </span>
                  </div>
                </div>
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
