import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { error } from "./alertSlice";
import api from "../services/api";

const initialState = {
};

export const cancelSubscription = createAsyncThunk(
    "subscriptions/cancelSubscription",
    async (_, { dispatch, getState }) => {
      try {
        const res = await api.put(`/subscriptions/cancel`);
        return { data: res.data, status: res.status };
      } catch (err) {
        console.error(err);
        return null;
      }
    }
);

export const changeSubscriptionPlan = createAsyncThunk(
  "subscriptions/changeSubscriptionPlan",
  async ({payment_plan}, { dispatch, getState }) => {
    try {
      const res = await api.put(`/subscriptions/change`, { payment_plan });
      return { data: res.data, status: res.status };
    } catch (err) {
      console.error(err);
      return null;
    }
  }
);

export const subscriptionsSlice = createSlice({
    name: "subscriptions",
    initialState,
    reducers: {},
    extraReducers: (builder) => {},
});

export const { } = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;