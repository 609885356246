import { Grid } from "@mui/material";
import { AutomatedMessagingCard } from "./AutomatedMessagingCard";
import { AddFollowupMessageCard } from "./AddFollowupMessageCard";
import FollowUpSendTime from "./FollowUpSendTime";
import { useState } from "react";
import CreateFollowUpMessageDialog from "./CreateFollowUpMessageDialog";
import EditFollowUpMessageDialog from "./EditFollowUpMessageDialog";
import { useSelector } from "react-redux";

export const AutomatedMessaging = () => {
  const campaign = useSelector((state) => state.campaign.campaign);
  const themeMode = useSelector((state) => state.themeMode.mode);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openEdit, setOpenEdit] = useState(false);

  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  return (
    <div>
      <div
        className={
          themeMode === "dark"
            ? "p-8 my-8 dashboard-table-layout"
            : "p-8 my-8 dashboard-table-layout-light"
        }
      >
        <div
          className={
            themeMode === "dark"
              ? "dashboard-subtitle"
              : "dashboard-subtitle-light"
          }
        >
          Automated messages are what your prospects see when you reach out to
          them on LinkedIn. Follow up messages are used to gently nudge a
          prospect to reply.
        </div>

        <div
          className={
            themeMode === "dark"
              ? "search-header-title py-4"
              : "search-header-title-light py-4"
          }
        >
          Edit your follow up messages{" "}
        </div>
        <Grid container spacing={2}>
          {campaign?.automated_messages?.map((automatedMessage, index) => (
            <Grid item lg={4} md={6} sm={12} key={index}>
              <AutomatedMessagingCard
                title={"Connection Request Message"}
                content={automatedMessage.message}
                automatedMessage={automatedMessage}
                handleClose={handleCloseEdit}
                handleClickOpen={handleClickOpenEdit}
                themeMode={themeMode}
              />{" "}
            </Grid>
          ))}

          <Grid item lg={4} md={6} sm={12} onClick={handleClickOpen}>
            <AddFollowupMessageCard
              handleClose={handleCloseEdit}
              handleClickOpen={handleClickOpenEdit}
              themeMode={themeMode}
            />{" "}
          </Grid>
        </Grid>

        <div
          className={
            themeMode === "dark"
              ? "search-header-title pt-12"
              : "search-header-title-light pt-12"
          }
        >
          Follow up send time{" "}
        </div>
        <div
          className={
            themeMode === "dark"
              ? "dashboard-subtitle py-2"
              : "dashboard-subtitle-light py-2"
          }
        >
          Tip: Take at least 60 mins gap to send a follow up message.
        </div>
        <FollowUpSendTime />
      </div>
      <CreateFollowUpMessageDialog
        open={open}
        handleClose={handleClose}
        handleClickOpen={handleClickOpen}
      />
      <EditFollowUpMessageDialog
        open={openEdit}
        handleClose={handleCloseEdit}
        handleClickOpen={handleClickOpenEdit}
      />
    </div>
  );
};
