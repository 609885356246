import { Button, Grid,useMediaQuery } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'; 
import { useState } from "react";
import TimeZone from "./time-zone/TimeZone";
import { TeamMembers } from "./team-members/TeamMembers";
import { SearchList } from "./search-list/SearchList";
import Summary from "./summary/Summary";
import InMailMessaging from "./inMailMessaging/InMailMessaging";
import { AutomatedMessaging } from "./automated-messaging/AutomatedMessaging";
import AICampaignOptimization from "./ai-campaign-optimization/AICampaignOptimization.js";
import { useSelector } from "react-redux";


const twinkle = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/twinkle.png";

export const CampaignTabs = ({setTab}) => {
  const [selectedTab, setSelectedTab] = useState(1);
  const themeMode = useSelector((state) => state.themeMode.mode);
  const isCustomRange = useMediaQuery('(min-width:900px) and (max-width:1336px)');
  const handleChange = (tabValue) => {
    setSelectedTab(tabValue);
    setTab(tabValue);
  };

  return (
    <div className="py-8">
      {selectedTab < 7 &&
      <Grid sx={{display:"flex",flexDirection:{md:isCustomRange?"column-reverse":"row",sm:"column-reverse",xs:"column-reverse"},justifyContent:"space-between"}}>
        <Grid className=" flex flex-col lg:flex-row space-y-4 lg:space-y-0 space-x-2 lg:space-x-2" sx={{ width: { md: isCustomRange ? "100%":"fit-content",sm:"100%",xs:"100%"}}}>
        <Button
          className={
            selectedTab === 1
              ? themeMode === "dark"
                ? "table-filter-btn-contained p-3 px-4"
                : "table-filter-btn-contained-light p-3 px-4"
              : themeMode === "dark"
              ? "table-filter-btn-outlined p-3 px-4"
              : "campaign-tab-btn-outlined-light p-3 px-4"
          }
          onClick={() => handleChange(1)}
        >
          <span
            className={
              selectedTab === 1
                ? themeMode === "dark"
                  ? "table-filter-btn-text-light"
                  : "table-filter-btn-text"
                : themeMode === "dark"
                ? "table-filter-btn-text"
                : "table-filter-btn-text-light"
            }
          >
            Summary
          </span>
        </Button>
        <Button
          className={
            selectedTab === 2
              ? themeMode === "dark"
                ? "table-filter-btn-contained p-3 px-4"
                : "table-filter-btn-contained-light p-3 px-4"
              : themeMode === "dark"
              ? "table-filter-btn-outlined p-3 px-4"
              : "campaign-tab-btn-outlined-light p-3 px-4"
          }
          onClick={() => handleChange(2)}
        >
          <span
            className={
              selectedTab === 2
                ? themeMode === "dark"
                  ? "table-filter-btn-text-light"
                  : "table-filter-btn-text"
                : themeMode === "dark"
                ? "table-filter-btn-text"
                : "table-filter-btn-text-light"
            }
          >
            Team Members
          </span>
        </Button>
        <Button
          className={
            selectedTab === 3
              ? themeMode === "dark"
                ? "table-filter-btn-contained p-3 px-4"
                : "table-filter-btn-contained-light p-3 px-4"
              : themeMode === "dark"
              ? "table-filter-btn-outlined p-3 px-4"
              : "campaign-tab-btn-outlined-light p-3 px-4"
          }
          onClick={() => handleChange(3)}
        >
          <span
            className={
              selectedTab === 3
                ? themeMode === "dark"
                  ? "table-filter-btn-text-light"
                  : "table-filter-btn-text"
                : themeMode === "dark"
                ? "table-filter-btn-text"
                : "table-filter-btn-text-light"
            }
          >
            Search List
          </span>
        </Button>
        <Button
          className={
            selectedTab === 4
              ? themeMode === "dark"
                ? "table-filter-btn-contained p-3 px-4"
                : "table-filter-btn-contained-light p-3 px-4"
              : themeMode === "dark"
              ? "table-filter-btn-outlined p-3 px-4"
              : "campaign-tab-btn-outlined-light p-3 px-4"
          }
          onClick={() => handleChange(4)}
        >
          <span
            className={
              selectedTab === 4
                ? themeMode === "dark"
                  ? "table-filter-btn-text-light"
                  : "table-filter-btn-text"
                : themeMode === "dark"
                ? "table-filter-btn-text"
                : "table-filter-btn-text-light"
            }
          >
            Automated Messaging
          </span>
        </Button>
        <Button
          className={
            selectedTab === 5
              ? themeMode === "dark"
                ? "table-filter-btn-contained p-3 px-4"
                : "table-filter-btn-contained-light p-3 px-4"
              : themeMode === "dark"
              ? "table-filter-btn-outlined p-3 px-4"
              : "campaign-tab-btn-outlined-light p-3 px-4"
          }
          onClick={() => handleChange(5)}
        >
          <span
            className={
              selectedTab === 5
                ? themeMode === "dark"
                  ? "table-filter-btn-text-light"
                  : "table-filter-btn-text"
                : themeMode === "dark"
                ? "table-filter-btn-text"
                : "table-filter-btn-text-light"
            }
          >
            InMail Messaging
          </span>
        </Button>
        <Button
          className={
            selectedTab === 6
              ? themeMode === "dark"
                ? "table-filter-btn-contained p-3 px-4"
                : "table-filter-btn-contained-light p-3 px-4"
              : themeMode === "dark"
              ? "table-filter-btn-outlined p-3 px-4"
              : "campaign-tab-btn-outlined-light p-3 px-4"
          }
          onClick={() => handleChange(6)}
        >
          <span
            className={
              selectedTab === 6
                ? themeMode === "dark"
                  ? "table-filter-btn-text-light"
                  : "table-filter-btn-text"
                : themeMode === "dark"
                ? "table-filter-btn-text"
                : "table-filter-btn-text-light"
            }
          >
            Timezone & Sending Hours
          </span>
        </Button>
        </Grid>
        <Grid mb={{ md: isCustomRange ? 4: 0,sm:4,xs:4}} sx={{display:"flex",justifyContent:"center"}}>
          <Button sx={{ textTransform: "none", textWrap: "nowrap", color: "#FFF", background: themeMode === "dark" ? "#7545FF99" : "#3202bd", width: { md: isCustomRange ? "100%" : "fit-content", sm: "100%", xs: "100%" }, borderRadius: "30px", padding: "8px 15px", position: "relative", "&:hover": { background: themeMode === "dark" ? "#622dfa99" :"#3e00f2" } }}
            onClick={() => handleChange(7)}
          >
            <img src={twinkle} alt="" style={{ position: "absolute", top: "-4px", left: "-4px" }} />
            AI Optimization
          </Button>
        </Grid>
      </Grid>
      }
      {selectedTab >= 7 &&
      <Grid sx={{display:"flex",flexDirection:{md:isCustomRange?"column-reverse":"row",sm:"column-reverse",xs:"column-reverse"},justifyContent:"center"}}>
        <Grid className=" flex flex-col lg:flex-row space-y-4 lg:space-y-0 space-x-2 lg:space-x-2" sx={{ width: { md: isCustomRange ? "100%":"fit-content",sm:"100%",xs:"100%"}}}>
          <Button
            className={
              selectedTab === 7
                ? themeMode === "dark"
                  ? "table-filter-btn-contained p-3 px-4"
                  : "table-filter-btn-contained-light p-3 px-4"
                : themeMode === "dark"
                ? "table-filter-btn-outlined p-3 px-4"
                : "campaign-tab-btn-outlined-light p-3 px-4"
            }
            onClick={() => handleChange(7)}
          >
            <span
              className={
                selectedTab === 7
                  ? themeMode === "dark"
                    ? "table-filter-btn-text-light"
                    : "table-filter-btn-text"
                  : themeMode === "dark"
                  ? "table-filter-btn-text"
                  : "table-filter-btn-text-light"
              }
            >
              Preference
            </span>
          </Button>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%", 
            }}
          >
            <ArrowForwardIcon sx={{ color: themeMode === "dark" ? 'white' : 'black', marginX: '10px' }} />
          </div>
          <Button
            className={
              selectedTab === 8
                ? themeMode === "dark"
                  ? "table-filter-btn-contained p-3 px-4"
                  : "table-filter-btn-contained-light p-3 px-4"
                : themeMode === "dark"
                ? "table-filter-btn-outlined p-3 px-4"
                : "campaign-tab-btn-outlined-light p-3 px-4"
            }
            onClick={() => handleChange(8)}
          >
            <span
              className={
                selectedTab === 8
                  ? themeMode === "dark"
                    ? "table-filter-btn-text-light"
                    : "table-filter-btn-text"
                  : themeMode === "dark"
                  ? "table-filter-btn-text"
                  : "table-filter-btn-text-light"
              }
            >
              Adjust Filters
            </span>
          </Button>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%", 
            }}
          >
            <ArrowForwardIcon sx={{ color: themeMode === "dark" ? 'white' : 'black', marginX: '10px' }} />
          </div>
          <Button
            className={
              selectedTab === 9
                ? themeMode === "dark"
                  ? "table-filter-btn-contained p-3 px-4"
                  : "table-filter-btn-contained-light p-3 px-4"
                : themeMode === "dark"
                ? "table-filter-btn-outlined p-3 px-4"
                : "campaign-tab-btn-outlined-light p-3 px-4"
            }
            onClick={() => handleChange(9)}
          >
            <span
              className={
                selectedTab === 9
                  ? themeMode === "dark"
                    ? "table-filter-btn-text-light"
                    : "table-filter-btn-text"
                  : themeMode === "dark"
                  ? "table-filter-btn-text"
                  : "table-filter-btn-text-light"
              }
            >
              Parameters
            </span>
          </Button>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%", 
            }}
          >
            <ArrowForwardIcon sx={{ color: themeMode === "dark" ? 'white' : 'black', marginX: '10px' }} />
          </div>
          <Button
            className={
              selectedTab === 10
                ? themeMode === "dark"
                  ? "table-filter-btn-contained p-3 px-4"
                  : "table-filter-btn-contained-light p-3 px-4"
                : themeMode === "dark"
                ? "table-filter-btn-outlined p-3 px-4"
                : "campaign-tab-btn-outlined-light p-3 px-4"
            }
            onClick={() => handleChange(10)}
          >
            <span
              className={
                selectedTab === 10
                  ? themeMode === "dark"
                    ? "table-filter-btn-text-light"
                    : "table-filter-btn-text"
                  : themeMode === "dark"
                  ? "table-filter-btn-text"
                  : "table-filter-btn-text-light"
              }
            >
              Save Changes
            </span>
          </Button>
        </Grid>
      </Grid>
      }
      <div>
        {selectedTab === 1 && <Summary />}
        {selectedTab === 2 && <TeamMembers />}
        {selectedTab === 3 && <SearchList />}
        {selectedTab === 4 && <AutomatedMessaging />}
        {selectedTab === 5 && <InMailMessaging />}
        {selectedTab === 6 && <TimeZone />}
        {selectedTab >= 7 && <AICampaignOptimization selectedTab={selectedTab} setSelectedTab={handleChange}/>}
      </div>
    </div>
  );
};
