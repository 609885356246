import { Grid, Button, Typography, Divider, Menu, MenuItem } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeFirstLetter } from "../../../utils/globalFunctions.js";
import dayjs from 'dayjs';
import { Visibility } from "@mui/icons-material";
import ViewDebitCard from "./ViewDebitCard.js";
import { getPaymentMethods, setPaymentAsDefault } from "../../store/paymentMethodSlice.js";

const stripeBlue = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/stripeBlue.png"
const stripe = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/stripe.png";
const paypal = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/PaypalP.png"
const visa = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/visaBlue.png"
const greyDots = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/greyDots.png";
const whiteDots = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/whiteDots.png";

const PaymentMethods = () => {
    const dispatch = useDispatch();

    const themeMode = useSelector((state) => state.themeMode.mode);
    const payments_invoices = useSelector((state) => state.payments.payments_invoices);
    const state = useSelector((state) => state);
    const all_payment_methods = useSelector((state) => state.paymentMethod);
    const payment_methods = useSelector((state) => state.paymentMethods.paymentMethods || []);

    const [hovered, setHovered] = useState(false);
    const [hide, setHide] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [selectedMethod,setSelectedMethod]=useState(null)
    const buttonRef = useRef(null);

    const myVisaNumber = "1234567891011121314";

    const handleClickOpen = (event, id) => {
        setAnchorEl(event.currentTarget);
        setSelectedMethod(id);
    };
    console.log("SS",selectedMethod);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const ShowDebitCard = () => {
        setOpen(true);
    };

    const HideDebitCard = () => {
        setOpen(false);
    };


    useEffect(() => {
        dispatch(getPaymentMethods());
    }, [dispatch]);

    const setDefaultMethod = () => {
        dispatch(setPaymentAsDefault(selectedMethod))
    }
    
    const selectedPaymentMethod = payment_methods.uuid===hovered;
    console.log("Methods", payment_methods);

    return (
        <div>
            <div className="pt-24 pb-6 md:px-16 px-8">
                <div
                    className={`dashboard-title ${themeMode === "dark" ? "text-light" : "text-dark"
                        }`}
                >
                    Payment Methods
                </div>
                <div
                    className={`dashboard-subtitle py-4 ${themeMode === "dark" ? "text-light" : "text-dark"
                        }`}
                >
                    In this page, you can  see and edit your payment methods.{" "}
                </div>
            </div>
            <Grid
                container
                className="md:px-16 px-8"
                sx={{ display: "flex", flexDirection: "row" }}
            >
                <Grid
                    item
                    md={6}
                    sm={12}
                    xs={12}
                    sx={{
                        display: "flex",
                        flexDirection: { md: "row", sm: "row", xs: "column" },
                    }}
                >
                    <Grid
                        item
                        md={6}
                        sm={12}
                        sx={{ display: "flex", flexDirection: "column" }}
                    >
                        <Grid
                            sx={{
                                display: "flex",
                                justifyContent: { md: "left", sm: "center" },
                                color: themeMode === "dark" ? "#FFF" : "#000",
                            }}
                        >
                            Current Plan
                        </Grid>
                        <Grid
                            sx={{
                                display: "flex",
                                color: themeMode === "dark" ? "#BDFF00" : "#608104",
                                fontSize: "22px",
                                fontWeight: "700",
                                justifyContent: { md: "left", sm: "center" },
                            }}
                        >
                            {capitalizeFirstLetter(payments_invoices?.data?.current_plan?.payment_plan?.name)}
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        md={6}
                        sm={12}
                        sx={{ display: "flex", flexDirection: "column" }}
                    >
                        <Grid
                            sx={{
                                display: "flex",
                                justifyContent: { md: "left", sm: "center" },
                                color: themeMode === "dark" ? "#FFF" : "#000",
                            }}
                        >
                            Next Payment Date
                        </Grid>
                        <Grid
                            sx={{
                                display: "flex",
                                color: themeMode === "dark" ? "#BDFF00" : "#608104",
                                fontSize: "22px",
                                fontWeight: "700",
                                justifyContent: { md: "left", sm: "center" },
                            }}
                        >
                            ${payments_invoices?.data?.current_plan?.payment_plan?.price}
                            <span
                                style={{
                                    color: themeMode === "dark" ? "#FFF" : "#000",
                                    marginLeft: "10px",
                                }}
                            >
                                on {dayjs(payments_invoices?.data?.current_plan?.next_date)?.format('D MMM YYYY')}
                            </span>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    my={{ md: "", sm: 3, xs: 3 }}
                    item
                    md={6}
                    sm={12}
                    xs={12}
                    sx={{
                        display: "flex",
                        justifyContent: { md: "flex-end", sm: "center" },
                        alignItems: "center",
                    }}
                >
                    <Button
                        onClick={() => (window.location.href = "add-payment-method")}
                        sx={{
                            padding: "8px 15px",
                            height: "fit-content",
                            color: themeMode === "dark" ? "#FFF" : "#FFF",
                            border:
                                themeMode === "dark" ? "1px solid #FFF" : "1px solid #000",
                            background: themeMode === "dark" ? "#FF0000" : "#BE0000",
                            borderRadius: "30px",
                            textTransform: "capitalize",
                            textWrap: "nowrap",
                            width: "fit-content",
                            fontSize: { md: "14px", sm: "14px", xs: "12px" },
                            "&:hover": {
                                color: "#FFF",
                                background: themeMode === "dark" ? "#BE0000" : "#FF0000",
                            },
                        }}
                    >
                        Add Payment Method{" "}
                    </Button>
                </Grid>
            </Grid>
            <Grid className="md:px-16 px-8" sx={{ display: "flex", justifyContent: "center" }}>
                <Divider sx={{ width: "100%", orientation: "horizonal", background: themeMode === "dark" ? "#FFF" : "#000", height: "2px" }} />
            </Grid>
            <Grid className="pt-8 pb-6 md:px-16 px-8" sx={{ display: "flex", flexDirection: "column" }}>
                {payment_methods
                    .filter(method => method.payment_method_type === "stripe")
                    .map((stripeMethod, index) => (
                        <Grid
                            key={stripeMethod.uuid}
                            className="mt-5"
                            sx={{
                                width: { md: "40%", sm: "75%", xs: "100%" },
                                color: themeMode === "dark" ? "#FFF" : "#000"
                            }}
                        >
                            <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
                                <Grid>
                                    <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
                                        Stripe
                                    </Typography>
                                </Grid>
                                <Grid>
                                    <Button
                                        disableRipple
                                        sx={{
                                            minWidth: "0px",
                                            padding: "5px 10px",
                                            "&:hover": { background: "transparent" }
                                        }}
                                        onClick={(event) => handleClickOpen(event, stripeMethod.uuid)}
                                        onMouseEnter={() => setHovered(stripeMethod.uuid)}
                                        onMouseLeave={() => setHovered(false)}
                                        ref={buttonRef}
                                    >
                                        <img src={hovered === stripeMethod.uuid ? whiteDots : greyDots} alt="" />
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid className="py-3" sx={{ display: "flex" }}>
                                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <img src={themeMode==="dark"?stripe:stripeBlue} alt="" style={{ width: "70px" }} />
                                </Grid>
                                <Grid className="mx-4" sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                    <Typography
                                        sx={{
                                            fontSize: { md: "14px", sm: "14px", xs: "11px" },
                                            fontWeight: "600",
                                            textWrap: "nowrap"
                                        }}
                                    >
                                        Credit
                                        <span className="mx-2">
                                            **** **** **** {stripeMethod?.stripte_fields?.last4}
                                        </span>
                                        <Button
                                            onClick={ShowDebitCard}
                                            disableRipple
                                            sx={{
                                                padding: "0px 5px",
                                                minWidth: "0px",
                                                color: themeMode === "dark" ? "#FFF" : "#000",
                                                marginLeft: "5px"
                                            }}
                                        >
                                            <Visibility />
                                        </Button>
                                    </Typography>
                                    {stripeMethod.default_method && (
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: "400",
                                                color: themeMode === "dark" ? "#80FF00" : "#03660D"
                                            }}
                                        >
                                            Primary
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid className="md:px-8 px-4 my-2" sx={{ display: "flex", justifyContent: "center" }}>
                                <Divider
                                    sx={{
                                        width: "100%",
                                        orientation: "horizontal",
                                        background: themeMode === "dark" ? "#FFF" : "#000",
                                        height: "0.5px"
                                    }}
                                />
                            </Grid>
                        </Grid>
                    ))}


                {payment_methods
                    .filter(method => method.payment_method_type === "paypal")
                    .map((paypalMethod, index) => (
                        <Grid sx={{ width: { md: "40%", sm: "75%", xs: "100%" }, color: themeMode === "dark" ? "#FFF" : "#000" }}>
                            <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
                                <Grid>
                                    <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
                                        Paypal
                                    </Typography>
                                </Grid>
                                <Grid>
                                    <Button disableRipple
                                        sx={{ minWidth: "0px", padding: "5px 10px", "&:hover": { background: "transparent" } }}
                                        onClick={handleClickOpen}
                                        onMouseEnter={() => setHovered("1")}
                                        onMouseLeave={() => setHovered(false)}
                                        ref={buttonRef}
                                    >
                                        <img src={hovered === "1" ? whiteDots : greyDots} alt="" />
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid className="py-3" sx={{ display: "flex" }}>
                                <Grid>
                                    <img src={paypal} alt="" style={{ width: "70px" }} />
                                </Grid>
                                <Grid className="mx-4" sx={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center" }}>
                                    <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                                        useremail@gmail.com
                                    </Typography>
                                    {
                                        payment_methods.payment_method_type === "paypal" && payment_methods.default_method ?
                                            <Typography sx={{ fontSize: "12px", fontWeight: "400", color: themeMode === "dark" ? "#80FF00" : "#03660D" }}>
                                                Primary
                                            </Typography>
                                            : ""
                                    }

                                </Grid>
                            </Grid>
                            <Grid className="md:px-8 px-4 my-2" sx={{ display: "flex", justifyContent: "center" }}>
                                <Divider sx={{ width: "100%", orientation: "horizonal", background: themeMode === "dark" ? "#FFF" : "#000", height: "0.5px" }} />
                            </Grid>
                        </Grid>
                    ))
                }


                {payment_methods
                    .filter(method => method.payment_method_type === "credit_card")
                    .map((visaMethod, index) => (
                        <Grid className="mt-5" sx={{ width: { md: "40%", sm: "75%", xs: "100%" }, color: themeMode === "dark" ? "#FFF" : "#000" }}>

                            <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
                                <Grid>
                                    <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
                                        Visa Card
                                    </Typography>
                                </Grid>
                                <Grid>
                                    <Button disableRipple
                                        sx={{ minWidth: "0px", padding: "5px 10px", "&:hover": { background: "transparent" } }}
                                        onClick={handleClickOpen}
                                        onMouseEnter={() => setHovered("2")}
                                        onMouseLeave={() => setHovered(false)}
                                        ref={buttonRef}
                                    >
                                        <img src={hovered === "2" ? whiteDots : greyDots} alt="" />
                                    </Button>

                                </Grid>

                            </Grid>
                            <Grid className="py-3" sx={{ display: "flex" }}>
                                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <img src={visa} alt="" style={{ width: "70px" }} />
                                </Grid>
                                <Grid className="mx-4" sx={{ display: "flex", flexDirection: "column", width: "100%" }}>

                                    <Typography sx={{ fontSize: { md: "14px", sm: "14px", xs: "11px" }, fontWeight: "600", textWrap: "nowrap" }}>
                                        Credit
                                        <span className="mx-2">
                                            **** **** **** 6464
                                        </span>
                                        <Button onClick={ShowDebitCard} disableRipple sx={{ padding: "0px 5px", minWidth: "0px", color: themeMode === "dark" ? "#FFF" : "#000", marginLeft: "5px" }}>
                                            <Visibility />
                                        </Button>
                                    </Typography>
                                    {
                                        payment_methods.payment_method_type === "credit_card" && payment_methods.default_method ?
                                            <Typography sx={{ fontSize: "12px", fontWeight: "400", color: themeMode === "dark" ? "#80FF00" : "#03660D" }}>
                                                Primary
                                            </Typography>
                                            : ""
                                    }
                                </Grid>
                            </Grid>
                            <Grid className="md:px-8 px-4 my-2" sx={{ display: "flex", justifyContent: "center" }}>
                                <Divider sx={{ width: "100%", orientation: "horizonal", background: themeMode === "dark" ? "#FFF" : "#000", height: "0.5px" }} />
                            </Grid>
                        </Grid>
                    ))
                }


                {payment_methods.map((method) => (
                    <Menu
                        key={method.uuid}
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        onMouseLeave={handleClose}
                        PaperProps={{
                            sx: {
                                backgroundColor: themeMode === "dark" ? "#0000004D" : "#FFFFFF4D",
                                border: themeMode === "dark" ? "1px solid #FFF" : "1px solid #000",
                                color: "white",
                                borderRadius: "10px",
                                padding: "3px 10px 3px 10px",
                                width: "195px",
                                backdropFilter: "blur(15px)"
                            },
                        }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                if (!method) {
                                    console.error('Payment method not found:', method);
                                    return;
                                }
                                console.log('Setting default method for UUID:', method.uuid);
                                // dispatch(setPaymentAsDefault({
                                //     uuid: method.uuid, 
                                // }));
                                setDefaultMethod();
                            }}
                            sx={{
                                borderRadius: "10px",
                                "&:hover": {
                                    background: themeMode === "dark" ? "#193000AA" : "#bff589AA",
                                },
                            }}
                        >
                            <Typography sx={{ display: "flex", alignItems: "center", color: themeMode === "dark" ? "#80FF00" : "#03660D" }}>
                                Make Primary
                            </Typography>
                        </MenuItem>
                        <Divider sx={{ backgroundColor: themeMode === "dark" ? "#fff" : "#000" }} />
                        <MenuItem
                            sx={{
                                borderRadius: "10px",
                                "&:hover": {
                                    background: themeMode === "dark" ? "#52000099" : "#EC9080",
                                },
                            }}
                        >
                            <Typography sx={{ display: "flex", alignItems: "center", color: themeMode === "dark" ? "#80FF00" : "#03660D" }}>
                                Remove
                            </Typography>
                        </MenuItem>
                    </Menu>
                ))}

            </Grid>
            <ViewDebitCard
                open={open}
                handleClose={HideDebitCard}
                handleClickOpen={ShowDebitCard}
            />
        </div>
    )

}
export default PaymentMethods