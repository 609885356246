import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { error } from "./alertSlice";
import api from "../services/api";
const { REACT_APP_API_URL } = process.env;

const initialState = {
    paymentMethods: [],
    primaryPaymentMethod:"",
};

export const getPaymentMethods = createAsyncThunk(
    "/payment_methods",
    async (_, { dispatch }) => { 
        try {
            const res = await axios.get(`${REACT_APP_API_URL}/payment_methods`);
            return res.data;
        } catch (err) {
            console.error('API Request Failed:', err.response ? err.response.data : err.message);
            dispatch(error(err.message));
            throw err; 
        }
    }
);


// export const setPaymentAsDefault = createAsyncThunk(
//     "payment_methods/set_default",
//     async (paymentMethods, { dispatch, rejectWithValue }) => {
//         try {
//             // Perform the PUT request to set the payment method as default
//             const putRes = await axios.put(
//                 `${REACT_APP_API_URL}/payment_methods/set_default/${paymentMethods}`
//             );

//             // If the PUT request is successful, perform a GET request to fetch the updated list
//             const getRes = await axios.get(`${REACT_APP_API_URL}/payment_methods`);

//             // Return the results of both requests
//             return {
//                 putData: putRes?.data,
//                 putStatus: putRes?.status,
//                 getData: getRes?.data,
//                 getStatus: getRes?.status
//             };
//         } catch (err) {
//             dispatch(error(err.message));
//             console.error('Error in setPaymentAsDefault:', err);
//             return rejectWithValue(err.message);
//         }
//     }
// );

// export const setPaymentAsDefault = createAsyncThunk(
//     "payment_methods/set_default",
//     async (paymentMethods, { dispatch, rejectWithValue }) => {
//         try {
//             const res = await api.put(`payment_methods/set_default/${paymentMethods.uuid}`);
//             return { data: res?.data, status: res?.status };

//         } catch (err) {
//             dispatch(error(err.message));
//             console.error('Error in setPaymentAsDefault:', err);
//             return rejectWithValue(err.message);
//         }
//     }
// );

export const setPaymentAsDefault = createAsyncThunk(
    "payment_methods/set_default",
    async (paymentMethods, { dispatch, rejectWithValue }) => {
        try {
            const res = await axios.put(
                `${REACT_APP_API_URL}/payment_methods/set_default/${paymentMethods}`);
            console.log("Response Put",res)
            return { data: res?.data, status: res?.status };
        } catch (err) {
            dispatch(error(err.message));
            console.error('Error in setPaymentAsDefault:', err);
            return rejectWithValue(err.message);
        }
    }
);

export const paymentMethodSlice = createSlice({
    name: "payments_method",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getPaymentMethods.fulfilled, (state, action) => {
                    state.paymentMethods = action.payload.data;
        })
        builder.addCase(getPaymentMethods.rejected, (state, action) => {
            console.error('Failed to fetch payment methods:', action.error.message);
        });

        builder.addCase(setPaymentAsDefault.fulfilled, (state, action) => {
            const updatedMethod = action.payload.data;
            
            state.paymentMethods = state.paymentMethods.map((method) =>
                method.uuid === updatedMethod.uuid
                    ? { ...method, default_method: true }
                    : { ...method, default_method: false }
            );
            state.primaryPaymentMethod = action.payload.data;
        });
        
        builder.addCase(setPaymentAsDefault.rejected, (state, action) => {
            console.error('Payment Default Methods Rejected:', action.error.message);
            console.error('Error Details:', action.payload);
        })

        builder.addCase(setPaymentAsDefault.pending, () => {
                console.log('Payment Default Methods Pending...'); 
            });


    },
});

export const { } = paymentMethodSlice.actions;

export default paymentMethodSlice.reducer;