import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, IconButton, TablePagination } from "@mui/material";
import {
  getSearchProspects,
  resumeAutomation,
  stopAutomation,
} from "../../../../store/campaignSlice";
import { useState } from "react";
import { LinkedIn } from "@mui/icons-material";
import { success } from "../../../../store/alertSlice";

export default function SearchListTable() {
  const dispatch = useDispatch();
  const searchProspects = useSelector(
    (state) => state.campaign.searchProspects
  );
  const { id, name } = useParams();

  const themeMode = useSelector((state) => state.themeMode.mode);
  const campaign = useSelector((state) => state.campaign.campaign);
  const prospectUser = useSelector((state) => state.campaign.prospectUser);

  const searchProspectsMeta = useSelector(
    (state) => state.campaign.searchProspectsMeta
  );

  const [page, setPage] = useState(
    searchProspectsMeta?.current_page
      ? searchProspectsMeta?.current_page - 1
      : 0
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    searchProspectsMeta?.per_page ? searchProspectsMeta?.per_page : 10
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(
      getSearchProspects({
        page: newPage + 1,
        per_page: rowsPerPage,
        campaign: id,
        user_uuid: prospectUser,
      })
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(
      getSearchProspects({
        page: 1,
        per_page: parseInt(event.target.value, 10),
        campaign: id,
        user_uuid: prospectUser,
      })
    );
  };

  return (
    <TableContainer sx={{ width: "100%", overflowX: "auto" }}>
      {searchProspects?.filter(
        (item) =>
          item.network_distance !== "DISTANCE_1" &&
          item.network_distance !== "OUT_OF_NETWORK" &&
          item.is_open_profile == false
      ).length == 0 && (
        <div className="flex w-full items-center justify-center text-center py-8">
          <span
            style={{
              color: themeMode === "dark" ? "white" : "black",
            }}
          >
            There are no records
          </span>
        </div>
      )}
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              className={
                themeMode === "dark"
                  ? "table-col-title"
                  : "table-col-title-light"
              }
              align="left"
            >
              Name{" "}
            </TableCell>
            <TableCell
              className={
                themeMode === "dark"
                  ? "table-col-title"
                  : "table-col-title-light"
              }
              align="left"
            >
              Current Job Title{" "}
            </TableCell>
            <TableCell
              className={
                themeMode === "dark"
                  ? "table-col-title"
                  : "table-col-title-light"
              }
              align="left"
            >
              Current Company{" "}
            </TableCell>
            <TableCell
              className={
                themeMode === "dark"
                  ? "table-col-title"
                  : "table-col-title-light"
              }
              align="left"
            >
              Location{" "}
            </TableCell>
            <TableCell
              className={
                themeMode === "dark"
                  ? "table-col-title"
                  : "table-col-title-light"
              }
              align="left"
            >
              Actions{" "}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {searchProspects
            ?.filter(
              (item) =>
                item.network_distance !== "DISTANCE_1" &&
                item.network_distance !== "OUT_OF_NETWORK" &&
                item.is_open_profile == false
            )
            .map((row, index) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                className="cursor-pointer"
              >
                <TableCell
                  className={
                    themeMode === "dark"
                      ? "table-row-content "
                      : "table-row-content-light  "
                  }
                >
                  <span className="flex items-center space-x-2">
                    <IconButton href={row.profile_url} target="_blank">
                      <LinkedIn
                        sx={{ color: themeMode === "dark" ? "#FFF" : "#000" }}
                      />
                    </IconButton>
                    <Avatar
                      sx={{ width: 32, height: 32 }}
                      src={row.profile_picture_url}
                    />{" "}
                    <span> {row.name}</span>
                  </span>
                </TableCell>
                <TableCell
                  className={
                    themeMode === "dark"
                      ? "table-row-content"
                      : "table-row-content-light"
                  }
                  align="left"
                >
                  {row.current_job_title}
                </TableCell>
                <TableCell
                  className={
                    themeMode === "dark"
                      ? "table-row-content"
                      : "table-row-content-light"
                  }
                  align="left"
                >
                  {row.current_company}
                </TableCell>
                <TableCell
                  className={
                    themeMode === "dark"
                      ? "table-row-content"
                      : "table-row-content-light"
                  }
                  align="left"
                >
                  <span> {row.location}</span>
                </TableCell>
                <TableCell
                  onClick={() =>
                    row.automation_enabled ? (
                      <>
                        {dispatch(stopAutomation(row.uuid)).then((res) => {
                          if (res?.payload?.status === 200) {
                            dispatch(success("Automation Stopped"));
                            dispatch(
                              getSearchProspects({
                                campaign: id,
                                user_uuid: prospectUser,
                              })
                            );
                          }
                        })}
                      </>
                    ) : (
                      <>
                        {dispatch(resumeAutomation(row.uuid)).then((res) => {
                          if (res?.payload?.status === 200) {
                            dispatch(success("Automation Resumed"));
                            dispatch(
                              getSearchProspects({
                                campaign: id,
                                user_uuid: prospectUser,
                              })
                            );
                          }
                        })}
                      </>
                    )
                  }
                  className={
                    themeMode === "dark"
                      ? "table-row-stats"
                      : "table-row-stats-light"
                  }
                  align="left"
                >
                  <span style={{ color: "#FF0000" }} className="font-bold">
                    {row.automation_enabled
                      ? "Stop Automation"
                      : "Resume Automation"}{" "}
                  </span>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={
          searchProspectsMeta?.total_count
            ? searchProspectsMeta?.total_count
            : 0
        }
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          "& .Mui-disabled": {
            color: themeMode === "dark" ? "darkgray" : "gray",
          },
          "& .MuiSelect-icon": {
            color: themeMode === "dark" ? "white" : "black",
          },
          "& .MuiTablePagination-displayedRows": {
            color: themeMode === "dark" ? "white" : "black",
            fontSize: "13px",
          },
          "& .MuiTablePagination-toolbar": {
            padding: "10px",
          },
          "& .MuiTablePagination-actions": {
            color: themeMode === "dark" ? "white" : "black",
          },
          "& .MuiTablePagination-selectLabel, & .MuiTablePagination-input": {
            color: themeMode === "dark" ? "white" : "black",
            fontSize: "13px",
          },
        }}
      />
    </TableContainer>
  );
}
