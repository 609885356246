import { useState, useEffect } from "react";
import { Grid, Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Backdrop, MenuItem, Select, TextField } from "@mui/material";
import dayjs from 'dayjs';

export default function ChangePaymentDialog({
  open,
  handleClickOpenPay,
  handleClose,
  current_plan,
}) {
  const themeMode = useSelector((state) => state.themeMode.mode);
  const organization = useSelector((state) => state.settings.organization);
  const user = useSelector((state) => state.userProfile.userProfile);
  const emailExists = organization?.admins?.some(
    (member) => member.email === user.email
  );
  const [mode, setMode] = useState("default");
  const [plan, setPlan] = useState("");
  const [planDetail, setPlanDetail] = useState(null);
  // const MonthlyPlan = () => {
  //   setPlan("monthly");
  // };
  // const AnnualPlan = () => {
  //   setPlan("annual");
  // };
  const handlePlanSelection = (tier) => {
    const newSelectedPlan = organization?.default_payment_plans?.find((payment_plan) => payment_plan?.tier === tier)
    setPlanDetail(newSelectedPlan);
    setPlan(tier);
  };

  const DefaultMode = () => {
    setMode("default");
    handleClose();
  };
  const ChangeMode = () => {
    setMode("change");
  };

  return (
    <Backdrop>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          background:
            themeMode === "dark" ? "rgba(0, 0, 0, 0.30)" : "#0000004D",
          backdropFilter: "blur(4px)",
        }}
        PaperProps={{
          sx: {
            backgroundColor:
              themeMode === "dark" ? "rgba(0, 0, 0, 0.60)" : "#FFFFFFBB", // Inner background color
            borderRadius: "10px",
            border: themeMode === "dark" ? "1px solid white" : "1px solid #000",
            color: themeMode === "dark" ? "white" : "#000",
            paddingLeft: "14px",
            paddingRight: "14px",
          },
        }}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle
          id="alert-dialog-title"
          className="w-full flex justify-end cursor-pointer pb-3"
          onClick={DefaultMode}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              d="M1.5 1L20.5 16.5M20.5 1L1.5 16.5"
              stroke={themeMode === "dark" ? "white" : "#000"}
              stroke-width="2"
            />
          </svg>{" "}
        </DialogTitle>

        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              color: themeMode === "dark" ? "white" : "#000",
              textAlign: "center",
              overflowX: "hidden",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                fontSize: "16px",
                fontWeight: "700",
                flexWrap: "wrap",
              }}
            >
              Change Subscription Plan
              <span
                style={{
                  display: mode === "change" ? "flex" : "none",
                  marginLeft: "5px",
                }}
              >
                to{" "}
                <span
                  style={{
                    color: themeMode === "dark" ? "#70FF00" : "#608104",
                    margin: "0px 5px",
                  }}
                >
                  {planDetail?.name}
                </span>
                ?
              </span>
            </Typography>
            {mode === "default" ? (
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "12px",
                  fontWeight: "400",
                  flexWrap: "wrap",
                }}
              >
                Currently, you are subscribed to the{" "}
                <span style={{ fontWeight: "700", margin: "0px 5px" }}>
                  {current_plan?.payment_plan?.name?.toLowerCase()} plan
                </span>{" "}
                in FacoonLabs
              </Typography>
            ) : mode === "change" ? (
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center !important",
                  fontSize: "13px",
                  fontWeight: "400",
                  texAlign: "center",
                  flexWrap: "wrap",
                }}
              >
                Your benefits will be changed as per the {planDetail?.name?.toLowerCase()} plan and you
                will be charged the difference amount between both plans.
              </Typography>
            ) : (
              ""
            )}
            <Grid
              item
              my={3}
              md={12}
              sx={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "12px",
                  fontWeight: "400",
                }}
              >
                {mode === "default"
                  ? "Change to:"
                  : mode === "change"
                  ? `Benefits of ${planDetail?.name} Plan:`
                  : ""}
              </Typography>
            </Grid>

            {mode === "default" ? (
              <>
                <Grid sx={{ display: "flex", justifyContent: "flex-start" }}>
                  {/* <Button
                    onClick={MonthlyPlan}
                    sx={{
                      color:
                        plan === "monthly" || themeMode === "dark"
                          ? "#FFF"
                          : "#000",
                      border:
                        themeMode === "dark"
                          ? "1px solid #fff"
                          : "1px solid #000",
                      borderRadius: "30px",
                      textTransform: "capitalize",
                      padding: "8px 20px",
                      margin: "0px 10px 0px 0px",
                      background:
                        plan === "monthly" && themeMode === "dark"
                          ? "#FF000099"
                          : plan === "monthly" && themeMode === "light"
                          ? "#BE000099"
                          : "transparent",
                      "&:hover": {
                        background:
                          plan === "monthly" ? "#ff0d0d" : "transparent",
                      },
                    }}
                  >
                    Monthly
                  </Button>
                  <Button
                    onClick={AnnualPlan}
                    sx={{
                      color:
                        plan === "annual" || themeMode === "dark"
                          ? "#FFF"
                          : "#000",
                      border:
                        themeMode === "dark"
                          ? "1px solid #fff"
                          : "1px solid #000",
                      borderRadius: "30px",
                      textTransform: "capitalize",
                      padding: "8px 20px",
                      background:
                        plan === "annual" && themeMode === "dark"
                          ? "#FF000099"
                          : plan === "annual" && themeMode === "light"
                          ? "#BE000099"
                          : "transparent",
                      "&:hover": {
                        background:
                          plan === "annual" ? "#ff0d0d" : "transparent",
                      },
                    }}
                  >
                    Annual
                  </Button> */}
                  {organization?.default_payment_plans?.map((subscription) => (
                    subscription.tier !== current_plan?.payment_plan?.tier && (
                      <Button
                        key={subscription.uuid}
                        onClick={() => handlePlanSelection(subscription.tier)}
                        sx={{
                          color:
                            plan === subscription.tier || themeMode === "dark"
                              ? "#FFF"
                              : "#000",
                          border:
                            themeMode === "dark" ? "1px solid #fff" : "1px solid #000",
                          borderRadius: "30px",
                          textTransform: "capitalize",
                          padding: "8px 20px",
                          margin: "0px 10px 0px 0px",
                          background:
                            plan === subscription.tier && themeMode === "dark"
                              ? "#FF000099"
                              : plan === subscription.tier && themeMode === "light"
                              ? "#BE000099"
                              : "transparent",
                          "&:hover": {
                            background: plan === subscription.tier ? "#ff0d0d" : "transparent",
                          },
                        }}
                      >
                        {subscription.name}
                      </Button>
                    )
                  ))}
                </Grid>
                <Grid
                  item
                  my={3}
                  md={12}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: "13px",
                      fontWeight: "400",
                      alignItems: "center",
                      flexDirection: { md: "row", sm: "row", xs: "column" },
                    }}
                  >
                    The changes will take in effect after the end of this plan
                    cycle ({dayjs(current_plan?.end_date)?.format('D MMMM YYYY')})
                    {/* <Button
                      onClick={ChangeMode}
                      disableRipple
                      sx={{
                        display: plan === "annual" ? "flex" : "none",
                        color: themeMode === "dark" ? "#70FF00" : "#608104",
                        fontSize: "14px",
                        textTransform: "capitalize",
                        textWrap: "nowrap",
                        padding: 0,
                        minWidth: "0",
                      }}
                    >
                      Change it now
                    </Button> */}
                  </Typography>
                  {current_plan?.payment_plan?.tier !== planDetail?.tier && ( 
                  <Typography
                    my={1}
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      fontSize: "12px",
                      fontWeight: "400",
                      flexWrap: "wrap",
                    }}
                  >
                    Note: You will be charged{" "}
                    <span style={{ fontWeight: "700", margin: "0px 5px" }}>
                      {/* {plan === "annual" ? "$2200" : "$200"} */}
                      ${planDetail?.price || 0}
                    </span>{" "}
                    on {dayjs(current_plan?.end_date).add(1, 'day').format('D MMMM YYYY')}
                  </Typography>
                  )}
                </Grid>
              </>
            ) : mode === "change" ? (
              <>
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  {planDetail?.benefits?.map((benefit, index) => (
                      <Typography class="text-small" key={index}>
                        {benefit}{" "}
                      </Typography>
                    ))}
                </Grid>
                <Grid
                  my={2}
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Typography sx={{ fontSize: "14px", fontWeight: "700" }}>
                    You will be charged the difference amount:{" "}
                    <span
                      style={{
                        color: themeMode === "dark" ? "#8FFF00" : "#608104",
                        marginLeft: "5px",
                      }}
                    >
                    {(planDetail?.price - current_plan?.payment_plan?.price) >= 0 
                      ? `+$${planDetail?.price - current_plan?.payment_plan?.price}` 
                      : `-$${Math.abs(planDetail?.price - current_plan?.payment_plan?.price)}`
                    }
                    </span>
                  </Typography>
                </Grid>
              </>
            ) : (
              ""
            )}
            <Grid
              item
              md={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {/* <Button
                // disabled={!emailExists}
                onClick={mode === "default" ? MonthlyPlan : handleClickOpenPay}
                sx={{
                  color: "#FFF",
                  border:
                    themeMode === "dark" ? "1px solid #FFF" : "1px solid #000",
                  borderRadius: "30px",
                  textTransform: "capitalize",
                  padding: "8px 25px",
                  background: themeMode === "dark" ? "#730101" : "#BE0000",
                  "&:hover": {
                    background: themeMode === "dark" ? "#FF000099" : "#d10202",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#a1a1a1", // Customize disabled background color
                    color: "#ffffff", // Customize disabled text color
                  },
                }}
              >
                {mode === "default" ? "Change it now" : "Pay Now"}
              </Button> */}
              <Button
                disabled={(!plan || current_plan?.payment_plan?.tier === planDetail?.tier) && mode === "default"}
                onClick={() => mode === "default" ? ChangeMode() : (handleClickOpenPay(plan), setMode("default"))}
                sx={{
                  color: "#FFF",
                  border:
                    themeMode === "dark" ? "1px solid #FFF" : "1px solid #000",
                  borderRadius: "30px",
                  textTransform: "capitalize",
                  padding: "8px 25px",
                  background: themeMode === "dark" ? "#730101" : "#BE0000",
                  "&:hover": {
                    background: themeMode === "dark" ? "#FF000099" : "#d10202",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#a1a1a1", // Customize disabled background color
                    color: "#ffffff", // Customize disabled text color
                  },
                }}
              >
                {mode === "default" ? "Change it now" : "Pay Now"}
              </Button>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
}
