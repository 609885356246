import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";

export const ContactUsForm = () => {
  const [age, setAge] = useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <div>
      <div>
        <div className="input-label pb-2">Full name</div>
        <TextField
          id="name"
          variant="outlined"
          size="small"
          className="w-full"
          style={{ border: "1px solid white" }}
          inputProps={{ style: { color: "white" } }}
        />
      </div>
      <div className="mt-6">
        <div className="input-label pb-2">Email</div>
        <TextField
          id="Email"
          variant="outlined"
          size="small"
          className="w-full"
          style={{ border: "1px solid white" }}
          inputProps={{ style: { color: "white" } }}
        />
      </div>
      <div className="mt-6">
        <div className="input-label pb-2">Phone number</div>
        <TextField
          id="Phone"
          variant="outlined"
          size="small"
          className="w-full"
          style={{ border: "1px solid white" }}
          inputProps={{ style: { color: "white" } }}
        />
      </div>
      <div className="mt-6">
        <div className="input-label pb-2">Issue Category</div>
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={age}
            label="Age"
            onChange={handleChange}
            size="small"
            sx={{
              ".MuiSelect-icon": {
                color: "white",
              },
              ".MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              boxShadow: "none",
            }}
            style={{ border: "1px solid white", color: "white" }}
          >
            <MenuItem value={"onboarding"}>Onboarding</MenuItem>
            <MenuItem value={"dashboard"}>Dashboard</MenuItem>
            <MenuItem value={"payment"}>Payment</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="mt-6">
        <div className="input-label pb-2">How can we help?</div>
        <TextField
          id="help"
          variant="outlined"
          multiline
          rows={4}
          size="small"
          className="w-full"
          style={{ border: "1px solid white" }}
          inputProps={{ style: { color: "white" } }}
        />
      </div>
      <div className="mt-6">
        <FormControlLabel
          control={
            <Checkbox
              sx={{
                color: "#fff",
                "&.Mui-checked": {
                  color: "green",
                },
              }}
            />
          }
          label="I’ve read and accept privacy policy"
        />
      </div>
      <div className="mt-6">
        <Button variant="contained" className="primary-button">
          Send message
        </Button>
      </div>
    </div>
  );
};
