import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TablePagination } from "@mui/material";
import Paper from "@mui/material/Paper";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getPayments } from "../../store/paymentsSlice";
import { downloadFile } from "../../../utils/globalFunctions.js";
import dayjs from 'dayjs';
const download = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/downloadIcon.png";
const downloadBlack = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/downloadBlack.png";

function createData(sno, invoice,date,payment,download) {
  return {
    sno, invoice, date, payment,download
  };
}
const heading=[
  // "Sno", 
  "Invoice", "Date", "Payment"," "
]
const rows = [
  createData(
    "01", "INV-0801-SA-1499", "01 Aug 2024", "1499",""
  ),
  createData(
    "02", "INV-0820-DU-8419","01 Feb 2024","2299",""
  ),
  createData(
    "03", "INV-0318-SI-5456", "21 Apr 2024", "7399",""
  ),
];

export default function PaymentTable() {
  const themeMode = useSelector((state) => state.themeMode.mode);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const payments_invoices = useSelector((state) => state.payments.payments_invoices);

  const [page, setPage] = useState(0); 
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    dispatch(getPayments({ page: page + 1, per_page: rowsPerPage })); 
  }, [page, rowsPerPage, dispatch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); 
  };

  const handleDownload = async (row) => {
    if (row?.receipt_url) {
      downloadFile(row?.receipt_url, "facoon_invoice_"+row?.stripe_charge_id+'.pdf');
    }
  };

  return (
    <TableContainer sx={{ width: "100%", overflowX: "auto" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
          {/* 
            <TableCell className="table-col-title" align="left">
              Name{" "}
            </TableCell>
            <TableCell className="table-col-title" align="left">
              Meeting{" "}
            </TableCell>
            <TableCell className="table-col-title" align="left">
              Tags{" "}
            </TableCell>
            <TableCell className="table-col-title" align="left">
              Title{" "}
            </TableCell>
            <TableCell className="table-col-title" align="left">
              Company{" "}
            </TableCell>
            <TableCell className="table-col-title" align="left">
              Email{" "}
            </TableCell>
            <TableCell className="table-col-title" align="left">
              Phone Number{" "}
            </TableCell> */}
            {
              heading.map((item,index)=>(
                <TableCell className={`table-col-title ${themeMode === "dark" ? "text-light" : "text-dark"}`} align="left" sx={{ borderBottom: themeMode === "dark" ? "1px solid #FFF" : "1px solid #000" }}>
                  {item}
                </TableCell> 
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {payments_invoices?.data?.payments?.data?.map((row, index) => (
            <TableRow
              key={row.uuid}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              className="cursor-pointer"
            >
              {/* <TableCell className={` ${themeMode === "dark" ? "table-row-content text-light" : "table-row-content-light text-dark"}`}>
                {index + 1 < 10 ? `0${index + 1}` : index + 1}
              </TableCell> */}
              <TableCell className={` ${themeMode === "dark" ? "table-row-content text-light" : "table-row-content-light text-dark"}`}>
                {row.stripe_charge_id}
              </TableCell>
              <TableCell className={` ${themeMode === "dark" ? "table-row-content text-light" : "table-row-content-light text-dark"}`}>
                {dayjs(row?.paid_at)?.format('D MMM YYYY')}
              </TableCell>
              <TableCell className={` ${themeMode === "dark" ? "table-row-content text-light" : "table-row-content-light text-dark"}`}>
                {row.amount}
              </TableCell>
              <TableCell className={` ${themeMode === "dark" ? "table-row-content text-light" : "table-row-content-light text-dark"}`}>
                <Button disableRipple sx={{"&:hover":{background:"transparent"}}}
                  href={row?.receipt_url}
                  download={"facoon_invoice_"+row?.stripe_charge_id+'.pdf'}
                >
                  <img src={themeMode==="dark"?download:downloadBlack} alt=""/>
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={payments_invoices?.data?.payments?.meta?.total_count || 0} 
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          "& .Mui-disabled": {
            color: themeMode === "dark" ? "darkgray" : "gray",
          },
          "& .MuiSelect-icon": {
            color: themeMode === "dark" ? "white" : "black",
          },
          "& .MuiTablePagination-displayedRows": {
            color: themeMode === "dark" ? "white" : "black",
            fontSize: "13px",
          },
          "& .MuiTablePagination-toolbar": {
            padding: "10px",
          },
          "& .MuiTablePagination-actions": {
            color: themeMode === "dark" ? "white" : "black",
          },
          "& .MuiTablePagination-selectLabel, & .MuiTablePagination-input": {
            color: themeMode === "dark" ? "white" : "black",
            fontSize: "13px",
          },
        }}
      />
    </TableContainer>
  );
}
