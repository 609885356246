import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Backdrop,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import __ from "lodash";
import { createCampaign, getCampaigns } from "../../store/campaignSlice";
import { error, success } from "../../store/alertSlice";



export default function SuggestFeatureDialog({ open, handleClose ,state }) {
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const { handleSubmit, register, reset, control, watch, formState } = useForm({
    mode: "onSubmit",
  });

  const { errors } = formState;

  function onSubmit(data) {
    //Empty
    handleClose();
  }

console.log(state)
  
  return (
    <Backdrop>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          background: themeMode === "dark" ? "rgba(0, 0, 0, 0.60)" : "FFFFFF",
          backdropFilter: "blur(8px)",
        }}
        PaperProps={{
          sx: {
            backgroundColor:
              themeMode === "dark" ? "rgba(0, 0, 0, 0.60)" : "#FFFFFFCC",
            borderRadius: "10px",
            border: "1px solid white",
            color: themeMode === "dark" ? "white" : "black",
            paddingLeft: state==="liveSupport"?"0px":"16px",
            paddingRight:state==="liveSupport"?"0px": "16px",
          },
        }}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle
          id="alert-dialog-title"
          className="w-full flex justify-end cursor-pointer pb-4"
          onClick={handleClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
          >
            <path
              d="M1.5 1L20.5 16.5M20.5 1L1.5 16.5"
              stroke= {themeMode=== "dark" ? "white" : "#000"}
              stroke-width="2"
            />
          </svg>{" "}
        </DialogTitle>
        <DialogContent>
          {
            state==="suggest"?
              <DialogContentText
                id="alert-dialog-description"
                style={{ color: themeMode === "dark" ? "white" : "#000" }}
              >
                <div
                  className={`pb-2 ${themeMode === "dark" ? "text-light" : "text-dark"}`}
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "normal",
                    textAlign: "center"
                  }}
                >
                  Suggest a Feature
                </div>
                <div
                  className={
                    themeMode === "dark"
                      ? "create-campaign-dialog-text pb-2 pt-4"
                      : "create-campaign-dialog-text-light pb-2 pt-4"
                  }
                >
                  Message{" "}
                </div>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      multiline
                      minRows={6}
                      maxRows={8}
                      className={
                        themeMode === "dark"
                          ? "create-campaign-dialog-field w-full"
                          : "create-campaign-dialog-field-light w-full"
                      }
                      size="small"
                      sx={{
                        "& .MuiInputBase-input": {
                          color: themeMode === "dark" ? "white" : "black",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                            color: themeMode === "dark" ? "white" : "black",
                          },
                        },
                      }}
                    />
                  )}
                  name="title"
                  control={control}
                />



                <div className="flex w-full justify-center pt-8">
                  <Button
                    className={
                      themeMode === "dark"
                        ? "create-button p-2"
                        : "create-button-light p-2"
                    }
                    variant="contained"
                    onClick={handleSubmit(onSubmit)}
                  >
                    <span className="create-button-text">Send</span>
                  </Button>
                </div>
              </DialogContentText>
              :
              state === "contactUs" ?
                <DialogContentText
                  id="alert-dialog-description"
                  style={{ color: themeMode === "dark" ? "white" : "#000" }}
                >
                  <div
                    className={`pb-2 ${themeMode === "dark" ? "text-light" : "text-dark"}`}
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "700",
                      lineHeight: "normal",
                      textAlign: "center"
                    }}
                  >
                    Want to ask something?
                  </div>
                  <div
                    className={
                      themeMode === "dark"
                        ? "create-campaign-dialog-text pb-2 pt-4"
                        : "create-campaign-dialog-text-light pb-2 pt-4"
                    }
                  >
                    Email{" "}
                  </div>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder="Email"
                        className={
                          themeMode === "dark"
                            ? "create-campaign-dialog-field w-full"
                            : "create-campaign-dialog-field-light w-full"
                        }
                        size="small"
                        sx={{
                          "& .MuiInputBase-input": {
                            color: themeMode === "dark" ? "white" : "black",
                          },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                              color: themeMode === "dark" ? "white" : "black",
                            },
                          },
                        }}
                      />
                    )}
                    name="title"
                    control={control}
                  />
                  <div
                    className={
                      themeMode === "dark"
                        ? "create-campaign-dialog-text pb-2 pt-4"
                        : "create-campaign-dialog-text-light pb-2 pt-4"
                    }
                  >
                    Message{" "}
                  </div>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        multiline
                        minRows={6}
                        maxRows={8}
                        placeholder="What can we help with?"
                        className={
                          themeMode === "dark"
                            ? "create-campaign-dialog-field w-full"
                            : "create-campaign-dialog-field-light w-full"
                        }
                        size="small"
                        sx={{
                          "& .MuiInputBase-input": {
                            color: themeMode === "dark" ? "white" : "black",
                          },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                              color: themeMode === "dark" ? "white" : "black",
                            },
                          },
                        }}
                      />
                    )}
                    name="title"
                    control={control}
                  />



                  <div className="flex w-full justify-center pt-8">
                    <Button
                      className={
                        themeMode === "dark"
                          ? "create-button p-2"
                          : "create-button-light p-2"
                      }
                      variant="contained"
                      onClick={handleSubmit(onSubmit)}
                    >
                      <span className="create-button-text">Send</span>
                    </Button>
                  </div>
                </DialogContentText>
                :
              state==="liveSupport"?
              <DialogContentText
                id="alert-dialog-description"
                style={{ color: themeMode === "dark" ? "white" : "#000" }}
              >
                <div
                  className={`pb-2 ${themeMode === "dark" ? "text-light" : "text-dark"}`}
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "normal",
                    textAlign: "center"
                  }}
                >
                  Live Support
                </div>
                <div
                  className={`py-8 ${themeMode === "dark" ? "text-light" : "text-dark"}`}
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "27px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "normal",
                    textAlign: "center"
                  }}
                >
                  Coming Soon ....
                </div>
               
              </DialogContentText>
              :""
          }
          
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
}
