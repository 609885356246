import { FormControl, FormHelperText, styled, Switch } from "@mui/material";
import { initializeApp } from "firebase/app";
import { deleteToken, getMessaging, getToken } from "firebase/messaging";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { error, success } from "../../../store/alertSlice";
import {
  addRemoveToken,
  getTokens,
  setFCMToken,
} from "../../../store/loginSlice";
import NotificationPermissionDialog from "../profile/NotificationPermissionDialog";
import { useState } from "react";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 45,
  height: 26,
  padding: 0.05,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#FF0000",
      "& + .MuiSwitch-track": {
        backgroundColor: "#6A0000",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#FF0000",
      border: "6px solid cyan",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "#292929",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#292929",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const PushNotifications = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyDo0U4Vu9N3Zty4zdHV5YlpQoBHsGGQIEc",
    authDomain: "facoon-labs.firebaseapp.com",
    projectId: "facoon-labs",
    storageBucket: "facoon-labs.appspot.com",
    messagingSenderId: "126853054104",
    appId: "1:126853054104:web:77c70c1ebc92f2b8305aed",
    measurementId: "G-77HKJ8GZ27",
  };

  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { control, formState } = useForm({
    mode: "onSubmit",
  });

  const { errors } = formState;
  const themeMode = useSelector((state) => state.themeMode.mode);

  return (
    <div>
      <div
        className={`settings-heading ${
          themeMode === "dark" ? "text-light" : "text-dark"
        }`}
      >
        Push Notifications{" "}
      </div>
      <div
        className={`linkedin-secondary-text mt-2 ${
          themeMode === "dark" ? "text-light" : "text-dark"
        }`}
      >
        Manage your Notifications settings here
      </div>
      <div className="flex text-white space-x-8 items-center my-8">
        <div
          className={`profile-field-title ${
            themeMode === "dark" ? "text-light" : "text-dark"
          }`}
        >
          Push Notifications:
        </div>
        <Controller
          name="Switch"
          control={control}
          render={({ field: { onChange, value, ref, onBlur } }) => (
            <FormControl required error={!!errors.Switch}>
              <CustomSwitch
                checked={value}
                onBlur={onBlur}
                onChange={(ev) => {
                  onChange(ev.target.checked);
                  if (ev.target.checked === false) {
                    deleteToken(messaging)
                      .then(() => {
                        dispatch(
                          success("Notifications disabled for this device")
                        );
                      })
                      .catch((err) => {
                        console.log("🚀 ~ UserProfile ~ err:", err);
                        dispatch(error("Could not perform this action"));
                      });
                  } else {
                    getToken(messaging, {
                      vapidKey:
                        "BIws2Yt1MTZWLIKI4iMgrF5IPmC-C5130ZZWNDLe2BBNF4uyd4zPb3SmY9Wjutn4tikwngWaTn8LvCkqKwwgeO4",
                    })
                      .then((newToken) => {
                        console.log("New FCM token:", newToken);
                        dispatch(setFCMToken(newToken));
                        dispatch(addRemoveToken([newToken])).then(() => {
                          dispatch(getTokens());
                        });
                        dispatch(
                          success("Notifications enabled for this device")
                        );
                      })
                      .catch((err) => {
                        // dispatch(error("Could not perform this action"));
                        console.log(
                          Notification.permission,
                          "Notification.permission"
                        );
                        if (Notification.permission === "default") {
                          Notification.requestPermission().then(
                            (permission) => {
                              if (permission === "granted") {
                                dispatch(success("Notifications are allowed."));
                                // Proceed with sending notifications.
                              } else if (permission === "denied") {
                                dispatch(error("User denied notifications."));
                              }
                            }
                          );
                        } else if (Notification.permission === "denied") {
                          handleClickOpen();
                        }
                      });
                  }
                }}
                inputRef={ref}
                required
              />
              <FormHelperText>{errors?.Switch?.message}</FormHelperText>
            </FormControl>
          )}
        />
      </div>
      <NotificationPermissionDialog
        open={open}
        handleClose={handleClose}
        handleClickOpen={handleClickOpen}
      />
    </div>
  );
};
