import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { error } from "./alertSlice";
const { REACT_APP_API_URL } = process.env;

const initialState = {
  value: 0,
  campaigns: [],
  campaignMeta: {},
  inActiveCampaigns: [],
  inActiveCampaignMeta: {},
  campaign: {},
  metaCampaign: {},
  prospectUser: "",
  automatedMessaingPerformance: 0,
  inMailPerformance: 0,
  searchProspects: [],
  searchProspectsMeta: {},
  automatedMessages: [],
  automatedMessage: {},
  inMailMessage: {},
  campaignsLoading: false,
  sendingHours: {},
  campaignSendingHours: {},
  updateLoading: false,
  prospectUserDetail: {},
  searchURLSetting: false,
};

export const getCampaigns = createAsyncThunk(
  "campaign/getCampaigns",
  async (searchQuery, { dispatch, getState }) => {
    try {
      const res = await axios.get(`${REACT_APP_API_URL}/campaigns`, {
        params: {
          query: searchQuery ? searchQuery.query : "",
          page: searchQuery ? searchQuery.page : 1,
          per_page: searchQuery ? searchQuery.per_page : 25,
          is_active: true,
        },
      });

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const getinActiveCampaigns = createAsyncThunk(
  "campaign/getinActiveCampaigns",
  async (searchQuery, { dispatch, getState }) => {
    try {
      const res = await axios.get(`${REACT_APP_API_URL}/campaigns`, {
        params: {
          query: searchQuery ? searchQuery?.query : "",
          page: searchQuery ? searchQuery.page : 1,
          per_page: searchQuery ? searchQuery.per_page : 25,
          is_active: false,
        },
      });

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const getCampaignDetail = createAsyncThunk(
  "campaign/getCampaignDetail",
  async (params, { dispatch, getState }) => {
    try {
      const res = await axios.get(`${REACT_APP_API_URL}/campaigns/${params}`);

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const createCampaign = createAsyncThunk(
  "campaign/createCampaign",
  async (campaign, { dispatch, getState }) => {
    try {
      const res = await axios.post(`${REACT_APP_API_URL}/campaigns`, {
        campaign,
      });

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.response.data.error));
      console.error(err);
      return null;
    }
  }
);

export const updateCampaign = createAsyncThunk(
  "campaign/updateCampaign",
  async (campaign, { dispatch, getState }) => {
    try {
      const res = await axios.put(
        `${REACT_APP_API_URL}/campaigns/${campaign.uuid}`,
        {
          campaign,
        }
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const activateCampaign = createAsyncThunk(
  "campaign/activateCampaign",
  async (campaign, { dispatch, getState }) => {
    try {
      const res = await axios.put(
        `${REACT_APP_API_URL}/campaigns/${campaign}/activate_campaign`
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const deactivateCampaign = createAsyncThunk(
  "campaign/deactivateCampaign",
  async (campaign, { dispatch, getState }) => {
    try {
      const res = await axios.put(
        `${REACT_APP_API_URL}/campaigns/${campaign}/deactivate_campaign`
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const deleteCampaign = createAsyncThunk(
  "campaign/deleteCampaign",
  async (params, { dispatch, getState }) => {
    try {
      const res = await axios.delete(
        `${REACT_APP_API_URL}/campaigns/${params}`
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

// Team Members end-points

export const updateTeamMembers = createAsyncThunk(
  "campaign/updateTeamMembers",
  async (campaign, { dispatch, getState }) => {
    try {
      const res = await axios.put(
        `${REACT_APP_API_URL}/campaigns/${campaign.id}/add_or_remove_members`,
        { campaign }
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

// Search Prospects end-points

export const getSearchProspects = createAsyncThunk(
  "campaign/getSearchProspects",
  async (filters, { dispatch, getState }) => {
    try {
      const res = await axios.get(
        `${REACT_APP_API_URL}/campaigns/${filters.campaign}/list_prospects_from_search`,
        {
          params: {
            filter_with_user_uuid: filters.user_uuid,
            query: filters.searchQuery,
            page: filters ? filters.page : 1,
            per_page: filters ? filters.per_page : 25,
          },
        }
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const setSearchUrl = createAsyncThunk(
  "campaign/setSearchUrl",
  async (campaign, { dispatch, getState }) => {
    try {
      const res = await axios.put(
        `${REACT_APP_API_URL}/campaigns/${campaign.id}/set_search_url`,
        { campaign },
        { params: { filter_with_user_uuid: campaign.user_uuid } }
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const stopAutomation = createAsyncThunk(
  "campaign/stopAutomation",
  async (prospect, { dispatch, getState }) => {
    try {
      const res = await axios.put(
        `${REACT_APP_API_URL}/prospects/${prospect}/stop_automation`
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const resumeAutomation = createAsyncThunk(
  "campaign/resumeAutomation",
  async (prospect, { dispatch, getState }) => {
    try {
      const res = await axios.put(
        `${REACT_APP_API_URL}/prospects/${prospect}/resume_automation`
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

// Automated Messages end-points

export const getAutomatedMessages = createAsyncThunk(
  "campaign/getAutomatedMessages",
  async (params, { dispatch, getState }) => {
    try {
      const res = await axios.get(
        `${REACT_APP_API_URL}/campaigns/${params}/automated_messages`
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const createAutomatedMessages = createAsyncThunk(
  "campaign/createAutomatedMessages",
  async (campaign_automated_message, { dispatch, getState }) => {
    try {
      const res = await axios.post(
        `${REACT_APP_API_URL}/campaigns/${campaign_automated_message.id}/automated_messages`,
        { campaign_automated_message }
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.response.data.error));
      console.error(err);
      return null;
    }
  }
);

export const updateAutomatedMessages = createAsyncThunk(
  "campaign/updateAutomatedMessages",
  async (campaign_automated_message, { dispatch, getState }) => {
    try {
      const res = await axios.put(
        `${REACT_APP_API_URL}/campaigns/${campaign_automated_message.campaignId}/automated_messages/${campaign_automated_message.messageId}`,
        { campaign_automated_message }
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const deleteAutomatedMessages = createAsyncThunk(
  "campaign/deleteAutomatedMessages",
  async (params, { dispatch, getState }) => {
    try {
      const res = await axios.delete(
        `${REACT_APP_API_URL}/campaigns/${params.campaignId}/automated_messages/${params.messageId}`
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

// InMail Messages end-points

export const getInMailMessages = createAsyncThunk(
  "campaign/getInMailMessages",
  async (params, { dispatch, getState }) => {
    try {
      const res = await axios.get(
        `${REACT_APP_API_URL}/campaigns/${params}/show_inmail_message_settings`
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const updateInMailMessages = createAsyncThunk(
  "campaign/updateInMailMessages",
  async (campaign, { dispatch, getState }) => {
    try {
      const res = await axios.put(
        `${REACT_APP_API_URL}/campaigns/${campaign.id}/update_inmail_message_settings`,
        { campaign }
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const getTimeZoneSettings = createAsyncThunk(
  "campaign/getTimeZoneSettings",
  async (params, { dispatch, getState }) => {
    try {
      const res = await axios.get(
        `${REACT_APP_API_URL}/campaigns/${params}/show_campaign_settings`
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const updateTimeZoneSettings = createAsyncThunk(
  "campaign/updateTimeZoneSettings",
  async (campaign, { dispatch, getState }) => {
    try {
      const apiUrl = `${REACT_APP_API_URL}/campaigns/${campaign.uuid}/update_campaign_settings`;
      console.log("Updating campaign at URL:", apiUrl);
      console.log("Campaign data being sent:", campaign);

      const res = await axios.put(
        `${REACT_APP_API_URL}/campaigns/${campaign.uuid}/update_campaign_settings`,
        { campaign }
      );
      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    setCampaign: (state, action) => {
      state.campaign = action.payload;
    },
    setAutomatedMessage: (state, action) => {
      state.automatedMessage = action.payload;
    },
    setProspectsUserFilter: (state, action) => {
      state.prospectUser = action.payload;
    },
    setProspectsUserFilterDetail: (state, action) => {
      state.prospectUserDetail = action.payload;
    },
    setautomatedMessaingPerformance: (state, action) => {
      if (state.automatedMessaingPerformance < 2) {
        state.automatedMessaingPerformance++;
      } else {
        state.automatedMessaingPerformance = 0;
      }
    },
    setinMailPerformance: (state, action) => {
      if (state.inMailPerformance < 1) {
        state.inMailPerformance++;
      } else {
        state.inMailPerformance = 0;
      }
    },
    setTimeZone: (state, action) => {
      state.sendingHours = action.payload;
    },
    setCampaignSendingHours: (state, action) => {
      state.campaignSendingHours = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateTimeZoneSettings.pending, (state, action) => {
      state.updateLoading = true;
    });
    builder.addCase(updateTimeZoneSettings.fulfilled, (state, action) => {
      state.updateLoading = false;
    });
    builder.addCase(updateTimeZoneSettings.rejected, (state, action) => {
      state.updateLoading = false;
    });
    builder.addCase(setSearchUrl.pending, (state, action) => {
      state.searchURLSetting = true;
    });
    builder.addCase(setSearchUrl.fulfilled, (state, action) => {
      state.searchURLSetting = false;
    });
    builder.addCase(setSearchUrl.rejected, (state, action) => {
      state.searchURLSetting = false;
    });
    builder.addCase(getCampaigns.pending, (state, action) => {
      state.campaignsLoading = true;
    });
    builder.addCase(getCampaigns.fulfilled, (state, action) => {
      state.campaigns = action.payload?.data?.data;
      state.campaignMeta = action.payload?.data?.meta;
      state.campaignsLoading = false;
    });
    builder.addCase(getCampaigns.rejected, (state, action) => {
      state.campaignsLoading = false;
    });
    builder.addCase(getinActiveCampaigns.fulfilled, (state, action) => {
      state.inActiveCampaigns = action.payload?.data?.data;
      state.inActiveCampaignMeta = action.payload?.data?.meta;
    });
    builder.addCase(getCampaignDetail.pending, (state, action) => {
      state.campaignsLoading = true;
    });
    builder.addCase(getCampaignDetail.fulfilled, (state, action) => {
      state.campaign = action.payload?.data;
      state.campaignsLoading = false;
    });
    builder.addCase(getCampaignDetail.rejected, (state, action) => {
      state.campaignsLoading = false;
    });
    builder.addCase(getSearchProspects.fulfilled, (state, action) => {
      state.searchProspects = action.payload?.data?.data;
      state.searchProspectsMeta = action.payload?.data?.meta;
    });
    builder.addCase(getAutomatedMessages.fulfilled, (state, action) => {
      state.automatedMessages = action.payload;
    });
    builder.addCase(getInMailMessages.fulfilled, (state, action) => {
      state.inMailMessage = action.payload;
    });
    builder.addCase(getTimeZoneSettings.fulfilled, (state, action) => {
      state.sendingHours = action.payload;
    });
    builder.addCase(getTimeZoneSettings.rejected, (state, action) => {
      state.campaignsLoading = false;
    });
  },
});

export const {
  setCampaign,
  setAutomatedMessage,
  setProspectsUserFilter,
  setautomatedMessaingPerformance,
  setinMailPerformance,
  setTimeZone,
  setCampaignSendingHours,
  setProspectsUserFilterDetail,
} = campaignSlice.actions;

export default campaignSlice.reducer;
