import React from 'react';
import { useState } from "react";
import { Box, Typography, Button } from '@mui/material';
import { useSelector } from "react-redux";
import AutomatedMessages from "./AutomatedMessages.js";
import InMailMessages from "./InMailMessages.js";
import TimeZoneOptimization from "./TimeZoneOptimization.js";

const Parameters = ({ setSelectedTab }) => {
    const themeMode = useSelector((state) => state.themeMode.mode);
    const [selectedSubTab, setSelectedSubTab] = useState(1);

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    maxWidth: 900,
                    padding: '20px',
                    color: '#fff',
                    marginTop: '20px',
                }}
            >
                <Typography variant="h4" gutterBottom sx={{ color: themeMode === "dark" ? 'white': 'black'}}>
                    Review AI-Generated Details
                </Typography>

                <Typography variant="body1" gutterBottom sx={{ color: themeMode === "dark" ? 'white': 'black', marginBottom: '50px'}}>
                    Please review the AI suggested parameters before proceeding and do changes if necessary.
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        marginBottom: '30px',
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{

                            backgroundColor: selectedSubTab === 1 ? '#b11b1b' : 'rgba(0, 0, 0, 0.6)',
                            borderRadius: '20px',
                            '&:hover': {
                            backgroundColor: selectedSubTab === 1 ? '#9b1717' : '#555',
                            },
                        }}
                        onClick={() => setSelectedSubTab(1)}
                    >
                        Automated Messages
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
 
                            backgroundColor: selectedSubTab === 2 ? '#b11b1b' : 'rgba(0, 0, 0, 0.6)',
                            borderRadius: '20px',
                            '&:hover': {
                            backgroundColor: selectedSubTab === 2 ? '#9b1717' : '#555',
                            },
                        }}
                        onClick={() => setSelectedSubTab(2)}
                    >
                        In-Mail Messages
                    </Button>
                    <Button
                        variant="contained"
                        sx={{

                            backgroundColor: selectedSubTab === 3 ? '#b11b1b' : 'rgba(0, 0, 0, 0.6)',
                            borderRadius: '20px',
                            '&:hover': {
                            backgroundColor: selectedSubTab === 3 ? '#9b1717' : '#555',
                            },
                        }}
                        onClick={() => setSelectedSubTab(3)}
                    >
                        Time Zone
                    </Button>
                </Box>
                {selectedSubTab === 1 && <AutomatedMessages setSelectedSubTab={setSelectedSubTab} />}
                {selectedSubTab === 2 && <InMailMessages setSelectedSubTab={setSelectedSubTab} />}
                {selectedSubTab === 3 && <TimeZoneOptimization setSelectedTab={setSelectedTab} />}
            </Box>
        </>
    );
};
export default Parameters;