import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Autocomplete,
  Backdrop,
  Chip,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import { Controller, useForm } from "react-hook-form";
import __ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmailNotifications,
  updateEmailNotifications,
} from "../../../store/settingsSlice";
import { error, success } from "../../../store/alertSlice";

const _ = __.runInContext();

_.mixin({
  setIn: (state, name, value) => {
    return _.setWith(_.clone(state), name, value, _.clone);
  },
});

export default function UpdateEmailNotificationDialog({ open, handleClose }) {
  const organization = useSelector((state) => state.settings.organization);
  const dispatch = useDispatch();
  // const [orgMemebers, setOrgMembers] = React.useState(organization?.members);
  const [orgMemebers, setOrgMembers] = React.useState(
    organization?.members.concat(organization?.admins)
  );
  const themeMode = useSelector((state) => state.themeMode.mode);
  const emailNotification = useSelector(
    (state) => state.settings.emailNotification
  );
  const emailNotifications = useSelector(
    (state) => state.settings.emailNotifications
  );

  const { control, watch, reset, handleSubmit, formState, setValue } = useForm({
    mode: "onChange",
    // resolver: yupResolver(schema),
  });

  const { errors } = formState;

  const form = watch();
  console.log("🚀 ~ UpdateEmailNotificationDialog ~ form:", form);

  React.useEffect(() => {
    reset({ ...emailNotification });
  }, [emailNotification, reset]);

  function onSubmit(data) {
    const updatedData = {
      ...emailNotifications,
      [data.key]: { add: data.members, remove: data.remove ? data.remove : [] },
    };
    console.log("🚀 ~ onSubmit ~ updatedData:", updatedData);
    dispatch(updateEmailNotifications(updatedData)).then((resp) => {
      if (resp.payload.status === 200) {
        dispatch(getEmailNotifications());
        dispatch(success("Email Notifications updated"));
      } else {
        dispatch(error(resp.payload.statusText));
      }
    });
    handleClose();
  }

  return (
    <Backdrop>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          background:
            themeMode === "dark" ? "rgba(0, 0, 0, 0.30)" : "#0000004D",
          backdropFilter: "blur(4px)",
        }}
        PaperProps={{
          sx: {
            backgroundColor:
              themeMode === "dark" ? "rgba(0, 0, 0, 0.60)" : "#FFFFFFBB",
            borderRadius: "10px",
            border: themeMode === "dark" ? "1px solid white" : "1px solid #000",
            color: themeMode === "dark" ? "white" : "#000",
            paddingLeft: "16px",
            paddingRight: "16px",
          },
        }}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle
          id="alert-dialog-title"
          className="w-full flex justify-end cursor-pointer pb-1"
          onClick={handleClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
          >
            <path
              d="M1.5 1L20.5 16.5M20.5 1L1.5 16.5"
              stroke={themeMode === "dark" ? "white" : "#000"}
              stroke-width="2"
            />
          </svg>{" "}
        </DialogTitle>
        <DialogContent>
          <div className="text-center pb-8">
            <div className="font-semibold">{emailNotification.name}</div>
            <div className="text-sm">Add or remove the members here.</div>
          </div>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: themeMode === "dark" ? "white" : "#000" }}
          >
            <div
              className={`create-campaign-dialog-text pb-1 ${
                themeMode === "dark" ? "text-light" : "text-dark"
              }`}
            >
              Members to get notifications
            </div>
            <Controller
              control={control}
              name="members"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  multiple
                  id="tags"
                  className=""
                  options={orgMemebers}
                  disableCloseOnSelect
                  getOptionLabel={(option) =>
                    `${option?.first_name} ${option?.last_name}`
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={index}
                        label={`${
                          option?.first_name + " " + option?.last_name
                        }`}
                        {...getTagProps({ index })}
                        sx={{
                          bgcolor: themeMode === "dark" ? "white" : "#000", // Background color of the chip
                          color: themeMode === "dark" ? "#000" : "#FFF", // Text color of the chip
                          m: 0.5, // Margin
                          border:
                            themeMode === "dark"
                              ? "1px solid #FFF"
                              : "1px solid #000",
                          padding: "8px",
                        }}
                      />
                    ))
                  }
                  renderOption={(_props, option, { selected }) => (
                    <li {..._props}>
                      <Checkbox style={{ marginRight: 8 }} checked={selected} />
                      {option?.first_name + " " + option?.last_name}
                    </li>
                  )}
                  value={
                    value
                      ? value.map((uuid) => _.find(orgMemebers, { uuid }))
                      : []
                  }
                  onChange={(event, newValue) => {
                    onChange(newValue.map((item) => item?.uuid));
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={`w-full ${
                        themeMode === "dark"
                          ? "create-campaign-dialog-field"
                          : "create-campaign-dialog-field-light"
                      }`}
                      size="small"
                      sx={{
                        "& .MuiInputBase-input": {
                          color: themeMode === "dark" ? "white" : "#000",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                            color: themeMode === "dark" ? "white" : "#000",
                          },
                        },
                      }}
                    />
                  )}
                />
              )}
            />

            <div
              className={`create-campaign-dialog-text pb-1 pt-6 ${
                themeMode === "dark" ? "text-light" : "text-dark"
              }`}
            >
              Members to not get notifications
            </div>
            <Controller
              control={control}
              name="remove"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  multiple
                  id="remove_members"
                  className=""
                  options={orgMemebers?.filter((member) =>
                    form.members?.includes(member.uuid)
                  )}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option?.first_name}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={index}
                        label={`${
                          option?.first_name + " " + option?.last_name
                        }`}
                        {...getTagProps({ index })}
                        sx={{
                          bgcolor: themeMode === "dark" ? "white" : "#000",
                          color: themeMode === "dark" ? "#000" : "#FFF", // Background color of the chip
                          m: 0.5, // Margin
                          border:
                            themeMode === "dark"
                              ? "1px solid white"
                              : "1px solid #000",
                          padding: "8px",
                        }}
                      />
                    ))
                  }
                  renderOption={(_props, option, { selected }) => (
                    <li {..._props}>
                      <Checkbox style={{ marginRight: 8 }} checked={selected} />
                      {option?.first_name + " " + option?.last_name}
                    </li>
                  )}
                  value={
                    value
                      ? value.map((uuid) => _.find(orgMemebers, { uuid }))
                      : []
                  }
                  onChange={(event, newValue) => {
                    onChange(newValue.map((item) => item?.uuid));
                    const newArray = newValue.map((item) => item?.uuid);
                    setValue(
                      "members",
                      form.members?.filter(
                        (member) => !newArray.includes(member)
                      )
                    );
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={`w-full ${
                        themeMode === "dark"
                          ? "create-campaign-dialog-field"
                          : "create-campaign-dialog-field-light"
                      }`}
                      size="small"
                      sx={{
                        "& .MuiInputBase-input": {
                          color: themeMode === "dark" ? "#FFF" : "#000",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                            color: themeMode === "dark" ? "#FFF" : "#000",
                          },
                        },
                      }}
                    />
                  )}
                />
              )}
            />

            <div className="flex w-full justify-center pt-8">
              <Button
                className={`p-2 ${
                  themeMode === "dark" ? "create-button" : "create-button-light"
                }`}
                variant="contained"
                onClick={handleSubmit(onSubmit)}
              >
                <span className="create-button-text">Save</span>
              </Button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
}
