import { Grid,Typography } from "@mui/material";
import { PricingCard } from "./PricingCard";
import { Partners } from "../homepage/Partners";
import { BeginYourJourney } from "../homepage/BeginYourJourney";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";

export const Pricing = () => {
  return (
    <>
    <Navbar />
      <div className="flex flex-col lg:flex md:pt-24 sm:pt-10 xs:pt-8">

    <div className="pricing-bg h-full py-16 sm:px-4">
      <div className="pricing-title"> Pricing Plans</div>
      <Typography className="pricing-heading" sx={{fontSize:{md:"70px",sm:"60px",xs:"35px"}}}> Choose the Plan That</Typography>
      <Typography className="pricing-heading" sx={{fontSize:{md:"70px",sm:"60px",xs:"35px"}}}> Fits Your Needs</Typography>
          <div class="px-16 py-16 mt-16 mb-16 min-[320px]:px-2">
            <Grid container spacing={{md:9,sm:9,xs:0}} 
            style={{
              backgroundImage: "url(https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/pricingBg.png)",
              backgroundSize: "contain",
            }}>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <PricingCard
              title={"Monthly"}
              charges={"$299"}
              btnTitle={"Choose Monthly Plan"}
              features={[
                "1 LinkedIn Profile Integration",
                "60 Targeted Outreach Calls",
                "50+ New Connections Each Month",
                "Personalized Onboarding Support",
                "Monthly Strategy and Support Calls",
              ]}
            />{" "}
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12} my={{md:0,sm:4,xs:12}}>
            <PricingCard
              bestDeal
              title={"Semi Annual"}
              charges={"$1599"}
              btnTitle={"Start 6-Months Journey"}
              features={[
                "1 LinkedIn Profile Integration",
                "120 Targeted Outreach Calls",
                "500+ New Connections Each Month",
                "Enhanced Onboarding Support",
                "Bi-Monthly Strategy and Support Calls",
              ]}
            />{" "}
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <PricingCard
              title={"Annual"}
              charges={"$2999"}
              btnTitle={"Unlock Annual Benefits"}
              features={[
                "1 LinkedIn Profile Integration",
                "240 Targeted Outreach Calls",
                "1000+ New Connections Each Month",
                "Premium Onboarding Support",
                "Monthly Strategy and Support Calls",
                "Advanced Analytics & Insights",
              ]}
            />{" "}
          </Grid>
        </Grid>
      </div>
      <Partners />
      <div className="my-28">
        <BeginYourJourney />
      </div>
    </div>
    </div>
    <Footer />
    </>
  );
};
