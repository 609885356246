import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Grid,
  Button,
  Typography,
  Select,
  MenuItem,
  Switch,
  CircularProgress,
  styled,
} from "@mui/material";
import { MySwitch, TimeRangeSlider } from "./myComponents";
import { useDispatch, useSelector } from "react-redux";
import {
  updateTimeZoneSettings,
  getTimeZoneSettings,
  getCampaignDetail,
  setCampaignSendingHours,
} from "../../../../store/campaignSlice";
import { error, success } from "../../../../store/alertSlice";
import SendingHoursSlider from "./SendingHoursSlider";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 45,
  height: 26,
  padding: 0.05,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#FF0000",
      "& + .MuiSwitch-track": {
        backgroundColor: "#6A0000",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#FF0000",
      border: "6px solid cyan",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "#292929",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#292929",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const TimeZone = () => {
  const { name, id } = useParams();
  const dispatch = useDispatch();
  const campaign = useSelector((state) => state.campaign.campaign);
  const themeMode = useSelector((state) => state.themeMode.mode);
  const campaignSendingHours = useSelector(
    (state) => state.campaign.campaignSendingHours
  );
  const updateLoading = useSelector((state) => state.campaign.updateLoading);

  const [selectedTimeZone, setSelectedTimeZone] = useState(
    campaign.time_zone || ""
  );
  const [sendingHours, setSendingHours] = useState({
    ...campaign.sending_hours,
  });
  const [syncOn, setSyncOn] = useState(true);

  const handleTimeZoneChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedTimeZone(selectedValue);
  };

  const sending_hours = campaign.sending_hours;
  const week_days = Object?.keys?.(sending_hours);

  const handleSync = (i) => {
    setSyncOn(i);
  };

  const handleStartChange = (day, newStart) => {
    setSendingHours((prevState) => {
      const updatedState = {
        ...prevState,
        [day]: { ...prevState[day], start: newStart },
      };
      console.log(`Updated start time for ${day}: `, updatedState);
      return updatedState;
    });
  };

  const handleEndChange = (day, newEnd) => {
    setSendingHours((prevState) => {
      const updatedState = {
        ...prevState,
        [day]: { ...prevState[day], end: newEnd },
      };
      console.log(`Updated end time for ${day}: `, updatedState);
      return updatedState;
    });
  };

  const handleSwitchChange = (day) => {
    setSendingHours((prevState) => {
      const updatedState = {
        ...prevState,
        [day]: { ...prevState[day], enabled: !prevState[day].enabled },
      };
      console.log(`Updated state for ${day}: `, updatedState);
      dispatch(
        updateTimeZoneSettings({
          ...campaign,
          sending_hours: updatedState,
          sync_sending_hours_of_all_days: syncOn,
          time_zone: selectedTimeZone,
        })
      ).then((resp) => {
        if (resp?.payload?.status === 200) {
          dispatch(success("Campaign updated"));
          dispatch(getCampaignDetail(id));
        } else {
          dispatch(error(resp.payload.statusText));
        }
      });
      return updatedState;
    });
  };

  const handleSubmit = () => {
    console.log("🚀 ~ TimeZone ~ campaignSendingHours:", campaignSendingHours);

    if (!id) {
      console.error("Campaign ID is undefined");
      return;
    } else {
      dispatch(
        updateTimeZoneSettings({
          ...campaign,
          sending_hours: campaignSendingHours,
          sync_sending_hours_of_all_days: syncOn,
          time_zone: selectedTimeZone,
        })
      ).then((resp) => {
        if (resp?.payload?.status === 200) {
          dispatch(success("Campaign updated"));
          dispatch(getCampaignDetail(id));
        } else {
          dispatch(error(resp.payload.statusText));
        }
      });
    }
  };

  return (
    <>
      <Grid className="my-8">
        <Grid
          sx={{
            background: themeMode === "dark" ? "#00000099" : "#DEDEDE",
            border: "1px solid #FFF",
            borderRadius: "10px",
            padding: { md: "30px 30px", sm: "15px 15px", xs: "10px 10px" },
          }}
        >
          <Grid>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "600",
                color: themeMode === "dark" ? "white" : "black",
              }}
            >
              Set your Timezone and Sending Hours
            </Typography>
            <Typography
              mt={1}
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: themeMode === "dark" ? "white" : "black",
              }}
            >
              You can set the ideal timezone and sending hours for your campaign
              using the scales below. Your invites, follow-ups and automated
              messages will all be sent during the selected times.
            </Typography>
          </Grid>
          <Grid my={4}>
            <Typography
              mb={2}
              sx={{
                fontSize: "12px",
                fontWeight: "700",
                color: themeMode === "dark" ? "white" : "black",
              }}
            >
              TimeZone
            </Typography>
            <Select
              value={selectedTimeZone}
              onChange={handleTimeZoneChange}
              defaultValue={campaign.time_zone}
              className={
                themeMode === "dark"
                  ? "dashboard-field"
                  : "dashboard-field-light"
              }
              size="small"
              sx={{
                minWidth: { md: "259px", sm: "259px", xs: "200px" },
                "& .MuiSelect-icon": {
                  color: themeMode === "dark" ? "white" : "black",
                },
                "& .MuiSelect-select.MuiSelect-select": {
                  color: themeMode === "dark" ? "grey" : "black",
                },
                "& .MuiSelect-select": {
                  color: "blue",
                },
                "& .MuiInputLabel-root": {
                  color: "red",
                },
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
              }}
            >
              <MenuItem value="" disabled>
                Choose TimeZone
              </MenuItem>
              <MenuItem value={"US East +5"}>Eastern Time Zone</MenuItem>
              <MenuItem value={"US Central +6"}>Central Time Zone</MenuItem>
              <MenuItem value={"US Mountain +7"}>
                Mountain Time (US & Canada)
              </MenuItem>
              <MenuItem value={"US Pacific +8"}>Pacific Time Zone</MenuItem>
              <MenuItem value={"US Alaskan +9"}>Alaskan Time Zone</MenuItem>
              <MenuItem value={"US Hawaiian +10"}>Hawaiian Time Zone</MenuItem>
            </Select>
          </Grid>
          <Grid
            mt={{ md: 0, sm: 1, xs: 2 }}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { md: "row", sm: "row", xs: "column" },
            }}
          >
            <Grid>
              <Typography
                sx={{ color: themeMode === "dark" ? "white" : "black" }}
              >
                Sending Hours
              </Typography>
            </Grid>
            <Grid sx={{ display: "flex" }}>
              <Typography
                sx={{ color: themeMode === "dark" ? "white" : "black" }}
              >
                Synchronize sending hours of all days{" "}
              </Typography>
              <Grid mx={{ md: 2, sm: 2, xs: 0 }}>
                <CustomSwitch
                  defaultChecked={campaign?.sync_sending_hours_of_all_days}
                  onChange={() => handleSync(!syncOn)}
                />
              </Grid>
            </Grid>
          </Grid>
          {week_days.map((day) => (
            <Grid
              key={day}
              container
              padding={{ md: 2, sm: 1, xs: "10px 5px" }}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid item md={3} sx={{ display: "flex", alignItems: "center" }}>
                <Grid mr={2}>
                  <CustomSwitch
                    defaultChecked={sending_hours[day]?.enabled}
                    onChange={(ev) => {
                      dispatch(
                        setCampaignSendingHours({
                          ...campaignSendingHours,
                          [day]: {
                            start: campaignSendingHours?.[day]?.start,
                            end: campaignSendingHours?.[day]?.end,
                            enabled: ev.target.checked,
                          },
                        })
                      );
                    }}
                  />
                  {/* <MySwitch
                    checked={sending_hours[day]?.enabled}
                    onChange={() => handleSwitchChange(day)}
                  /> */}
                </Grid>
                <Typography
                  sx={{ color: themeMode === "dark" ? "white" : "black" }}
                >
                  {day.charAt(0).toUpperCase() + day.slice(1)}
                </Typography>
              </Grid>

              <Grid item md={6} sm={6} xs={12} mt={{ md: 0, sm: 0, xs: 1 }}>
                {/* <TimeRangeSlider
                  start={sending_hours[day].start}
                  end={sending_hours[day].end}
                  onStartChange={(newStart) => handleStartChange(day, newStart)}
                  onEndChange={(newEnd) => handleEndChange(day, newEnd)}
                  themeMode={themeMode}
                /> */}
                <SendingHoursSlider
                  dayTimeRange={sending_hours?.[day]}
                  sendingHours={sending_hours}
                  day={day}
                />
              </Grid>
              <Grid
                item
                md={3}
                sm={2}
                xs={0}
                sx={{ display: { md: "flex", sm: "flex", xs: "none" } }}
              />
            </Grid>
          ))}
          <Grid
            className="py-8"
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: { md: "flex-end", sm: "flex-end", xs: "center" },
            }}
          >
            <Button
              onClick={handleSubmit}
              className={
                themeMode === "dark"
                  ? "create-button px-8 py-2"
                  : "create-button-light px-8 py-2"
              }
              variant="contained"
            >
              {updateLoading && (
                <CircularProgress
                  sx={{ color: "white" }}
                  size={20}
                  className="mr-4"
                />
              )}
              <span className="create-button-text">Save</span>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default TimeZone;
