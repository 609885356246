import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  AppBar,
  Badge,
  Toolbar,
  Typography,
  Grid,
  Box,
  Button,
  IconButton,
  Drawer,
  Menu,
  MenuItem,
  Paper,
  Divider,
  Avatar,
} from "@mui/material";
import { ToolbarList } from "../toolbar/toolbarList";
import NotificationMenu from "./NotificationMenu";
import { useDispatch, useSelector } from "react-redux";
import { setThemeMode } from "../../store/themeModeSlice";
import { logoutUser } from "../../store/userProfileSlice";
import { CheckOutlined, Home } from "@mui/icons-material";
import ErrorIcon from "@mui/icons-material/Error";
import MenuIcon from "@mui/icons-material/Menu";
import MessageDialog from "../messages/messageShow/MessageDialog";
import { setSelectedTabValue } from "../../store/settingsSlice";
import { getNotifications } from "../../store/notificationSlice";
import { addRemoveToken } from "../../store/loginSlice";
const logout =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/logout.png";

const nightBlack =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/nightBlack.png";
const bellBlack =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/bellBlack.png";
const helpBlack =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/helpBlack.png";

const night =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/nightGrey.png";
const bell =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/bellGrey.png";
const helpIconGrey =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/helpGrey.png";

const nightWhite =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/nightWhite.png";
const bellWhite =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/bellWhite.png";
const helpIconWhite =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/helpWhite.png";

const logoYellow =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/logoFace.png";
const logoRed =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/logoRed.png";

const linkedInRounded =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/linkedInRounded.png";
const linkedInRoundedWhite =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/linkedInroundedWhite.png";

const NavbarApp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const notifications = useSelector(
    (state) => state.notification.notifications
  );
  const storedTheme = localStorage.getItem("theme");
  const [isTheme, setIsTheme] = useState(storedTheme);
  const linkedin = useSelector(
    (state) => state.settings.linkedInConnectedStatus
  );

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [openMessageShow, setOpenMessageShow] = useState(false);
  const [messageId, setMessageId] = useState(null);

  const handleOpenMessage = (message) => {
    setMessageId(message.notificaitonable_uuid);
    setOpenMessageShow(true);
  };

  const handleCloseMessage = () => {
    setMessageId(null);
    setOpenMessageShow(false);
  };

  const [hovered, setHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [optionHovered, setOptionHovered] = useState(null);

  const user = useSelector((state) => state.userProfile.userProfile);
  const fcmToken = useSelector((state) => state.login.fcmToken);
  const fcmTokens = useSelector((state) => state.login.fcmTokens);

  const themeMode = useSelector((state) => state.themeMode.mode);

  const toggleTheme = () => {
    const newTheme = isTheme === "dark" ? "light" : "dark";
    setIsTheme(newTheme);
    dispatch(setThemeMode(newTheme));
    localStorage.setItem("theme", newTheme);
    return newTheme;
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const open = Boolean(anchorEl);

  const [notifBoxVisible, setNotifBoxVisible] = useState(false);

  const openNotif = (event) => {
    setNotifBoxVisible(event.currentTarget);
  };

  const closeNotif = () => {
    setNotifBoxVisible(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHelpCenter = () => {
    if (location.pathname.startsWith("/dashboard/help-center")) {
      const lastVisitedPage = localStorage.getItem("lastVisitedPage");
      if (lastVisitedPage) {
        navigate(lastVisitedPage);
      }
    } else {
      localStorage.setItem("lastVisitedPage", location.pathname);
      navigate("/dashboard/help-center");
    }
  };

  const handleNavigateHome = () => {
    navigate("/");
    setAnchorEl(null);
  };

  const handleLogout = () => {
    const updatedFCMTokensArray = fcmTokens.filter((item) => item !== fcmToken);
    dispatch(addRemoveToken(updatedFCMTokensArray));
    dispatch(logoutUser());
    setAnchorEl(null);
    navigate("/");
  };

  const path = location.pathname;

  const nameLength = user?.last_name?.length + user?.first_name?.length;

  const countUnread = notifications.filter(
    (notification) => notification.read === false
  ).length;

  return (
    <>
      <Box>
        <AppBar
          position="fixed"
          className="appBar"
          sx={{
            paddingLeft: { md: "20px", sm: "10px", xs: "0px" },
            paddingRight: { md: "60px", sm: "10px", xs: "0px" },
          }}
        >
          <Toolbar
            className="spaceBtwn"
            sx={{
              paddingY: "1px",
              paddingRight: { md: "0px", sm: "0px", xs: "0px" },
              paddingLeft: { md: "0px", sm: "30px", xs: "15px" },
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{
                "&:focus": {
                  outline: "none",
                },
                display: {
                  md: "none",
                  sm: "block",
                },
              }}
            >
              <Typography
                sx={{
                  color: themeMode === "dark" ? "#FFF" : "#030303",
                  fontSize: "25px",
                }}
              >
                <MenuIcon sx={{ height: "45px", width: "45px" }} />
              </Typography>
            </IconButton>

            <Grid
              sx={{
                display: {
                  md: "block",
                  sm: "none",
                  xs: "none",
                },
              }}
            >
              <Button
                disableRipple
                onClick={handleNavigateHome}
                sx={{
                  background: themeMode === "dark" ? "#150101" : "#ECECEC",
                  padding: "13px",
                  "&:hover": {
                    background: themeMode === "dark" ? "#150101" : "#ECECEC",
                  },
                }}
              >
                <img src={themeMode === "dark" ? logoYellow : logoRed} alt="" />
              </Button>
            </Grid>

            <Toolbar
              className="toolBar"
              sx={{
                display: { md: "none", sm: "none", xs: "none" },
                width: { md: "fit-content", sm: "50%", xs: "40%" },
                overflowX: { md: "hidden", sm: "auto" },
              }}
            >
              <Drawer
                anchor="right"
                open={isDrawerOpen}
                onClose={toggleDrawer(false)}
                sx={{ background: "transparent" }}
              >
                <Grid
                  role="presentation"
                  onClick={toggleDrawer(false)}
                  onKeyDown={toggleDrawer(false)}
                  className={
                    themeMode === "dark" ? "presentation" : "presentation-light"
                  }
                >
                  <Grid
                    className="w100p20"
                    sx={{
                      display: {
                        md: "none",
                        sm: "block",
                        xs: "block",
                      },
                    }}
                  >
                    <Button disableRipple href="/dashboard">
                      <img
                        src={themeMode === "dark" ? logoYellow : logoRed}
                        alt=""
                      />
                    </Button>
                  </Grid>
                  {ToolbarList.map((item, index) => (
                    <Button
                      disableRipple
                      key={item.name}
                      className="btn"
                      onClick={() => navigate(item.link)}
                    >
                      <Grid
                        item
                        md={12}
                        onMouseEnter={() => setHovered(index)}
                        onMouseLeave={() => setHovered(null)}
                        className="ball"
                        sx={{
                          boxShadow:
                            themeMode === "dark" &&
                            location.pathname.startsWith(item.link)
                              ? "0px 0px 6px 3px #F9E74D"
                              : themeMode === "dark" &&
                                !location.pathname.startsWith(item.link)
                              ? "0px 4px 4px 0px #00000040"
                              : themeMode === "light" &&
                                location.pathname.startsWith(item.link)
                              ? "0px 0px 6px 3px #BE0000"
                              : "0px 4px 4px 0px #00000040",
                          backgroundColor:
                            themeMode === "dark" &&
                            location.pathname.startsWith(item.link)
                              ? "#F9E74D"
                              : themeMode === "light" &&
                                location.pathname.startsWith(item.link)
                              ? "#BE0000"
                              : themeMode === "dark"
                              ? "#52000099"
                              : themeMode === "light"
                              ? "#D4D4D4"
                              : "",
                          "&:hover": {
                            border:
                              themeMode === "dark" ? "0.5px solid #f7e334" : "",
                            background:
                              themeMode === "dark" ? "#f9e74d" : "#BE0000",
                          },
                        }}
                      >
                        <Grid item md={12} mr={1} className="p15">
                          <img
                            src={
                              themeMode === "dark"
                                ? hovered === index ||
                                  location.pathname.startsWith(item.link)
                                  ? item.imgD
                                  : item.img
                                : themeMode === "light"
                                ? hovered === index ||
                                  location.pathname.startsWith(item.link)
                                  ? item.imgW
                                  : item.imgD
                                : ""
                            }
                            alt={item.name}
                            className="w300"
                          />
                        </Grid>
                      </Grid>
                      <Grid item md={12} className="alignCenter">
                        <Typography
                          className="name"
                          sx={{
                            color:
                              themeMode === "dark"
                                ? hovered === index ||
                                  location.pathname.startsWith(item.link)
                                  ? "#FFF"
                                  : "#FFFFFF80"
                                : themeMode === "light"
                                ? hovered === index ||
                                  location.pathname.startsWith(item.link)
                                  ? "#BE0100"
                                  : "#4D4D4D"
                                : "",
                          }}
                        >
                          {item.name}
                        </Typography>
                      </Grid>
                    </Button>
                  ))}
                </Grid>
              </Drawer>
            </Toolbar>

            <Grid className="flex">
              <Grid
                className="alignCenter"
                sx={{ marginX: { md: "50px", sm: "50px", xs: "0px" } }}
              >
                {/* {!linkedin?.connected ? (
                  <Button onClick={() => navigate("/dashboard/settings")} sx={{position:"relative",padding:"0px",minWidth:"0px"}}>
                    <PriorityHighIcon sx={{ color: "red", position: "absolute", top: "0px", right: "0px", width: "30px", height: "30px" }} />
                  <span className="flex items-center mr-3 mt-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                    >
                      <path
                        d="M31.6667 5C32.5507 5 33.3986 5.35119 34.0237 5.97631C34.6488 6.60143 35 7.44928 35 8.33333V31.6667C35 32.5507 34.6488 33.3986 34.0237 34.0237C33.3986 34.6488 32.5507 35 31.6667 35H8.33333C7.44928 35 6.60143 34.6488 5.97631 34.0237C5.35119 33.3986 5 32.5507 5 31.6667V8.33333C5 7.44928 5.35119 6.60143 5.97631 5.97631C6.60143 5.35119 7.44928 5 8.33333 5H31.6667ZM30.8333 30.8333V22C30.8333 20.559 30.2609 19.177 29.2419 18.1581C28.223 17.1391 26.841 16.5667 25.4 16.5667C23.9833 16.5667 22.3333 17.4333 21.5333 18.7333V16.8833H16.8833V30.8333H21.5333V22.6167C21.5333 21.3333 22.5667 20.2833 23.85 20.2833C24.4688 20.2833 25.0623 20.5292 25.4999 20.9668C25.9375 21.4043 26.1833 21.9978 26.1833 22.6167V30.8333H30.8333ZM11.4667 14.2667C12.2093 14.2667 12.9215 13.9717 13.4466 13.4466C13.9717 12.9215 14.2667 12.2093 14.2667 11.4667C14.2667 9.91667 13.0167 8.65 11.4667 8.65C10.7196 8.65 10.0032 8.94675 9.47498 9.47498C8.94675 10.0032 8.65 10.7196 8.65 11.4667C8.65 13.0167 9.91667 14.2667 11.4667 14.2667ZM13.7833 30.8333V16.8833H9.16667V30.8333H13.7833Z"
                        fill={themeMode === "dark" ? "white" : "black"}
                      />
                      
                    </svg>
                    <Button
                      onClick={() => navigate("/dashboard/settings")}
                      className="flex space-x-1 px-4"
                      style={{
                        border: "1px solid #E00000",
                        background: "#730101",
                      }}
                    >
                      <span
                        style={{
                          border: "1px solid #E41010",
                          borderRadius: "5px",
                        }}
                        className="p-1"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="11"
                          height="12"
                          viewBox="0 0 11 12"
                          fill="none"
                        >
                          <path
                            d="M1 1L10 11M10 1L1 11"
                            stroke="#E41010"
                            stroke-width="2"
                          />
                        </svg>
                      </span>

                      <span
                        className="linked-dis"
                        style={{
                          color: themeMode === "dark" ? "#E00000" : "white",
                        }}
                      >
                        DISCONNECTED
                        <PriorityHighIcon sx={{color:"red"}}/>
                      </span>
                    </Button>
                  </span>
                  </Button>
                ) : (
                  <span className="flex items-center mr-4 mt-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                    >
                      <path
                        d="M31.6667 5C32.5507 5 33.3986 5.35119 34.0237 5.97631C34.6488 6.60143 35 7.44928 35 8.33333V31.6667C35 32.5507 34.6488 33.3986 34.0237 34.0237C33.3986 34.6488 32.5507 35 31.6667 35H8.33333C7.44928 35 6.60143 34.6488 5.97631 34.0237C5.35119 33.3986 5 32.5507 5 31.6667V8.33333C5 7.44928 5.35119 6.60143 5.97631 5.97631C6.60143 5.35119 7.44928 5 8.33333 5H31.6667ZM30.8333 30.8333V22C30.8333 20.559 30.2609 19.177 29.2419 18.1581C28.223 17.1391 26.841 16.5667 25.4 16.5667C23.9833 16.5667 22.3333 17.4333 21.5333 18.7333V16.8833H16.8833V30.8333H21.5333V22.6167C21.5333 21.3333 22.5667 20.2833 23.85 20.2833C24.4688 20.2833 25.0623 20.5292 25.4999 20.9668C25.9375 21.4043 26.1833 21.9978 26.1833 22.6167V30.8333H30.8333ZM11.4667 14.2667C12.2093 14.2667 12.9215 13.9717 13.4466 13.4466C13.9717 12.9215 14.2667 12.2093 14.2667 11.4667C14.2667 9.91667 13.0167 8.65 11.4667 8.65C10.7196 8.65 10.0032 8.94675 9.47498 9.47498C8.94675 10.0032 8.65 10.7196 8.65 11.4667C8.65 13.0167 9.91667 14.2667 11.4667 14.2667ZM13.7833 30.8333V16.8833H9.16667V30.8333H13.7833Z"
                        fill={themeMode === "dark" ? "white" : "black"}
                      />
                    </svg>
                    <Button
                      onClick={() => navigate("/dashboard/settings")}
                      className="flex space-x-1 px-4"
                      style={{
                        border:
                          themeMode === "dark"
                            ? "1px solid #00FF29"
                            : "1px solid black",
                        background:
                          themeMode === "dark" ? "#730101" : "#70FF00",
                      }}
                    >
                      <span
                        style={{
                          border:
                            themeMode === "dark"
                              ? "1px solid #00FF29"
                              : "1px solid black",
                          borderRadius: "5px",
                        }}
                        className="px-1"
                      >
                        <CheckOutlined
                          className="p-0 m-0"
                          style={{
                            fontSize: "18px",
                            color: themeMode === "dark" ? "#00FF29" : "black",
                          }}
                        />
                      </span>

                      <span
                        className="linked-dis"
                        style={{
                          color: themeMode === "dark" ? "#00FF29" : "black",
                        }}
                      >
                        CONNECTED
                      </span>
                    </Button>
                  </span>
                )} */}

                <Button
                  onMouseEnter={() => setOptionHovered(4)}
                  onMouseLeave={() => setOptionHovered(null)}
                  className="ball2"
                  onClick={() => {
                    dispatch(setSelectedTabValue(2));
                    navigate("/dashboard/settings");
                  }}
                  sx={{
                    padding: "0px",
                    marginX: "5px",
                    backgroundColor: linkedin.connected ? "#58f55c" : "#fa3737",
                    width: { md: "51px", sm: "51px", xs: "35px" },
                    height: { md: "51px", sm: "51px", xs: "35px" },
                    position: "relative",
                  }}
                >
                  <Grid
                    sx={{
                      position: "absolute",
                      top: { md: "-5px", sm: "-5px", xs: "-10px" },
                      right: { md: "-5px", sm: "-5px", xs: "-10px" },
                    }}
                  >
                    {!linkedin?.connected ? (
                      <div
                        style={{
                          background: "#FFF",
                          borderRadius: "50%",
                          transform: "scale(0.8)",
                        }}
                      >
                        <ErrorIcon
                          sx={{ color: "red", transform: "scale(1.2)" }}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          background: "#3cfa41",
                          borderRadius: "50%",
                          width: "15px",
                          height: "15px",
                          position: "relative",
                          top: "5px",
                          right: "5px",
                        }}
                      />
                    )}
                  </Grid>

                  <img
                    src={linkedInRoundedWhite}
                    alt=""
                    style={{ width: "100%" }}
                  />
                  <Grid
                    sx={{
                      display: optionHovered === 4 ? "flex" : "none",
                      position: "absolute",
                      background: !linkedin?.connected ? "#773636" : "#3b7736",
                      top: { md: "50px", sm: "50px", xs: "35px" },
                      padding: "5px 8px",
                      border: "1px solid #FFF",
                      borderRadius: "30px",
                    }}
                  >
                    <Typography
                      sx={{
                        textTransform: "none",
                        color: "#FFF",
                        textWrap: "nowrap",
                        fontSize: "12px",
                      }}
                    >
                      {!linkedin?.connected
                        ? "Account: Disconnected"
                        : "Account: Connected"}
                    </Typography>
                  </Grid>
                </Button>

                <Button
                  onMouseEnter={() => setOptionHovered(1)}
                  onMouseLeave={() => setOptionHovered(null)}
                  className="ball2"
                  onClick={toggleTheme}
                  sx={{
                    marginX: "5px",
                    backgroundColor:
                      themeMode === "dark" ? "#52000099" : "#FFF",
                    width: { md: "51px", sm: "51px", xs: "35px" },
                    height: { md: "51px", sm: "51px", xs: "35px" },
                    "&:hover": {
                      background:
                        themeMode === "dark" ? "#ff000099" : "#BE0000",
                    },
                  }}
                >
                  <img
                    src={
                      optionHovered === 1
                        ? nightWhite
                        : themeMode === "dark"
                        ? night
                        : nightBlack
                    }
                    alt=""
                  />
                  <Grid
                    sx={{
                      display: optionHovered === 1 ? "flex" : "none",
                      position: "absolute",
                      background: "#773636",
                      top: { md: "50px", sm: "50px", xs: "35px" },
                      padding: "5px 8px",
                      border: "1px solid #FFF",
                      borderRadius: "30px",
                    }}
                  >
                    <Typography
                      sx={{
                        textTransform: "none",
                        color: "#FFF",
                        textWrap: "nowrap",
                        fontSize: "12px",
                      }}
                    >
                      Dark Mode
                    </Typography>
                  </Grid>
                </Button>

                <Button
                  onMouseEnter={() => setOptionHovered(2)}
                  onMouseLeave={() => setOptionHovered(null)}
                  onClick={handleHelpCenter}
                  className="ball2"
                  sx={{
                    marginX: "5px",
                    backgroundColor: location.pathname.startsWith(
                      "/dashboard/help-center"
                    )
                      ? "#BE0000"
                      : themeMode === "dark"
                      ? "#52000099"
                      : "#FFF",
                    width: { md: "51px", sm: "51px", xs: "35px" },
                    height: { md: "51px", sm: "51px", xs: "35px" },
                    "&:hover": {
                      background:
                        themeMode === "dark" ? "#ff000099" : "#BE0000",
                    },
                  }}
                >
                  <img
                    src={
                      location.pathname.startsWith("/dashboard/help-center") ||
                      optionHovered === 2
                        ? helpIconWhite
                        : themeMode === "dark"
                        ? helpIconGrey
                        : helpBlack
                    }
                    alt=""
                    style={{ transform: "scale(1.1)" }}
                  />
                  <Grid
                    sx={{
                      display: optionHovered === 2 ? "flex" : "none",
                      position: "absolute",
                      background: "#773636",
                      top: { md: "50px", sm: "50px", xs: "35px" },
                      padding: "5px 8px",
                      border: "1px solid #FFF",
                      borderRadius: "30px",
                    }}
                  >
                    <Typography
                      sx={{
                        textTransform: "none",
                        color: "#FFF",
                        textWrap: "nowrap",
                        fontSize: "12px",
                      }}
                    >
                      Help Center
                    </Typography>
                  </Grid>
                </Button>

                <Button
                  onMouseEnter={() => setOptionHovered(3)}
                  onMouseLeave={() => setOptionHovered(null)}
                  onClick={openNotif}
                  className="ball2"
                  sx={{
                    backgroundColor:
                      notifBoxVisible && themeMode === "dark"
                        ? "#FF000099"
                        : !notifBoxVisible && themeMode === "dark"
                        ? "#52000099"
                        : notifBoxVisible && themeMode === "light"
                        ? "#BE0000"
                        : "#FFF",

                    marginX: "5px",
                    width: { md: "51px", sm: "51px", xs: "35px" },
                    height: { md: "51px", sm: "51px", xs: "35px" },
                    "&:hover": {
                      background:
                        themeMode === "dark" ? "#ff000099" : "#BE0000",
                    },
                  }}
                >
                  <Grid
                    sx={{
                      position: "absolute",
                      top: { md: "-5px", sm: "-5px", xs: "-10px" },
                      right: { md: "-5px", sm: "-5px", xs: "-10px" },
                    }}
                  >
                    {countUnread ? (
                      <div
                        style={{
                          background: "#FFF",
                          borderRadius: "50%",
                        }}
                      >
                        <Grid
                          sx={{
                            background: "red",
                            color: "#FFF",
                            padding: "5px",
                            borderRadius: "50%",
                            height: "24px",
                            width: "24px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {countUnread}
                        </Grid>
                      </div>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <img
                    src={
                      optionHovered === 3 || notifBoxVisible
                        ? bellWhite
                        : themeMode === "dark"
                        ? bell
                        : bellBlack
                    }
                    alt=""
                  />
                  <Grid
                    sx={{
                      display: optionHovered === 3 ? "flex" : "none",
                      position: "absolute",
                      background: "#773636",
                      top: { md: "50px", sm: "50px", xs: "35px" },
                      padding: "5px 8px",
                      border: "1px solid #FFF",
                      borderRadius: "30px",
                    }}
                  >
                    <Typography
                      sx={{
                        textTransform: "none",
                        color: "#FFF",
                        textWrap: "nowrap",
                        fontSize: "12px",
                      }}
                    >
                      Notifications
                    </Typography>
                  </Grid>
                </Button>
              </Grid>

              <NotificationMenu
                notifBoxVisible={notifBoxVisible}
                closeNotif={closeNotif}
                handleOpenMessage={handleOpenMessage}
              />

              <Button
                disableRipple
                className="profile"
                onClick={handleClick}
                sx={{
                  backgroundColor: {
                    md: themeMode === "dark" ? "#3434344D" : "#FFFFFF",
                    sm: themeMode === "dark" ? "#3434344D" : "#FFFFFF",
                    xs: "transparent",
                  },
                  padding: {
                    md: "0px 15px 0px 0px",
                    sm: "0px 15px 0px 0px",
                    xs: "0px",
                  },
                  fontSize: { md: "16px", sm: "16px", xs: "10px" },
                  marginX: "5px",
                  color: themeMode === "dark" ? "#FFF" : "#000",
                  "&:hover": {
                    backgroundColor: {
                      md: themeMode === "dark" ? "#3434344D" : "#FFFFFF",
                      sm: themeMode === "dark" ? "#3434344D" : "#FFFFFF",
                      xs: "transparent",
                    },
                  },
                }}
              >
                <Typography
                  sx={{
                    display: { md: "flex", sm: "flex", xs: "none" },
                    alignItems: "center",
                  }}
                >
                  {/* <img src={user?.image_url} alt="" className="userDp" /> */}
                  <Avatar
                    alt="Remy Sharp"
                    src={user?.image_url || user?.linkedin_profile_picture_url}
                    sx={{
                      width: 100,
                      height: 100,
                      marginRight: { md: "20px", sm: "20px", xs: "0px" },
                    }}
                    className="userDp"
                  />{" "}
                  {user?.first_name > 8
                    ? `${user?.first_name?.substring(0, 8)}...`
                    : user?.first_name}
                  <span
                    className="nameBold"
                    style={{ display: { md: "flex", sm: "none", xs: "none" } }}
                  >
                    {" "}
                    {nameLength > 8
                      ? `${user?.last_name?.substring(0, 2)}...`
                      : user?.last_name}
                  </span>
                </Typography>
                <Typography
                  sx={{ display: { md: "none", sm: "none", xs: "flex" } }}
                >
                  {/* <img src={user?.image_url} alt="" className="userDp" /> */}
                  <Avatar
                    alt="Remy Sharp"
                    src={user?.image_url}
                    sx={{
                      width: 100,
                      height: 100,
                      marginRight: { md: "20px", sm: "20px", xs: "0px" },
                    }}
                    className="userDp"
                  />{" "}
                </Typography>
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onMouseLeave={handleClose}
                PaperProps={{
                  sx: {
                    backgroundColor: "#FF0000AA",
                    color: "white",
                    borderRadius: "30px",
                    padding: "5px 15px 5px 15px",
                    width: "195px",
                  },
                }}
              >
                <MenuItem
                  onClick={handleNavigateHome}
                  sx={{
                    borderRadius: "15px",
                    "&:hover": {
                      background:
                        themeMode === "dark" ? "#52000099" : "#EC9080",
                    },
                  }}
                >
                  <Typography sx={{ display: "flex", alignItems: "center" }}>
                    <Home
                      style={{
                        marginRight: "8px",
                        padding: "0px",
                      }}
                    />
                    Go to Home
                  </Typography>
                </MenuItem>
                <Divider sx={{ backgroundColor: "#fff" }} />
                <MenuItem
                  onClick={handleLogout}
                  sx={{
                    borderRadius: "15px",
                    "&:hover": {
                      background:
                        themeMode === "dark" ? "#52000099" : "#EC9080",
                    },
                  }}
                >
                  <Typography sx={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={logout}
                      alt="Logout"
                      style={{ marginRight: "10px", marginLeft: "4px" }}
                    />
                    Logout
                  </Typography>
                </MenuItem>
              </Menu>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
      <MessageDialog
        openMessageShow={openMessageShow}
        closeMessageShow={handleCloseMessage}
        messageId={messageId}
      />
    </>
  );
};
export default NavbarApp;
