import {
    Box,
    Button,
    TextField,
    Typography,
    Grid
} from "@mui/material";
import { useSelector } from "react-redux";

const AdjustFilters = ({ setSelectedTab }) => {
    const themeMode = useSelector((state) => state.themeMode.mode);

    return (
        <>
            <Box
                sx={{
                    height: '100vh',
                    padding: '20px',
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        maxWidth: 800,
                        padding: '20px',
                        color: '#fff',
                        marginTop: '20px',
                    }}
                >
                    <Typography variant="h5" gutterBottom sx={{ color: themeMode === "dark" ? 'white': 'black'}}>
                        Retrieve LinkedIn Sales Navigator Filters
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom sx={{ color: themeMode === "dark" ? 'white': 'black', marginBottom: 2 }}>
                        Go to this URL and adjust filters given below in LinkedIn Sales Navigation
                    </Typography>
                    <TextField
                        fullWidth
                        placeholder="www.linkedin.com/url"
                        variant="outlined"
                        margin="normal"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                            '& fieldset': { borderColor: themeMode === "dark" ? 'white': 'black' }, // Border color white
                            color: themeMode === "dark" ? 'white': 'black',
                            },
                        }}
                    />
                    <Typography variant="h6" gutterBottom sx={{ color: themeMode === "dark" ? 'white': 'black', marginTop: 4 }}>
                        Filters
                    </Typography>
                    <Grid container spacing={2} sx={{ color: themeMode === "dark" ? 'white': 'black', marginBottom: 4 }}>
                        <Grid item xs={6}>
                            <Typography variant="body1">Geography: California</Typography>
                            <Typography variant="body1">Industry: XYZ</Typography>
                            <Typography variant="body1">Company: XYZ</Typography>
                            <Typography variant="body1">Job Title and Function: XYZ</Typography>
                            <Typography variant="body1">Seniority Level: XYZ</Typography>
                            <Typography variant="body1">Years of Experience: XYZ</Typography>
                            <Typography variant="body1">Groups: California</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1">Content: California</Typography>
                            <Typography variant="body1">Company Relation: California</Typography>
                            <Typography variant="body1">Education: California</Typography>
                            <Typography variant="body1">Job Changes: California</Typography>
                            <Typography variant="body1">Keywords: California</Typography>
                            <Typography variant="body1">Connections: California</Typography>
                        </Grid>
                    </Grid>
                    <Typography variant="subtitle1" gutterBottom sx={{ color: themeMode === "dark" ? 'white': 'black', marginBottom: 2 }}>
                        After filter settings, paste a valid LinkedIn Sales Navigator search list URL here*
                    </Typography>
                    <TextField
                        fullWidth
                        placeholder="LinkedIn Sales Navigator search list URL"
                        variant="outlined"
                        margin="normal"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                            '& fieldset': { borderColor: themeMode === "dark" ? 'white': 'black' }, 
                            color: themeMode === "dark" ? 'white': 'black',
                            },
                        }}
                    />
                    <Box sx={{ marginTop: 3 }}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#b11b1b', 
                                color: '#fff',
                                width: '77px',
                                Height: '41px',
                                borderRadius: '30px',
                                padding: '10px 40px',
                                '&:hover': {
                                    backgroundColor: '#9b1717',
                                },
                            }}
                            onClick={() => setSelectedTab(9)}
                        >
                            Next
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    );
};
export default AdjustFilters;