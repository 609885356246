import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

const faqs = [
  {
    ques: "What is FacoonLabs?",
    ans: "FacoonLabs is a platform designed to automate messaging and outreach on LinkedIn, helping businesses generate prospects and expand their network.",
  },
  {
    ques: "How does FacoonLabs work?",
    ans: "FacoonLabs is a platform designed to automate messaging and outreach on LinkedIn, helping businesses generate prospects and expand their network.",
  },
  {
    ques: "Is FacoonLabs compliant with LinkedIn’s policies?",
    ans: "FacoonLabs is a platform designed to automate messaging and outreach on LinkedIn, helping businesses generate prospects and expand their network.",
  },
  {
    ques: "Can I customize the messages sent through FacoonLabs?",
    ans: "FacoonLabs is a platform designed to automate messaging and outreach on LinkedIn, helping businesses generate prospects and expand their network.",
  },
  {
    ques: "What types of businesses benefit from using FacoonLabs?",
    ans: "FacoonLabs is a platform designed to automate messaging and outreach on LinkedIn, helping businesses generate prospects and expand their network.",
  },
  {
    ques: "Is there a limit to the number of messages I can send?",
    ans: "FacoonLabs is a platform designed to automate messaging and outreach on LinkedIn, helping businesses generate prospects and expand their network.",
  },
  {
    ques: "How does FacoonLabs ensure message accuracy?",
    ans: "FacoonLabs is a platform designed to automate messaging and outreach on LinkedIn, helping businesses generate prospects and expand their network.",
  },
  {
    ques: "Can I track the performance of my outreach campaigns?",
    ans: "FacoonLabs is a platform designed to automate messaging and outreach on LinkedIn, helping businesses generate prospects and expand their network.",
  },
  {
    ques: "Is there a free trial available for FacoonLabs?",
    ans: "FacoonLabs is a platform designed to automate messaging and outreach on LinkedIn, helping businesses generate prospects and expand their network.",
  },
  {
    ques: "Is there a free trial available for FacoonLabs?",
    ans: "FacoonLabs is a platform designed to automate messaging and outreach on LinkedIn, helping businesses generate prospects and expand their network.",
  },
];

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: "transparent",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="10"
        viewBox="0 0 17 10"
        fill="none"
      >
        <path
          d="M16.5762 1.48288C16.5764 1.6774 16.5381 1.87004 16.4637 2.04974C16.3892 2.22944 16.2799 2.39265 16.1422 2.53001L9.3351 9.3371C9.19767 9.47477 9.03445 9.58398 8.85477 9.65849C8.67509 9.73301 8.48248 9.77136 8.28797 9.77136C8.09345 9.77136 7.90084 9.73301 7.72116 9.65849C7.54148 9.58398 7.37826 9.47477 7.24083 9.3371L0.433736 2.53001C0.296224 2.3925 0.187145 2.22925 0.112724 2.04958C0.0383038 1.86991 9.79691e-08 1.67735 9.88392e-08 1.48287C9.97093e-08 1.2884 0.0383038 1.09584 0.112724 0.916171C0.187145 0.736504 0.296224 0.573256 0.433736 0.435744C0.571247 0.298233 0.734498 0.189151 0.914165 0.114731C1.09383 0.0403099 1.2864 0.00200655 1.48087 0.00200655C1.67534 0.00200655 1.86791 0.0403099 2.04757 0.114731C2.22724 0.189151 2.39049 0.298233 2.528 0.435744L8.28797 6.19571L14.0479 0.435744C14.2548 0.227947 14.5187 0.0862905 14.8062 0.0287582C15.0937 -0.0287741 15.3918 0.000410264 15.6627 0.112606C15.9336 0.224802 16.1651 0.414952 16.3277 0.65892C16.4903 0.902887 16.5768 1.18967 16.5762 1.48288Z"
          fill="white"
        />
      </svg>
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function HelpAccordian() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      {faqs.map((faq, index) => (
        <Accordion
          expanded={expanded === `panel${index + 1}`}
          onChange={handleChange(`panel${index + 1}`)}
          key={index}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <div className="faq-ques">{faq.ques}</div>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: "0px" }} className="pb-4">
            <div className="faq-ans pl-10">{faq.ans}</div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
