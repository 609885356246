import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { error } from "./alertSlice";
const { REACT_APP_API_URL } = process.env;

const initialState = {
  value: 0,
  onboardings: [],
  chosenPaymentPlan: {},
  showCaptcha: false,
  codeCheckpoint: {},
  codeLoading: false,
  welcome: {},
  onboardingUser: {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    organization_website: "",
    reason_to_join: "",
    terms_and_conditions: false,
    current_position: "",
  },
};

export const welcomeUser = createAsyncThunk(
  "onboarding/welcomeUser",
  async (user_email, { dispatch, getState }) => {
    try {
      const data = await axios.get(`${REACT_APP_API_URL}/onboardings/welcome`, {
        params: {
          email: encodeURIComponent(user_email)
            .replace(/%20/g, "+")
            .replace(/%40/g, "@"),
        },
      });

      return { data: data.data, header: data.headers, status: data.status };
    } catch (err) {
      console.error(err);
      dispatch(error(err.message));

      return null;
    }
  }
);

export const getConfirmDetails = createAsyncThunk(
  "onboarding/getConfirmDetails",
  async (user_email, { dispatch, getState }) => {
    try {
      const res = await axios.get(
        `${REACT_APP_API_URL}/onboardings/confirm_details`
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      console.error(err);
      dispatch(error(err.message));

      return null;
    }
  }
);

export const updateOnboardingDetails = createAsyncThunk(
  "onboarding/updateOnboardingDetails",
  async (user, { dispatch, getState }) => {
    try {
      const res = await axios.put(
        `${REACT_APP_API_URL}/onboardings/update_details`,
        { user }
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      console.error(err);
      dispatch(error(err.message));

      return null;
    }
  }
);

export const setPassword = createAsyncThunk(
  "onboarding/setPassword",
  async (user, { dispatch, getState }) => {
    try {
      const res = await axios.put(
        `${REACT_APP_API_URL}/onboardings/set_password`,
        { user }
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      console.error(err);
      dispatch(error(err.message));

      return null;
    }
  }
);

export const getChosePaymentPlan = createAsyncThunk(
  "onboarding/getChosePaymentPlan",
  async (user_email, { dispatch, getState }) => {
    try {
      const res = await axios.get(
        `${REACT_APP_API_URL}/onboardings/chosen_payment_plan`
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      console.error(err);
      dispatch(error(err.message));

      return null;
    }
  }
);

export const processPayment = createAsyncThunk(
  "onboarding/processPayment",
  async (payment, { dispatch, getState }) => {
    try {
      const res = await axios.post(
        `${REACT_APP_API_URL}/onboardings/subscribe_to_payment_plan`,
        { payment }
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      console.error(err);
      dispatch(error(err.message));

      return null;
    }
  }
);

export const accountConnection = createAsyncThunk(
  "onboarding/accountConnection",
  async (payment, { dispatch, getState }) => {
    try {
      const res = await axios.put(
        `${REACT_APP_API_URL}/onboardings/linkedin_connection_step_confirmation`
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      console.error(err);
      dispatch(error(err.message));

      return null;
    }
  }
);

export const finishOnboarding = createAsyncThunk(
  "onboarding/finishOnboarding",
  async (payment, { dispatch, getState }) => {
    try {
      const res = await axios.put(
        `${REACT_APP_API_URL}/onboardings/finish_onboarding`
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      console.error(err);
      dispatch(error(err.message));

      return null;
    }
  }
);

export const solveCodeCheckpoint = createAsyncThunk(
  "onboarding/solveCodeCheckpoint",
  async (code_checkpoint, { dispatch, getState }) => {
    try {
      const res = await axios.put(
        `${REACT_APP_API_URL}/linkedin_connected_accounts/code_checkpoint`,
        { code_checkpoint }
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.response.data));
      console.error(err);
      return null;
    }
  }
);

export const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    setOnboardingShowCaptcha: (state, action) => {
      state.showCaptcha = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(welcomeUser.fulfilled, (state, action) => {
      state.welcome = action.payload?.data;
      state.onboardings = action.payload;
      if (action.payload) {
        const { data, header } = action.payload;
        const { email } = data;
        const accessToken = header["access-token"];
        const client = header.client;

        state.login = data;

        localStorage.setItem("client", client);
        localStorage.setItem("uid", email);
        localStorage.setItem("access-token", accessToken);

        const userData = {
          "access-token": accessToken,
          client,
          uid: email,
        };
        localStorage.setItem("user", JSON.stringify(userData));

        axios.defaults.headers["client"] = action.payload.header.client;
        axios.defaults.headers["uid"] = action.payload.data.email;
        axios.defaults.headers["access-token"] =
          action.payload.header["access-token"];
      }
    });
    builder.addCase(getConfirmDetails.fulfilled, (state, action) => {
      state.onboardingUser = action.payload?.data;
    });
    builder.addCase(getChosePaymentPlan.fulfilled, (state, action) => {
      state.chosenPaymentPlan = action.payload?.data;
    });
    builder.addCase(solveCodeCheckpoint.pending, (state, action) => {
      state.codeLoading = true;
    });
    builder.addCase(solveCodeCheckpoint.fulfilled, (state, action) => {
      state.codeLoading = false;
      state.codeCheckpoint = action.payload;
      state.showCaptcha = false;
    });
    builder.addCase(solveCodeCheckpoint.rejected, (state, action) => {
      state.codeLoading = false;
      state.showCaptcha = false;
    });
  },
});

export const { setOnboardingShowCaptcha } = onboardingSlice.actions;

export default onboardingSlice.reducer;
