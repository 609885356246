import { useState } from "react";
import { Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import {  useSelector } from "react-redux";

const timeZone = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/timeZone.png";
const timeZoneBlack = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/timezoneBlack.png";
const inMailMsg = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/inMailMsg.png";
const inMailMsgBlack = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/inMailMsgBlack.png";
const searchList = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/searchList.png";
const searchListBlack = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/searchListBlack.png";
const automatedMsg = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/automatedMsg.png";
const automatedMsgBlack = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/automatedMsgBlack.png";
const priority = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/highPriority.png"

const Automation = () => {
    const theme = useTheme();
    const themeMode = useSelector((state) => state.themeMode.mode);
    const [status,setStatus]=useState("connected") //or connected
    const isCustomRange = useMediaQuery('(min-width:900px) and (max-width:1336px)');

    return (
        <Grid
            sx={{
                background: themeMode === "dark" ? "#00000099" :"#DEDEDE99",
                color:themeMode==="dark"?"#FFF":"#000",
                border: themeMode === "dark" ? "1px solid #FFF" :'1px solid #999999',
                borderRadius: "30px",
                padding: "30px"
            }}
        >
            <Typography sx={{ fontSize: "20px", fontwweight: "700" }}>
                Campaign Automation
            </Typography>
            <Typography sx={{ color: status === "connected" && themeMode === "dark" ? "#70FF00" : status === "connected" && themeMode === "light" ?"#05a315":"#FF0606", fontSize: "12px", fontWeight: "400",display:"flex",alignItems:"center" }}>
                {
                status==="connected"?
                    "Automation config is all good. Prospects collection and outreach is in process. Please check the stats below."
                    :
                    (
                        <>
                            <img src={priority} alt="" style={{marginRight:"10px"}}/>
                            Automation config requires your input. Please check below and make appropriate changes.
                        </>
                    )
                }
            </Typography>
            <Grid container mt={4} px={{md:6,sm:4,xs:0}} md={12} sm={12} xs={12} sx={{display:"flex",justifyContent:{md:"space-evenly",sm:"space-between"},flexWrap:"wrap"}}>
                <Grid item md={isCustomRange ? 6 : 2.5} sm={6} xs={12} sx={{
                    display: "flex", padding: "15px", alignItems: "center",}}>
                    <img src={themeMode==="dark"?searchList:searchListBlack} alt="" style={{width:"47px",height:"51px"}}/>
                    <Grid sx={{fontSize:"14px",fontWeight:"500",paddingLeft:"5px"}}>
                        <Typography sx={{fontSize:"14px",fontWeight:"500"}}>Search<br /></Typography>
                        <Typography sx={{fontSize:"14px",fontWeight:"500"}}>List URL</Typography>
                        <Typography sx={{ color: status === "connected" && themeMode === "dark" ? "#05FF00" : status === "connected" && themeMode === "light" ? "#07b304" : "#FF0000", fontSize: "14px", fontWeight: "500", fontStyle: "italic", textDecoration: status === "connected" ? "none" : "underline", textWrap: { md: "nowrap", sm: "nowrap", xs: "wrap" } }}>
                            {status === "connected" ? "Set" :"needs to be set"}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item md={isCustomRange ? 6 : 3.5} sm={6} xs={12} sx={{ display: "flex", padding: "15px", alignItems: "center", }}>
                    <img src={themeMode==="dark"?automatedMsg:automatedMsgBlack} alt="" style={{ width: "57px", height: "45px" }} />
                    <Grid sx={{ fontSize: "14px", fontWeight: "500", paddingLeft: "5px" }}>
                        <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>Automated<br /></Typography>
                        <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>Messages</Typography>
                        <Typography sx={{ color: status === "connected" && themeMode === "dark" ? "#05FF00" : status === "connected" && themeMode === "light" ?"#07b304": "#FF0000", fontSize: "14px", fontWeight: "500", fontStyle: "italic", textDecoration: status === "connected" ? "none" : "underline",textWrap:{md:"nowrap",sm:"nowrap",xs:"wrap"} }}>
                            {status === "connected" ? "Confirmed" : "needs to be confirmed"}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item md={isCustomRange ? 6 : 3.5} sm={6} xs={12} sx={{ display: "flex", padding: "15px", alignItems: "center", }}>
                    <img src={themeMode==="dark"?inMailMsg:inMailMsgBlack} alt="" style={{ width: "44px", height: "44px" }} />
                    <Grid sx={{ fontSize: "14px", fontWeight: "500", paddingLeft: "5px" }}>
                        <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>InMail<br /></Typography>
                        <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>Messages</Typography>
                        <Typography sx={{ color: status === "connected" && themeMode === "dark" ? "#05FF00" : status === "connected" && themeMode === "light" ? "#07b304" : "#FF0000", fontSize: "14px", fontWeight: "500", fontStyle: "italic", textDecoration: status === "connected" ? "none" : "underline", textWrap: { md: "nowrap", sm: "nowrap", xs: "wrap" } }}>
                            
                            {status === "connected" ? "Confirmed" : "needs to be confirmed"}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item md={isCustomRange ? 6 : 2.5} sm={6} xs={12} sx={{ display: "flex", padding: "15px", alignItems: "center",}}>
                    <img src={themeMode==="dark"?timeZone:timeZoneBlack} alt="" style={{ width: "44px", height: "45px" }} />
                    <Grid sx={{ fontSize: "14px", fontWeight: "500", paddingLeft: "5px" }}>
                        <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>Time Zone &<br /></Typography>
                        <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>Sending Hours</Typography>
                        <Typography sx={{ color: status === "connected" && themeMode === "dark" ? "#05FF00" : status === "connected" && themeMode === "light" ? "#07b304" : "#FF0000", fontSize: "14px", fontWeight: "500", fontStyle: "italic", textDecoration: status === "connected" ? "none" : "underline", textWrap: { md: "nowrap", sm: "nowrap", xs: "wrap" } }}>
                            {status === "connected" ? "Set" : "needs to be set"}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default Automation;