/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { Grid, useMediaQuery } from "@mui/material";
import Footer from "../footer/Footer";
import { BeginYourJourney } from "../homepage/BeginYourJourney";
import { Hero } from "../homepage/Hero";
import { Partners } from "../homepage/Partners";
import Navbar from "../navbar/Navbar";
import { DistributedTeam } from "./DistributedTeam";
import { Mission } from "./mission/Mission";
import { OurTeam } from "./our-team/OurTeam";


const gradientAnimation = keyframes`
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 0%;
  }
`;

export const AboutUs = () => {
  const isCustomRange = useMediaQuery('(min-width:900px) and (max-width:1200px)');
  return (
    <div>
      {/* Desktop Display */}
      <Navbar />
      <Grid
        className="md:pt-24 sm:pt-10 xs:pt-8"
        sx={{
          display: { md: "flex", sm: "none", xs: "none" }, flexDirection: "column",
          // backgroundImage: `url(https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/about-shader.gif)`,
        }}
        css={css`
          background-color: #040000;
          background-image: linear-gradient(315deg, #040000 10%, #000d73 24%, #0938e1 42%, #45259d 61%, #ff0606 93%);
          background-size: 240% 240%;
          animation: ${gradientAnimation} 20s ease infinite;
      `}
      >
        <Hero
          pageTitle={"About Us"}
          mainHeading={"Behind the Scenes at FacoonLabs"}
          primarayButton={"Learn Our Story"}

          imageUrl={"https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/heroNew.png"}
        />
        <DistributedTeam imageUrl={isCustomRange ? "	https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/Vector2Tablet.png" : "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/Vector2.png"} />
      </Grid>




      {/* Tablet Display */}
      <Grid sx={{ display: { md: "none", sm: "flex", xs: "none" }, flexDirection: "column" }}
        // style={{
        //   backgroundImage: `url(https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/about-shader.gif)`,
        //   backgroundRepeat: "no-repeat",
        //   backgroundSize: "cover",
        // }}
        css={css`
        background-color: #040000;
          background-image: linear-gradient(315deg, #040000 10%, #000d73 24%, #0938e1 42%, #45259d 61%, #ff0606 93%);
        background-size: 240% 240%;
        animation: ${gradientAnimation} 20s ease infinite;
      `}
      >
        <Hero
          pageTitle={"About Us"}
          mainHeading={"Behind the Scenes at FacoonLabs"}
          primarayButton={"Learn Our Story"}
          //imageUrl={"https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/hero1.png"}
          imageUrl={"https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/heroNew.png"}
        />
        <DistributedTeam imageUrl={"	https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/Vector2Tablet.png"} />
      </Grid>





      {/* Mobile Display */}
      <Grid sx={{ display: { md: "none", sm: "none", xs: "flex" }, flexDirection: "column" }}
        style={{
          // backgroundImage: `url(https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/about-shader.gif)`,
          // backgroundRepeat: "no-repeat",
          // backgroundSize: "cover",
        }}
        css={css`
        background-color: #040000;
          background-image: linear-gradient(315deg, #040000 10%, #000d73 24%, #0938e1 42%, #45259d 61%, #ff0606 93%);
        background-size: 240% 240%;
        animation: ${gradientAnimation} 20s ease infinite;
      `}
      >
        <Hero
          pageTitle={"About Us"}
          mainHeading={"Behind the Scenes at FacoonLabs"}
          primarayButton={"Learn Our Story"}
          imageUrl={"https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/heroNew.png"}
        />
        <DistributedTeam imageUrl={"https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/Vector2Mbl.png"} />
      </Grid>



      <Partners />
      <Mission />
      <OurTeam />
      <BeginYourJourney />
      <Footer />
    </div>
  );
};
