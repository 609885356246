import React, { useState } from "react";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { setCampaignSendingHours } from "../../../../store/campaignSlice";

// Mapping slider values (0-48) to corresponding times over 24 hours
const timeMapping = [
  { value: 0, label: "12:00am" },
  { value: 1, label: "12:30am" },
  { value: 2, label: "1:00am" },
  { value: 3, label: "1:30am" },
  { value: 4, label: "2:00am" },
  { value: 5, label: "2:30am" },
  { value: 6, label: "3:00am" },
  { value: 7, label: "3:30am" },
  { value: 8, label: "4:00am" },
  { value: 9, label: "4:30am" },
  { value: 10, label: "5:00am" },
  { value: 11, label: "5:30am" },
  { value: 12, label: "6:00am" },
  { value: 13, label: "6:30am" },
  { value: 14, label: "7:00am" },
  { value: 15, label: "7:30am" },
  { value: 16, label: "8:00am" },
  { value: 17, label: "8:30am" },
  { value: 18, label: "9:00am" },
  { value: 19, label: "9:30am" },
  { value: 20, label: "10:00am" },
  { value: 21, label: "10:30am" },
  { value: 22, label: "11:00am" },
  { value: 23, label: "11:30am" },
  { value: 24, label: "12:00pm" },
  { value: 25, label: "12:30pm" },
  { value: 26, label: "1:00pm" },
  { value: 27, label: "1:30pm" },
  { value: 28, label: "2:00pm" },
  { value: 29, label: "2:30pm" },
  { value: 30, label: "3:00pm" },
  { value: 31, label: "3:30pm" },
  { value: 32, label: "4:00pm" },
  { value: 33, label: "4:30pm" },
  { value: 34, label: "5:00pm" },
  { value: 35, label: "5:30pm" },
  { value: 36, label: "6:00pm" },
  { value: 37, label: "6:30pm" },
  { value: 38, label: "7:00pm" },
  { value: 39, label: "7:30pm" },
  { value: 40, label: "8:00pm" },
  { value: 41, label: "8:30pm" },
  { value: 42, label: "9:00pm" },
  { value: 43, label: "9:30pm" },
  { value: 44, label: "10:00pm" },
  { value: 45, label: "10:30pm" },
  { value: 46, label: "11:00pm" },
  { value: 47, label: "11:30pm" },
  { value: 48, label: "12:00am" },
];

function SendingHoursSlider({ dayTimeRange, sendingHours, day }) {
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const campaignSendingHours = useSelector(
    (state) => state.campaign.campaignSendingHours
  );

  const getSliderValueFromTime = (timeString) => {
    const mapping = {
      am: 0,
      pm: 12,
    };

    const [time, period] = timeString.toLowerCase().split(/(am|pm)/);
    let [hours, minutes] = time.split(":").map(Number);

    if (period === "pm" && hours !== 12) {
      hours += 12;
    }
    if (period === "am" && hours === 12) {
      hours = 0;
    }

    const value = hours * 2 + minutes / 30;
    return value;
  };

  // Example object with start, end, and enabled
  const timeRange = {
    start: "6:00am",
    end: "8:30pm",
    enabled: true,
  };

  const [value, setValue] = useState([
    getSliderValueFromTime(dayTimeRange.start),
    getSliderValueFromTime(dayTimeRange.end),
  ]); // Default: full 24-hour range

  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(
      setCampaignSendingHours({
        ...campaignSendingHours,
        [day]: {
          start: valueLabelFormat(newValue[0]),
          end: valueLabelFormat(newValue[1]),
          enabled: campaignSendingHours?.[day]?.enabled,
        },
      })
    );
  };

  const valueLabelFormat = (value) => {
    const time = timeMapping.find((t) => t.value === value);
    return time ? time.label : "";
  };

  return (
    <div>
      <div className="flex justify-between">
        <div>
          <Typography
            sx={{ color: themeMode === "dark" ? "white" : "black" }}
            variant="body2"
          >
            {valueLabelFormat(value[0])}
          </Typography>
        </div>
        <div>
          {" "}
          <Typography
            sx={{ color: themeMode === "dark" ? "white" : "black" }}
            variant="body2"
          >
            {valueLabelFormat(value[1])}
          </Typography>
        </div>
      </div>
      <Slider
        value={value}
        min={0}
        max={48}
        step={1}
        marks={false}
        valueLabelDisplay="off"
        valueLabelFormat={valueLabelFormat}
        onChange={handleChange}
        sx={{
          color: "red",
          height: 4,
          "& .MuiSlider-track": {
            color: "red",
          },
          "& .MuiSlider-rail": {
            color: "grey",
          },
          "& .MuiSlider-thumb": {
            height: 16,
            width: 16,
            backgroundColor: themeMode === "dark" ? "white" : "black",
            border: themeMode === "dark" ? "2px solid #FFF" : "2px solid black",
            "&:hover, &.Mui-focusVisible, &.Mui-active": {
              boxShadow: "inherit",
            },
          },
        }}
      />
    </div>
  );
}

export default SendingHoursSlider;
