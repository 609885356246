import React from 'react';
import { Typography, Button, TextField } from '@mui/material';
import { useSelector } from "react-redux";

const InMailMessages = ({ setSelectedSubTab }) => {
    const themeMode = useSelector((state) => state.themeMode.mode);

    return (
        <>
            <Typography variant="h5" gutterBottom sx={{ color: themeMode === "dark" ? 'white': 'black', marginBottom: '20px'}}>
                Edit your In-Mail Messages
            </Typography>
            <Typography variant="h6" sx={{ color: themeMode === "dark" ? 'white': 'black', marginBottom: '10px' }}>
                First InMail Message (1st Message)
            </Typography>
            <Typography variant="subtitle1" gutterBottom sx={{ color: themeMode === "dark" ? 'white': 'black', marginTop: 2 }}>
                InMail Subject Line*
            </Typography>
            <TextField
                fullWidth
                label=""
                variant="outlined"
                margin="normal"
                defaultValue="Subject line generated by AI"
                sx={{
                    '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: themeMode === "dark" ? 'white': 'black' }, 
                    color: themeMode === "dark" ? 'white': 'black',
                    },
                }}
            />
            <Typography variant="subtitle1" gutterBottom sx={{ color: themeMode === "dark" ? 'white': 'black', marginTop: 2 }}>
                InMail Message*
            </Typography>
            <TextField
                fullWidth
                label=""
                variant="outlined"
                margin="normal"
                multiline
                rows={4}
                defaultValue="A long/short message generated by AI"
                sx={{
                    '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: themeMode === "dark" ? 'white': 'black' }, 
                    color: themeMode === "dark" ? 'white': 'black',
                    },
                }}
            />
            <Button
                variant="contained"
                sx={{
                    backgroundColor: '#b11b1b', 
                    color: '#fff',
                    width: '77px',
                    Height: '41px',
                    borderRadius: '30px',
                    padding: '10px 40px',
                    '&:hover': {
                        backgroundColor: '#9b1717',
                    },
                }}
                onClick={() => setSelectedSubTab(3)}
            >
                Next
            </Button>
        </>
    )
}
export default InMailMessages;