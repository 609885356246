import { Outlet, Navigate, Route, Routes } from "react-router-dom";
import { Campaigns } from "./campaign/Campaigns";
import { Connections } from "./connections/Connections";
import { Meetings } from "./meetings/Meetings";
import { Settings } from "./settings/Settings";
import { Messages } from "./messages/messages";
import { OverviewTab } from "./overviewTab/OverviewTab";
import { CampaignDetail } from "./campaign/campaign-detail/CampaignDetail";
import { Payment } from "./payments/Payment";
import WelcomeDashboard from "./WelcomeDashboard";
import HelpCenter from "./helpCenter/HelpCenter";
import PaymentMethods from "./payments/PaymentMethod";
import AddPaymentMethod from "./payments/AddPaymentMethod";

const Preview = () => {
  return (
    <div style={{ minHeight: "100vh" }}>
      <Outlet />
      <Routes>
        <Route path="overview" index element={<OverviewTab />} />
        <Route path="campaigns" index element={<Campaigns />} />
        <Route path="messages" index element={<Messages />} />
        <Route path="campaigns/:name/:id" element={<CampaignDetail />} />
        <Route path="connections" index element={<Connections />} />
        <Route path="meetings" index element={<Meetings />} />
        <Route path="payments" index element={<Payment />} />
        <Route path="settings" index element={<Settings />} />

        <Route path="help-center" index element={<HelpCenter />} />
        <Route path="payment-methods" index element={<PaymentMethods />} />
        <Route path="add-payment-method" index element={<AddPaymentMethod />} />

        {/* <Route path="welcome-dashboard" index element={<WelcomeDashboard />} /> */}

        <Route path="*" element={<Navigate to="overview" />} />
      </Routes>
    </div>
  );
};
export default Preview;
