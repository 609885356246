import { useDispatch, useSelector } from "react-redux";
import { React,useState, useEffect } from "react";
import { Grid, Typography, Toolbar, Tabs, Tab, Button, Link, InputAdornment, FormControl, FormLabel, RadioGroup,  Radio, TextField, FormControlLabel, Checkbox, IconButton,useMediaQuery } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { styled } from "@mui/system";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { error, success } from "../../store/alertSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import __ from "lodash";


const masterCard = "https://gistcdn.githack.com/hamza-smh/5d9cb5c8936222b6c436169b73d4cbb0/raw/e2e3018e6bbab78ab707bbe151807c949939d6e9/masterCard.svg"
const stripeBlue = "https://gistcdn.githack.com/hamza-smh/7c894ab172c3f48094db88a14e1bcb21/raw/9d48be7b5ecf1da4fed962b092f55855762bf208/stripeBlue.svg"
const stripe = "https://gistcdn.githack.com/hamza-smh/9221ed5a952f3290dac0578867ece369/raw/2bff5e46f5ad201fed9c169901e61e545d77def6/stripeWhite.svg";
const paypal = "	https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/paypalFull.png"
const visa = "https://gistcdn.githack.com/hamza-smh/b458542799dfd5c25362ee15c6395556/raw/ef73c34fd8bb8ffc9da8ec68ac66f60771dfa697/visa.svg"
const visaWhite = "https://gistcdn.githack.com/hamza-smh/9c5c05db01cf2df85f1405cd900a14b4/raw/ab9e2a5d912271ec06274c8b20fe2bf75402bab1/visaWhite.svg"

const method = [
    { label: 'Paypal', width: '35%', left: '0%' },
    { label: 'Credit/Debit Card', width: '40%', left: '30%' },
    { label: 'Stripe', width: '35%', left: '65%' },
];
const Animation = styled("div")(({ width, left }) => ({
    position: "absolute",
    height: "100%",
    top: 0,
    zIndex: 0,
    background: "#E50000",
    borderRadius: "30px",
    border: "1px solid #FFF",
    transition: "all .5s ease 0s",
    width: width,
    left: left,
}));

const defaultValues = {
    email: "",
    password: "",
};
const schema = yup
    .object()
    .shape({
        email: yup.string().required("You must type email"),
        password: yup.string().required("You must type your password"),
    })
    .required();

const AddPaymentMethod = () => {
    const dispatch = useDispatch();
    const themeMode = useSelector((state) => state.themeMode.mode);
    const [activeTab, setActiveTab] = useState(0);
    const [hoverTab, setHoverTab] = useState(null);
    const [selectedTab, setSelectedTab] = useState("Paypal");
    const [showPassword, setShowPassword] = useState(false);
    const isCustomRange = useMediaQuery(
        "(min-width:900px) and (max-width:1336px)"
    );

    useEffect(() => {
        const currentPath = window.location.pathname;
        const matchingTab = method.findIndex((item) => item.link === currentPath);
        if (matchingTab !== -1) {
            setActiveTab(matchingTab);
        }
    }, []);

    const getAnimationStyle = () => {
        const tab = hoverTab !== null ? hoverTab : activeTab;
        return {
            width: method[tab]?.width,
            left: method[tab]?.left,
        };
    };

    const [selectedValue, setSelectedValue] = useState('');

    const handleChangeRadio = (event) => {
        setSelectedValue(event.target.value);
    };


    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
        setHoverTab(null);
        setSelectedTab(event.target.value);
    };
    const handleMouseEnter = (index) => {
        setHoverTab(index);
    };

    const handleMouseLeave = () => {
        setHoverTab(null);
    };

    const { handleSubmit, register, reset, control, watch, formState } = useForm({
        defaultValues,
        mode: "all",
        resolver: yupResolver(schema),
    });

    const { errors } = formState;

    function onSubmit(data) {
        console.log("Add Payment Complete")
        // dispatch(loginUser(data)).then((resp) => {
        //     if (resp?.payload?.status === 200) {
        //         dispatch(getUserProfile());
        //         dispatch(getLinkedInConnectedStatus());
        //         navigate("/dashboard");
        //     }
        //     else (
        //         dispatch(error("Invalid user or password"))
        //     )
        // });
    }


    return (
        <div>
            <div className="pt-24 pb-6 md:px-16 px-8">
                <div
                    className={`dashboard-title ${themeMode === "dark" ? "text-light" : "text-dark"
                        }`}
                >
                    Add Payment Methods
                </div>
                <div
                    className={`dashboard-subtitle py-4 ${themeMode === "dark" ? "text-light" : "text-dark"
                        }`}
                >
                    Choose your preferred payment method.{" "}
                </div>
            </div>
            <div className="md:px-16 xs:px-2 px-8">
                <Toolbar
                    className="paymentMethod-slider"
                    sx={{
                        display: {md:"flex",sm:"flex",xs:"none"},
                        width: { md:isCustomRange?"75%":"50%", sm: "75%", xs: "100%" },
                        overflowX: { md: "hidden", sm: "auto" },
                    }}
                >
                    <Tabs
                        value={activeTab}
                        onChange={handleChange}
                        TabIndicatorProps={{ style: { display: "none" } }}
                        variant="fullWidth"
                        sx={{
                            width: "100%",
                        }}
                    >
                        {method.map((tab, index) => (
                            <Tab
                                key={index}
                                label={tab.label}
                                style={{ minWidth: { md: tab.width * 1.5, sm: tab.width * 0.5 } }}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={() => handleMouseLeave(index)}
                                onClick={() => setSelectedTab(tab.label)}
                                disableRipple
                                className="paymentItem"
                                sx={{
                                    width: "fit-content",
                                    height: "40px !important",
                                    fontSize: { md: "16px", sm: "14px",xs:"12px" },
                                    padding: { md: "0px 25px", sm: "5px 10px" },
                                }}
                            />
                        ))}
                    </Tabs>
                    <Animation {...getAnimationStyle()} />


                </Toolbar>

                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="options"
                        name="options"
                        value={selectedTab} // Set the value of RadioGroup to selectedTab
                        onChange={handleChange}
                        sx={{ display: { md: "none", sm: "none", xs: "flex" }, flexDirection: 'column' }}
                    >
                        {
                            method.map((item, index) => (
                                <FormControlLabel
                                    key={index}
                                    value={item.label} // Set the value to item.label
                                    control={
                                        <Radio
                                            sx={{
                                                color: themeMode === "dark" ? "#FFF" : "#000",
                                                '&.Mui-checked': { color: "red" }
                                            }}
                                        />
                                    }
                                    label={item.label}
                                />
                            ))
                        }
                    </RadioGroup>
                </FormControl>

            </div>
            <Grid className="mt-16 md:px-16 px-8" sx={{display:"flex",justifyContent:{md:"flex-start",sm:"flex-start",xs: "space-between"},width:"100%"}}>
                {selectedTab === "Stripe" && (
                    <img src={stripeBlue} alt="Stripe" style={{ width: "210px" }} />
                )}
                {selectedTab === "Paypal" && (
                    <img src={paypal} alt="Paypal" style={{ width: "210px" }} />
                )}
                {selectedTab === "Credit/Debit Card" && (
                    <>
                        <img src={visa} alt="Visa" style={{ width: "105px", marginRight: "15px" }} />
                        <img src={masterCard} alt="Mastercard" style={{ width: "105px" }} />
                    </>
                )}
            </Grid>

            <Grid sx={{ width: "100%", display: "flex", flexDirection: "column" }} className="py-12 md:px-16 px-8">

                {
                        selectedTab==="Paypal"?
                            <>
                        <Grid>
                            <Controller
                                render={({ field }) => (
                                    <>
                                        <TextField
                                            {...field}
                                            placeholder="Email"
                                            error={!!errors.email}
                                            autoComplete="off"
                                            required
                                            className="payment-method-input"
                                            sx={{
                                                background: "transparent",
                                                width: { md: "40%", sm: "66%", xs: "100%" },
                                                "& .MuiOutlinedInput-root": {
                                                    background: "transparent",
                                                    "& fieldset": {
                                                        border: "none",
                                                        background: "transparent",
                                                    },
                                                },
                                                "& .MuiInputBase-input": {
                                                    background: "transparent",
                                                    color: themeMode === "dark" ? "#FFF" : "#000",
                                                },
                                            }}
                                        />
                                        {errors.email && (
                                            <Typography
                                                variant="body2"
                                                color="error"
                                                sx={{ mt: 1, ml: 1 }}
                                            >
                                                {errors.email.message}
                                            </Typography>
                                        )}
                                    </>
                                )}
                                name="email"
                                control={control}
                            />
                        </Grid>
                        <Grid my={6}>
                            <Controller
                                render={({ field }) => (
                                    <>
                                        <TextField
                                            {...field}
                                            placeholder="Passsword"
                                            error={!!errors.password}
                                            autoComplete="off"
                                            //helperText={errors?.password?.message}
                                            type={showPassword ? "text" : "password"}
                                            required
                                            className="payment-method-input"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            edge="end"
                                                            style={{ color: "#FFF" }}
                                                        >
                                                            {showPassword ? (
                                                                <VisibilityOff style={{ color: themeMode === "dark" ? "#FFF" : "#000" }} />
                                                            ) : (
                                                                <Visibility style={{ color: themeMode === "dark" ? "#FFF" : "#000" }} />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            sx={{
                                                background: "transparent",
                                                width: { md: "40%", sm: "66%", xs: "100%" },
                                                "& .MuiOutlinedInput-root": {
                                                    background: "transparent",
                                                    "& fieldset": {
                                                        border: "none",
                                                        background: "transparent",
                                                    },
                                                },
                                                "& .MuiInputBase-input": {
                                                    background: "transparent",
                                                    color: themeMode === "dark" ? "#FFF" : "#000",
                                                },
                                            }}
                                        />
                                        {errors?.password && (
                                            <Typography
                                                variant="body2"
                                                color="error"
                                                sx={{ mt: 1, ml: 1 }}
                                            >
                                                {errors.password.message}
                                            </Typography>
                                        )}
                                    </>
                                )}
                                name="password"
                                control={control}
                            />
                        </Grid>
                            </>
                    :
                        selectedTab === "Credit/Debit Card" ?
                            <>
                                <Grid>
                                    <Controller
                                        render={({ field }) => (
                                            <>
                                                <TextField
                                                    {...field}  
                                                    label="Card Holder Name"
                                                    //error={!!errors.card_holder_name}
                                                    size="small"
                                                    autoComplete="off"
                                                    required
                                                    className="payment-method-input"
                                                    sx={{
                                                        background: "transparent",
                                                        width: { md: "30%", sm: "66%", xs: "100%" },
                                                        "& .MuiOutlinedInput-root": {
                                                            background: "transparent",
                                                            "& fieldset": {
                                                                border: "none",
                                                                background: "transparent",
                                                            },
                                                        },
                                                        "& .MuiInputBase-input": {
                                                            background: "transparent",
                                                            color: themeMode === "dark" ? "#FFF" : "#000",
                                                        },
                                                        '& .MuiInputLabel-root': {
                                                          color: themeMode === 'dark' ? '#ffff' : '#000',
                                                          fontSize: '12px',
                                                          marginBottom: '15px',
                                                          transform: 'translate(0px, -20px) scale(1)',
                                                          '&.MuiInputLabel-shrink': {
                                                            transform: 'translate(0px, -20px) scale(1)'
                                                          }
                                                        },
                                                    }}
                                                />
                                                
                                            </>
                                        )}
                                        name="card-holder-name"
                                        control={control}
                                    />
                                </Grid>
                                <Grid my={6} sx={{display:"flex",flexDirection:{md:"row",sm:"row",xs:"column"}}}>
                                    <Controller
                                        render={({ field }) => (
                                            <>
                                                <TextField
                                                    {...field}
                                                    label="Card Number"
                                                    size="small"
                                                    //error={!!errors.password}
                                                    autoComplete="off"
                                                    //helperText={errors?.password?.message}
                                                    //type={showPassword ? "text" : "password"}
                                                    //required
                                                    className="payment-method-input"
                                                    sx={{
                                                        background: "transparent",
                                                        width: { md: "30%", sm: "66%", xs: "100%" },
                                                        "& .MuiOutlinedInput-root": {
                                                            background: "transparent",
                                                            "& fieldset": {
                                                                border: "none",
                                                                background: "transparent",
                                                            },
                                                        },
                                                        "& .MuiInputBase-input": {
                                                            background: "transparent",
                                                            color: themeMode === "dark" ? "#FFF" : "#000",
                                                        },
                                                        '& .MuiInputLabel-root': {
                                                          color: themeMode === 'dark' ? '#ffff' : '#000',
                                                          fontSize: '12px',
                                                          marginBottom: '15px',
                                                          transform: 'translate(0px, -20px) scale(1)',
                                                          '&.MuiInputLabel-shrink': {
                                                            transform: 'translate(0px, -20px) scale(1)'
                                                          }
                                                        },
                                                    }}
                                                />
                                                
                                            </>
                                        )}
                                        name="password"
                                        control={control}
                                    />
                                    <Grid mx={{md:3,sm:6,xs:0}} sx={{display:"flex",marginY:{md:"0px",sm:"0px",xs:"40px"},justifyContent:"space-between",width:{md:"23%",sm:"50%",xs:"100%"}}}>
                                        <Controller
                                            render={({ field }) => (
                                            <>
                                                <TextField
                                                    {...field}
                                                    label="Validity"
                                                    placeholder="mm/yy"
                                                    size="small"
                                                    autoComplete="off"
                                                    //error={!!errors.password}
                                                    //helperText={errors?.password?.message}
                                                    className="payment-method-input"
                                                    
                                                    sx={{
                                                        background: "transparent",
                                                        width: { md: "45%", sm: "45%", xs: "45%" },
                                                        "& .MuiOutlinedInput-root": {
                                                            background: "transparent",
                                                            "& fieldset": {
                                                                border: "none",
                                                                background: "transparent",
                                                            },
                                                        },
                                                        "& .MuiInputBase-input": {
                                                            background: "transparent",
                                                            color: themeMode === "dark" ? "#FFF" : "#000",
                                                        },
                                                        '& .MuiInputLabel-root': {
                                                            color: themeMode === 'dark' ? '#ffff' : '#000',
                                                            fontSize: '12px',
                                                            marginBottom: '15px',
                                                            transform: 'translate(0px, -20px) scale(1)',
                                                            '&.MuiInputLabel-shrink': {
                                                                transform: 'translate(0px, -20px) scale(1)'
                                                            }
                                                        },
                                                    }}
                                                />

                                            </>
                                        )}
                                        name="validity"
                                            control={control}
                                        />

                                        <Controller
                                            render={({ field }) => (
                                            <>
                                                <TextField
                                                    {...field}
                                                    label="CVC"
                                                    placeholder="***"
                                                    size="small"
                                                    autoComplete="off"
                                                    //error={!!errors.password}
                                                    //helperText={errors?.password?.message}
                                                    required
                                                    className="payment-method-input"
                                                    sx={{
                                                        background: "transparent",
                                                        width: { md: "45%", sm: "45%", xs: "45%" },
                                                        "& .MuiOutlinedInput-root": {
                                                            background: "transparent",
                                                            "& fieldset": {
                                                                border: "none",
                                                                background: "transparent",
                                                            },
                                                        },
                                                        "& .MuiInputBase-input": {
                                                            background: "transparent",
                                                            color: themeMode === "dark" ? "#FFF" : "#000",
                                                        },
                                                        '& .MuiInputLabel-root': {
                                                            color: themeMode === 'dark' ? '#ffff' : '#000',
                                                            fontSize: '12px',
                                                            marginBottom: '15px',
                                                            transform: 'translate(0px, -20px) scale(1)',
                                                            '&.MuiInputLabel-shrink': {
                                                                transform: 'translate(0px, -20px) scale(1)'
                                                            }
                                                        },
                                                    }}
                                                />

                                            </>
                                        )}
                                        name="cvc"
                                            control={control}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid mb={6}>
                                    <Typography sx={{fontSize:"20px",fontWeight:"700"}}>Billing Address</Typography>
                                </Grid>
                                <Grid sx={{display:"flex"}}>
                                    <Controller
                                        render={({ field }) => (
                                            <>
                                                <TextField
                                                    {...field}
                                                    label="Address Line 1"
                                                    //error={!!errors.card_holder_name}
                                                    size="small"
                                                    autoComplete="off"
                                                    className="payment-method-input"
                                                    sx={{
                                                        background: "transparent",
                                                        width: { md: "30%", sm: "80%", xs: "100%" },
                                                        "& .MuiOutlinedInput-root": {
                                                            background: "transparent",
                                                            "& fieldset": {
                                                                border: "none",
                                                                background: "transparent",
                                                            },
                                                        },
                                                        "& .MuiInputBase-input": {
                                                            background: "transparent",
                                                            color: themeMode === "dark" ? "#FFF" : "#000",
                                                        },
                                                        '& .MuiInputLabel-root': {
                                                            color: themeMode === 'dark' ? '#ffff' : '#000',
                                                            fontSize: '12px',
                                                            marginBottom: '15px',
                                                            transform: 'translate(0px, -20px) scale(1)',
                                                            '&.MuiInputLabel-shrink': {
                                                                transform: 'translate(0px, -20px) scale(1)'
                                                            }
                                                        },
                                                    }}
                                                />

                                            </>
                                        )}
                                        name="address-line-1"
                                        control={control}
                                    />

                                    <Controller
                                        render={({ field }) => (
                                            <>
                                                <TextField
                                                    {...field}
                                                    label="Postal Code"
                                                    size="small"
                                                    autoComplete="off"
                                                    //error={!!errors.password}
                                                    //helperText={errors?.password?.message}
                                                    required
                                                    className="payment-method-input mx-6"
                                                    sx={{
                                                        display:{md:"flex",sm:"none",xs:"none"},
                                                        background: "transparent",
                                                        width: { md: "15%", sm: "20%", xs: "100%" },
                                                        "& .MuiOutlinedInput-root": {
                                                            background: "transparent",
                                                            "& fieldset": {
                                                                border: "none",
                                                                background: "transparent",
                                                            },
                                                        },
                                                        "& .MuiInputBase-input": {
                                                            background: "transparent",
                                                            color: themeMode === "dark" ? "#FFF" : "#000",
                                                        },
                                                        '& .MuiInputLabel-root': {
                                                            color: themeMode === 'dark' ? '#ffff' : '#000',
                                                            fontSize: '12px',
                                                            marginBottom: '15px',
                                                            transform: 'translate(0px, -20px) scale(1)',
                                                            '&.MuiInputLabel-shrink': {
                                                                transform: 'translate(0px, -20px) scale(1)'
                                                            }
                                                        },
                                                    }}
                                                />

                                            </>
                                        )}
                                        name="postal_code"
                                        control={control}
                                    />
                                </Grid>
                                <Grid my={6} sx={{ display: "flex",flexDirection:{md:"row",sm:"column",xs:"column"} }}>
                                    <Controller
                                        render={({ field }) => (
                                            <>
                                                <TextField
                                                    {...field}
                                                    label="Address Line 2"
                                                    size="small"
                                                    //error={!!errors.password}
                                                    autoComplete="off"
                                                    //helperText={errors?.password?.message}
                                                    //type={showPassword ? "text" : "password"}
                                                    //required
                                                    className="payment-method-input"
                                                    sx={{
                                                        background: "transparent",
                                                        width: { md: "30%", sm: "80%", xs: "100%" },
                                                        marginBottom:{md:"0px",sm:"40px",xs:"15px"},
                                                        "& .MuiOutlinedInput-root": {
                                                            background: "transparent",
                                                            "& fieldset": {
                                                                border: "none",
                                                                background: "transparent",
                                                            },
                                                        },
                                                        "& .MuiInputBase-input": {
                                                            background: "transparent",
                                                            color: themeMode === "dark" ? "#FFF" : "#000",
                                                        },
                                                        '& .MuiInputLabel-root': {
                                                            color: themeMode === 'dark' ? '#ffff' : '#000',
                                                            fontSize: '12px',
                                                            marginBottom: '15px',
                                                            transform: 'translate(0px, -20px) scale(1)',
                                                            '&.MuiInputLabel-shrink': {
                                                                transform: 'translate(0px, -20px) scale(1)'
                                                            }
                                                        },
                                                    }}
                                                />

                                            </>
                                        )}
                                        name="address-line-2"
                                        control={control}
                                    />
                                    <Grid mx={{ md: 3, sm: 0, xs: 0 }} my={{md:0,sm:3,xs:3}} sx={{ display: "flex",flexDirection:{md:"row",sm:"row",xs:"column"}, marginY: { md: "0px", sm: "0px", xs: "40px" }, justifyContent: "space-between", width: {md:"35%",sm:"100%",xs:"100%"} }}>
                                        <Controller
                                            render={({ field }) => (
                                            <>
                                                <TextField
                                                    {...field}
                                                    label="City"
                                                    size="small"
                                                    //error={!!errors.password}
                                                    autoComplete="off"
                                                    //helperText={errors?.password?.message}
                                                    //type={showPassword ? "text" : "password"}
                                                    required
                                                    className="payment-method-input"
                                                    sx={{
                                                        background: "transparent",
                                                        width: { md: "45%", sm: "45%", xs: "100%" },
                                                        "& .MuiOutlinedInput-root": {
                                                            background: "transparent",
                                                            "& fieldset": {
                                                                border: "none",
                                                                background: "transparent",
                                                            },
                                                        },
                                                        "& .MuiInputBase-input": {
                                                            background: "transparent",
                                                            color: themeMode === "dark" ? "#FFF" : "#000",
                                                        },
                                                        '& .MuiInputLabel-root': {
                                                            color: themeMode === 'dark' ? '#ffff' : '#000',
                                                            fontSize: '12px',
                                                            marginBottom: '15px',
                                                            transform: 'translate(0px, -20px) scale(1)',
                                                            '&.MuiInputLabel-shrink': {
                                                                transform: 'translate(0px, -20px) scale(1)'
                                                            }
                                                        },
                                                    }}
                                                />

                                            </>
                                        )}
                                        name="city"
                                            control={control}
                                        />

                                        <Controller
                                            render={({ field }) => (
                                            <>
                                                <TextField
                                                    {...field}
                                                    label="Country"
                                                    //error={!!errors.password}
                                                    size="small"
                                                    autoComplete="off"
                                                    //helperText={errors?.password?.message}
                                                    required
                                                    className="payment-method-input"
                                                    sx={{
                                                        background: "transparent",
                                                        width: { md: "45%", sm: "45%", xs: "100%" },
                                                        marginX:{md:3,sm:3,xs:0},
                                                        marginY: { md: 0, sm: 0, xs: 4 },
                                                        "& .MuiOutlinedInput-root": {
                                                            background: "transparent",
                                                            "& fieldset": {
                                                                border: "none",
                                                                background: "transparent",
                                                            },
                                                        },
                                                        "& .MuiInputBase-input": {
                                                            background: "transparent",
                                                            color: themeMode === "dark" ? "#FFF" : "#000",
                                                        },
                                                        '& .MuiInputLabel-root': {
                                                            color: themeMode === 'dark' ? '#ffff' : '#000',
                                                            fontSize: '12px',
                                                            marginBottom: '15px',
                                                            transform: 'translate(0px, -20px) scale(1)',
                                                            '&.MuiInputLabel-shrink': {
                                                                transform: 'translate(0px, -20px) scale(1)'
                                                            }
                                                        },
                                                    }}
                                                
                                                />

                                            </>
                                        )}
                                        name="cvc"
                                            control={control}
                                        />

                                        <Controller
                                            render={({ field }) => (
                                                <>
                                                    <TextField
                                                        {...field}
                                                        label="Postal Code"
                                                        size="small"
                                                        autoComplete="off"
                                                        //error={!!errors.password}
                                                        //helperText={errors?.password?.message}
                                                        required
                                                        className="payment-method-input"
                                                        sx={{
                                                            display: { md: "none", sm: "flex", xs: "flex" },
                                                            background: "transparent",
                                                            width: { md: "15%", sm: "20%", xs: "100%" },
                                                            "& .MuiOutlinedInput-root": {
                                                                background: "transparent",
                                                                "& fieldset": {
                                                                    border: "none",
                                                                    background: "transparent",
                                                                },
                                                            },
                                                            "& .MuiInputBase-input": {
                                                                background: "transparent",
                                                                color: themeMode === "dark" ? "#FFF" : "#000",
                                                            },
                                                            '& .MuiInputLabel-root': {
                                                                color: themeMode === 'dark' ? '#ffff' : '#000',
                                                                fontSize: '12px',
                                                                marginBottom: '15px',
                                                                transform: 'translate(0px, -20px) scale(1)',
                                                                '&.MuiInputLabel-shrink': {
                                                                    transform: 'translate(0px, -20px) scale(1)'
                                                                }
                                                            },
                                                        }}
                                                    />

                                                </>
                                            )}
                                            name="postal_code"
                                            control={control}
                                        />
                                    </Grid>
                                </Grid>

                            </>
                    :
                        selectedTab === "Stripe" ?
                                <>
                                    <Grid>
                                        <Controller
                                            render={({ field }) => (
                                                <>
                                                    <TextField
                                                        {...field}
                                                        label="Card Holder Name"
                                                        //error={!!errors.card_holder_name}
                                                        size="small"
                                                        autoComplete="off"
                                                        required
                                                        className="payment-method-input"
                                                        sx={{
                                                            background: "transparent",
                                                            width: { md: "30%", sm: "66%", xs: "100%" },
                                                            "& .MuiOutlinedInput-root": {
                                                                background: "transparent",
                                                                "& fieldset": {
                                                                    border: "none",
                                                                    background: "transparent",
                                                                },
                                                            },
                                                            "& .MuiInputBase-input": {
                                                                background: "transparent",
                                                                color: themeMode === "dark" ? "#FFF" : "#000",
                                                            },
                                                            '& .MuiInputLabel-root': {
                                                                color: themeMode === 'dark' ? '#ffff' : '#000',
                                                                fontSize: '12px',
                                                                marginBottom: '15px',
                                                                transform: 'translate(0px, -20px) scale(1)',
                                                                '&.MuiInputLabel-shrink': {
                                                                    transform: 'translate(0px, -20px) scale(1)'
                                                                }
                                                            },
                                                        }}
                                                    />

                                                </>
                                            )}
                                            name="card-holder-name"
                                            control={control}
                                        />
                                    </Grid>
                                    <Grid my={6} sx={{ display: "flex", flexDirection: { md: "row", sm: "row", xs: "column" } }}>
                                        <Controller
                                            render={({ field }) => (
                                                <>
                                                    <TextField
                                                        {...field}
                                                        label="Card Number"
                                                        size="small"
                                                        //error={!!errors.password}
                                                        autoComplete="off"
                                                        //helperText={errors?.password?.message}
                                                        //type={showPassword ? "text" : "password"}
                                                        //required
                                                        className="payment-method-input"
                                                        sx={{
                                                            background: "transparent",
                                                            width: { md: "30%", sm: "66%", xs: "100%" },
                                                            "& .MuiOutlinedInput-root": {
                                                                background: "transparent",
                                                                "& fieldset": {
                                                                    border: "none",
                                                                    background: "transparent",
                                                                },
                                                            },
                                                            "& .MuiInputBase-input": {
                                                                background: "transparent",
                                                                color: themeMode === "dark" ? "#FFF" : "#000",
                                                            },
                                                            '& .MuiInputLabel-root': {
                                                                color: themeMode === 'dark' ? '#ffff' : '#000',
                                                                fontSize: '12px',
                                                                marginBottom: '15px',
                                                                transform: 'translate(0px, -20px) scale(1)',
                                                                '&.MuiInputLabel-shrink': {
                                                                    transform: 'translate(0px, -20px) scale(1)'
                                                                }
                                                            },
                                                        }}
                                                    />

                                                </>
                                            )}
                                            name="password"
                                            control={control}
                                        />
                                        <Grid mx={{ md: 3, sm: 6, xs: 0 }} sx={{ display: "flex", marginY: { md: "0px", sm: "0px", xs: "40px" }, justifyContent: "space-between", width: { md: "23%", sm: "50%", xs: "100%" } }}>
                                            <Controller
                                                render={({ field }) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            label="Validity"
                                                            placeholder="mm/yy"
                                                            size="small"
                                                            autoComplete="off"
                                                            //error={!!errors.password}
                                                            //helperText={errors?.password?.message}
                                                            className="payment-method-input"

                                                            sx={{
                                                                background: "transparent",
                                                                width: { md: "45%", sm: "45%", xs: "45%" },
                                                                "& .MuiOutlinedInput-root": {
                                                                    background: "transparent",
                                                                    "& fieldset": {
                                                                        border: "none",
                                                                        background: "transparent",
                                                                    },
                                                                },
                                                                "& .MuiInputBase-input": {
                                                                    background: "transparent",
                                                                    color: themeMode === "dark" ? "#FFF" : "#000",
                                                                },
                                                                '& .MuiInputLabel-root': {
                                                                    color: themeMode === 'dark' ? '#ffff' : '#000',
                                                                    fontSize: '12px',
                                                                    marginBottom: '15px',
                                                                    transform: 'translate(0px, -20px) scale(1)',
                                                                    '&.MuiInputLabel-shrink': {
                                                                        transform: 'translate(0px, -20px) scale(1)'
                                                                    }
                                                                },
                                                            }}
                                                        />

                                                    </>
                                                )}
                                                name="validity"
                                                control={control}
                                            />

                                            <Controller
                                                render={({ field }) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            label="CVC"
                                                            placeholder="***"
                                                            size="small"
                                                            autoComplete="off"
                                                            //error={!!errors.password}
                                                            //helperText={errors?.password?.message}
                                                            required
                                                            className="payment-method-input"
                                                            sx={{
                                                                background: "transparent",
                                                                width: { md: "45%", sm: "45%", xs: "45%" },
                                                                "& .MuiOutlinedInput-root": {
                                                                    background: "transparent",
                                                                    "& fieldset": {
                                                                        border: "none",
                                                                        background: "transparent",
                                                                    },
                                                                },
                                                                "& .MuiInputBase-input": {
                                                                    background: "transparent",
                                                                    color: themeMode === "dark" ? "#FFF" : "#000",
                                                                },
                                                                '& .MuiInputLabel-root': {
                                                                    color: themeMode === 'dark' ? '#ffff' : '#000',
                                                                    fontSize: '12px',
                                                                    marginBottom: '15px',
                                                                    transform: 'translate(0px, -20px) scale(1)',
                                                                    '&.MuiInputLabel-shrink': {
                                                                        transform: 'translate(0px, -20px) scale(1)'
                                                                    }
                                                                },
                                                            }}
                                                        />

                                                    </>
                                                )}
                                                name="cvc"
                                                control={control}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid mb={6}>
                                        <Typography sx={{ fontSize: "20px", fontWeight: "700" }}>Billing Address (Optional)</Typography>
                                    </Grid>
                                    <Grid sx={{ display: "flex" }}>
                                        <Controller
                                            render={({ field }) => (
                                                <>
                                                    <TextField
                                                        {...field}
                                                        label="Address Line 1"
                                                        //error={!!errors.card_holder_name}
                                                        size="small"
                                                        autoComplete="off"
                                                        className="payment-method-input"
                                                        sx={{
                                                            background: "transparent",
                                                            width: { md: "30%", sm: "80%", xs: "100%" },
                                                            "& .MuiOutlinedInput-root": {
                                                                background: "transparent",
                                                                "& fieldset": {
                                                                    border: "none",
                                                                    background: "transparent",
                                                                },
                                                            },
                                                            "& .MuiInputBase-input": {
                                                                background: "transparent",
                                                                color: themeMode === "dark" ? "#FFF" : "#000",
                                                            },
                                                            '& .MuiInputLabel-root': {
                                                                color: themeMode === 'dark' ? '#ffff' : '#000',
                                                                fontSize: '12px',
                                                                marginBottom: '15px',
                                                                transform: 'translate(0px, -20px) scale(1)',
                                                                '&.MuiInputLabel-shrink': {
                                                                    transform: 'translate(0px, -20px) scale(1)'
                                                                }
                                                            },
                                                        }}
                                                    />

                                                </>
                                            )}
                                            name="address-line-1"
                                            control={control}
                                        />

                                        <Controller
                                            render={({ field }) => (
                                                <>
                                                    <TextField
                                                        {...field}
                                                        label="Postal Code"
                                                        size="small"
                                                        autoComplete="off"
                                                        //error={!!errors.password}
                                                        //helperText={errors?.password?.message}
                                                        required
                                                        className="payment-method-input mx-6"
                                                        sx={{
                                                            display: { md: "flex", sm: "none", xs: "none" },
                                                            background: "transparent",
                                                            width: { md: "15%", sm: "20%", xs: "100%" },
                                                            "& .MuiOutlinedInput-root": {
                                                                background: "transparent",
                                                                "& fieldset": {
                                                                    border: "none",
                                                                    background: "transparent",
                                                                },
                                                            },
                                                            "& .MuiInputBase-input": {
                                                                background: "transparent",
                                                                color: themeMode === "dark" ? "#FFF" : "#000",
                                                            },
                                                            '& .MuiInputLabel-root': {
                                                                color: themeMode === 'dark' ? '#ffff' : '#000',
                                                                fontSize: '12px',
                                                                marginBottom: '15px',
                                                                transform: 'translate(0px, -20px) scale(1)',
                                                                '&.MuiInputLabel-shrink': {
                                                                    transform: 'translate(0px, -20px) scale(1)'
                                                                }
                                                            },
                                                        }}
                                                    />

                                                </>
                                            )}
                                            name="postal_code"
                                            control={control}
                                        />
                                    </Grid>
                                    <Grid my={6} sx={{ display: "flex", flexDirection: { md: "row", sm: "column", xs: "column" } }}>
                                        <Controller
                                            render={({ field }) => (
                                                <>
                                                    <TextField
                                                        {...field}
                                                        label="Address Line 2"
                                                        size="small"
                                                        //error={!!errors.password}
                                                        autoComplete="off"
                                                        //helperText={errors?.password?.message}
                                                        //type={showPassword ? "text" : "password"}
                                                        //required
                                                        className="payment-method-input"
                                                        sx={{
                                                            background: "transparent",
                                                            width: { md: "30%", sm: "80%", xs: "100%" },
                                                            marginBottom: { md: "0px", sm: "40px", xs: "15px" },
                                                            "& .MuiOutlinedInput-root": {
                                                                background: "transparent",
                                                                "& fieldset": {
                                                                    border: "none",
                                                                    background: "transparent",
                                                                },
                                                            },
                                                            "& .MuiInputBase-input": {
                                                                background: "transparent",
                                                                color: themeMode === "dark" ? "#FFF" : "#000",
                                                            },
                                                            '& .MuiInputLabel-root': {
                                                                color: themeMode === 'dark' ? '#ffff' : '#000',
                                                                fontSize: '12px',
                                                                marginBottom: '15px',
                                                                transform: 'translate(0px, -20px) scale(1)',
                                                                '&.MuiInputLabel-shrink': {
                                                                    transform: 'translate(0px, -20px) scale(1)'
                                                                }
                                                            },
                                                        }}
                                                    />

                                                </>
                                            )}
                                            name="address-line-2"
                                            control={control}
                                        />
                                        <Grid mx={{ md: 3, sm: 0, xs: 0 }} my={{ md: 0, sm: 3, xs: 3 }} sx={{ display: "flex", flexDirection: { md: "row", sm: "row", xs: "column" }, marginY: { md: "0px", sm: "0px", xs: "40px" }, justifyContent: "space-between", width: { md: "35%", sm: "100%", xs: "100%" } }}>
                                            <Controller
                                                render={({ field }) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            label="City"
                                                            size="small"
                                                            //error={!!errors.password}
                                                            autoComplete="off"
                                                            //helperText={errors?.password?.message}
                                                            //type={showPassword ? "text" : "password"}
                                                            required
                                                            className="payment-method-input"
                                                            sx={{
                                                                background: "transparent",
                                                                width: { md: "45%", sm: "45%", xs: "100%" },
                                                                "& .MuiOutlinedInput-root": {
                                                                    background: "transparent",
                                                                    "& fieldset": {
                                                                        border: "none",
                                                                        background: "transparent",
                                                                    },
                                                                },
                                                                "& .MuiInputBase-input": {
                                                                    background: "transparent",
                                                                    color: themeMode === "dark" ? "#FFF" : "#000",
                                                                },
                                                                '& .MuiInputLabel-root': {
                                                                    color: themeMode === 'dark' ? '#ffff' : '#000',
                                                                    fontSize: '12px',
                                                                    marginBottom: '15px',
                                                                    transform: 'translate(0px, -20px) scale(1)',
                                                                    '&.MuiInputLabel-shrink': {
                                                                        transform: 'translate(0px, -20px) scale(1)'
                                                                    }
                                                                },
                                                            }}
                                                        />

                                                    </>
                                                )}
                                                name="city"
                                                control={control}
                                            />

                                            <Controller
                                                render={({ field }) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            label="Country"
                                                            //error={!!errors.password}
                                                            size="small"
                                                            autoComplete="off"
                                                            //helperText={errors?.password?.message}
                                                            required
                                                            className="payment-method-input"
                                                            sx={{
                                                                background: "transparent",
                                                                width: { md: "45%", sm: "45%", xs: "100%" },
                                                                marginX: { md: 3, sm: 3, xs: 0 },
                                                                marginY: { md: 0, sm: 0, xs: 4 },
                                                                "& .MuiOutlinedInput-root": {
                                                                    background: "transparent",
                                                                    "& fieldset": {
                                                                        border: "none",
                                                                        background: "transparent",
                                                                    },
                                                                },
                                                                "& .MuiInputBase-input": {
                                                                    background: "transparent",
                                                                    color: themeMode === "dark" ? "#FFF" : "#000",
                                                                },
                                                                '& .MuiInputLabel-root': {
                                                                    color: themeMode === 'dark' ? '#ffff' : '#000',
                                                                    fontSize: '12px',
                                                                    marginBottom: '15px',
                                                                    transform: 'translate(0px, -20px) scale(1)',
                                                                    '&.MuiInputLabel-shrink': {
                                                                        transform: 'translate(0px, -20px) scale(1)'
                                                                    }
                                                                },
                                                            }}

                                                        />

                                                    </>
                                                )}
                                                name="cvc"
                                                control={control}
                                            />

                                            <Controller
                                                render={({ field }) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            label="Postal Code"
                                                            size="small"
                                                            autoComplete="off"
                                                            //error={!!errors.password}
                                                            //helperText={errors?.password?.message}
                                                            required
                                                            className="payment-method-input"
                                                            sx={{
                                                                display: { md: "none", sm: "flex", xs: "flex" },
                                                                background: "transparent",
                                                                width: { md: "15%", sm: "20%", xs: "100%" },
                                                                "& .MuiOutlinedInput-root": {
                                                                    background: "transparent",
                                                                    "& fieldset": {
                                                                        border: "none",
                                                                        background: "transparent",
                                                                    },
                                                                },
                                                                "& .MuiInputBase-input": {
                                                                    background: "transparent",
                                                                    color: themeMode === "dark" ? "#FFF" : "#000",
                                                                },
                                                                '& .MuiInputLabel-root': {
                                                                    color: themeMode === 'dark' ? '#ffff' : '#000',
                                                                    fontSize: '12px',
                                                                    marginBottom: '15px',
                                                                    transform: 'translate(0px, -20px) scale(1)',
                                                                    '&.MuiInputLabel-shrink': {
                                                                        transform: 'translate(0px, -20px) scale(1)'
                                                                    }
                                                                },
                                                            }}
                                                        />

                                                    </>
                                                )}
                                                name="postal_code"
                                                control={control}
                                            />
                                        </Grid>
                                    </Grid>

                                </>
                    :
                    ""
                }
                    
                
                <Grid className={themeMode==="dark"?"text-light":"text-dark"}>
                    <FormControlLabel
                        sx={{color:themeMode==="dark"?"#FFF":"#000"}}
                        control={
                            <Checkbox
                                size="large"
                                sx={{
                                    color: themeMode === "dark" ? "#FFF" : "#000",
                                    "&.Mui-checked": {
                                        color: themeMode === "dark" ? "#FFF" : "#000"
                                    },
                                }}
                            />
                        }
                        label="Set as primary payment method"
                    />
                </Grid>
                <div className="pt-10">
                    <Button
                        variant="contained"
                        size="large"
                        style={{
                            backgroundColor: "#E50000",
                            color: "white",
                            borderRadius: "30px",
                            border:"1px solid #FFF"
                        }}
                        onClick={handleSubmit(onSubmit)}
                    >
                        <span className="sign-button">Add payment method</span>
                    </Button>
                    <Typography className={`pt-6 ${themeMode === "dark" ? "text-light" : "text-dark"}`}>
                        Payment information is encrypted and secure. Learn more about our{" "}
                        <Button disableRipple onClick={() => (window.location.href = "/privacy-policy")} sx={{padding:"0px",minWidth:"0px",textTransform:"none", fontSize: "18px", fontStyle: "italic", fontWeight: "700", color: themeMode === "dark" ? "#FFF" : "#000",textDecoration:"none","&:hover":{background:"transparent"} }}>
                            Privacy Policy.
                        </Button>
                    </Typography>

                </div>
            </Grid>
        </div>
    )
}
export default AddPaymentMethod;