import React, { useEffect } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Snackbar from "@mui/material/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import { clear } from "../app/store/alertSlice";

const AlertMessage = () => {
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);
  const timeOut = 6000;
  const handleClose = () => {
    dispatch(clear());
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(clear());
    }, timeOut);

    return () => {
      clearTimeout(timeout);
    };
  }, [alert]);
  if (!alert.message) return null;

  return (
    <Snackbar
      open={true}
      autoHideDuration={timeOut}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert onClose={handleClose} severity={alert.type} sx={{ width: "100%" }}>
        {alert.title && <AlertTitle>{alert.title}</AlertTitle>}
        {alert.message}
      </Alert>
    </Snackbar>
  );
};

export default AlertMessage;
