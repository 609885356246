import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  MenuItem,
  Select,
  Tabs,
  Tab,
  Typography,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Autocomplete,
  TextField,
  TablePagination,
  Chip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import "./messages.css";
import { messagesData } from "./messagesData";
import MessageShow from "./messageShow/messageShow";
import { useDispatch, useSelector } from "react-redux";
import { getQuickResponses, getTags } from "../../store/settingsSlice";
import { Close, CloseOutlined, SearchOutlined } from "@mui/icons-material";
import MessageDialog from "./messageShow/MessageDialog";
import {
  getInboxMessages,
  getOutboxMessages,
  getSentMessages,
  getTeamInboxMessages,
  receiveReply,
  setMessageTab,
} from "../../store/messagesSlice";
import AllMessagesTable from "./AllMessagesTable";
import DeleteMessageDialog from "./DeleteMessageDialog";

export const Messages = () => {
  const dispatch = useDispatch();
  const [messagesTabValue, setMessagesTabValue] = useState(0);
  const tabs = ["My Inbox", "Team Inbox", "My Outbox", "Sent Messages"];
  const [selectedTab, setSelectedTab] = useState("My Inbox");
  const [messageId, setMessageId] = useState(null);
  const openChatBox = useSelector((state) => state.messages.openChatBox);

  const [openMessageShow, setOpenMessageShow] = useState(openChatBox);
  const tags = useSelector((state) => state.settings.tags?.data);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpenMessageShow(openChatBox);
  }, [openChatBox]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const inboxMeta = useSelector((state) => state.messages.inboxMeta);
  const inboxMessages = useSelector((state) => state.messages.inboxMessages);
  const [inputvalue, setInputValue] = useState("");
  const [tagsUuids, setTagsUuids] = useState([]);

  const [page, setPage] = useState(inboxMeta?.current_page - 1);
  const [rowsPerPage, setRowsPerPage] = useState(inboxMeta?.per_page);

  const clearTags = () => {
    setTagsUuids([]); // Set the value to an empty array to clear selections
  };

  useEffect(() => {
    dispatch(getTags({ tag_query: "", page: 1, per_page: 10 }));
    dispatch(getInboxMessages());
    dispatch(getQuickResponses());
    // dispatch(receiveReply());
  }, []);

  const themeMode = useSelector((state) => state.themeMode.mode);

  const handleMessagesTabChange = (event, newValue) => {
    setMessagesTabValue(newValue);
  };

  const handleOpenMessage = (message) => {
    setMessageId(message.id);
    setOpenMessageShow(true);
  };

  const handleCloseMessage = () => {
    setMessageId(null);
    setOpenMessageShow(false);
  };

  const handleChangeTags = (event, newValue) => {
    setTagsUuids(newValue);
    if (selectedTab === "My Inbox") {
      if (messagesTabValue === 0) {
        dispatch(
          getInboxMessages({
            unread: false,
            query: inputvalue,
            tag_uuids: newValue.map((tag) => tag.uuid),
          })
        );
      } else {
        dispatch(
          getInboxMessages({
            unread: true,
            query: inputvalue,
            tag_uuids: newValue.map((tag) => tag.uuid),
          })
        );
      }
    } else if (selectedTab === "Team Inbox") {
      if (messagesTabValue === 0) {
        dispatch(
          getTeamInboxMessages({
            unread: false,
            query: inputvalue,
            tag_uuids: newValue.map((tag) => tag.uuid),
          })
        );
      } else {
        dispatch(
          getInboxMessages({
            unread: true,
            query: inputvalue,
            tag_uuids: newValue.map((tag) => tag.uuid),
          })
        );
      }
    } else if (selectedTab === "My Outbox") {
      dispatch(
        getOutboxMessages({
          unread: false,
          query: inputvalue,
          tag_uuids: newValue.map((tag) => tag.uuid),
        })
      );
    } else {
      dispatch(
        getSentMessages({
          unread: false,
          query: inputvalue,
          tag_uuids: newValue.map((tag) => tag.uuid),
        })
      );
    }

    console.log("Selected Objects:", newValue);
  };

  const truncatedString = (str) => {
    if (str.length > 80) {
      return str.slice(0, 80) + "...";
    }
    return str;
  };

  const filteredMessages =
    messagesTabValue === 1
      ? messagesData.filter((message) => !message.read)
      : messagesData;

  return (
    <div>
      <Container className="containerClass">
        <div className="titleContainer">
          <Typography
            className={`title1 ${
              themeMode === "dark" ? "text-light" : "text-dark"
            }`}
          >
            Messages
          </Typography>
          <div className="tabs">
            {tabs.map((tab) => (
              <Button
                key={tab}
                className={`tab 
                  ${
                    selectedTab === tab && themeMode === "dark"
                      ? "selected-dark"
                      : selectedTab === tab && themeMode === "light"
                      ? "selected-light"
                      : selectedTab != tab && themeMode === "dark"
                      ? "unselected-dark"
                      : "unselected-light"
                  }`}
                onClick={() => {
                  setInputValue("");
                  clearTags();
                  setSelectedTab(tab);
                  dispatch(setMessageTab(tab));
                  if (tab === "My Inbox") {
                    dispatch(
                      getInboxMessages({
                        page: 1,
                        per_page: 10,
                      })
                    );
                  } else if (tab === "Team Inbox") {
                    dispatch(
                      getTeamInboxMessages({
                        page: 1,
                        per_page: 10,
                      })
                    );
                  } else if (tab === "My Outbox") {
                    dispatch(
                      getOutboxMessages({
                        page: 1,
                        per_page: 10,
                      })
                    );
                  } else {
                    dispatch(
                      getSentMessages({
                        page: 1,
                        per_page: 10,
                      })
                    );
                  }
                }}
              >
                {tab}
              </Button>
            ))}
          </div>
          <div></div>
        </div>
        <Typography
          className={`py-4 ${
            themeMode === "dark"
              ? "dashboard-subtitle "
              : "dashboard-subtitle-light"
          }`}
        >
          View and manage your messages in this page.
        </Typography>
        <div className="lg:flex justify-between items-center">
          <Grid
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: { md: "row", sm: "row", xs: "column" },
            }}
            //className="lg:flex items-center lg:space-x-6 w-full space-y-6 lg:space-y-0"
          >
            <OutlinedInput
              id="outlined-adornment-password"
              className={
                themeMode === "dark"
                  ? "dashboard-field"
                  : "dashboard-field-light"
              }
              value={inputvalue}
              onChange={(e) => {
                setInputValue(e.target.value);
                if (selectedTab === "My Inbox") {
                  if (messagesTabValue === 0) {
                    dispatch(
                      getInboxMessages({
                        unread: false,
                        query: e.target.value,
                        tag_uuids:
                          tagsUuids.length > 0
                            ? tagsUuids.map((tag) => tag.uuid)
                            : [],
                      })
                    );
                  } else {
                    dispatch(
                      getInboxMessages({
                        unread: true,
                        query: e.target.value,
                        tag_uuids:
                          tagsUuids.length > 0
                            ? tagsUuids.map((tag) => tag.uuid)
                            : [],
                      })
                    );
                  }
                } else if (selectedTab === "Team Inbox") {
                  if (messagesTabValue === 0) {
                    dispatch(
                      getTeamInboxMessages({
                        unread: false,
                        query: e.target.value,
                        tag_uuids:
                          tagsUuids.length > 0
                            ? tagsUuids.map((tag) => tag.uuid)
                            : [],
                      })
                    );
                  } else {
                    dispatch(
                      getTeamInboxMessages({
                        unread: true,
                        query: e.target.value,
                        tag_uuids:
                          tagsUuids.length > 0
                            ? tagsUuids.map((tag) => tag.uuid)
                            : [],
                      })
                    );
                  }
                } else if (selectedTab === "My Outbox") {
                  dispatch(
                    getOutboxMessages({
                      unread: false,
                      query: e.target.value,
                      tag_uuids:
                        tagsUuids.length > 0
                          ? tagsUuids.map((tag) => tag.uuid)
                          : [],
                    })
                  );
                } else {
                  dispatch(
                    getSentMessages({
                      unread: false,
                      query: e.target.value,
                      tag_uuids:
                        tagsUuids.length > 0
                          ? tagsUuids.map((tag) => tag.uuid)
                          : [],
                    })
                  );
                }
              }}
              size="small"
              sx={{
                width: { md: 300, sm: 300, xs: "100%" },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
                "& .MuiInputBase-input": {
                  color: themeMode === "dark" ? "white" : "black",
                },
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <SearchOutlined
                      style={{
                        color: themeMode === "dark" ? "gray" : "darkgray",
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              }
              placeholder="Search"
            />

            <Autocomplete
              multiple
              freeSolo
              disablePortal
              value={tagsUuids}
              options={tags}
              getOptionLabel={(option) => option.title}
              onChange={handleChangeTags}
              sx={{ width: { md: 300, sm: 300, xs: "100%" } }}
              renderTags={(selected, getTagProps) =>
                selected.map((option, index) => (
                  <Chip
                    key={index}
                    label={option.title}
                    {...getTagProps({ index })}
                    // deleteIcon={
                    //   <CloseOutlined
                    //     style={{
                    //       color: themeMode === "dark" ? "white" : "black",
                    //     }}
                    //   />
                    // }
                    sx={{
                      backgroundColor:
                        themeMode === "dark" ? "gray" : "lightgray",
                      color: themeMode === "dark" ? "white" : "black",
                      "& .MuiChip-deleteIcon": {
                        color: "white",
                      },
                    }}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  placeholder="Search by Tag"
                  {...params}
                  className={
                    themeMode === "dark"
                      ? "dashboard-field"
                      : "dashboard-field-light"
                  }
                  size="small"
                  sx={{
                    my: { md: 0, sm: 0, xs: 2 },
                    mx: { md: 1.5, sm: 2, xs: 0 },
                    "& fieldset": {
                      border: "none",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent", // Change border color on hover
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent", // Change border color on hover
                      },
                    },
                    "&:hover .MuiInput-notched": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused .MuiTextFieldInput-notched": {
                      borderColor: "transparent",
                    },
                    "& .MuiInputBase-input": {
                      color: themeMode === "dark" ? "white" : "black",
                    },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <SearchOutlined
                          style={{
                            color: themeMode === "dark" ? "white" : "black",
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              )}
            />
            <Button
              className={`dashboard-clear-filters ${
                themeMode === "dark" ? "text-light" : "text-dark"
              }`}
              sx={{
                marginLeft: { md: 1.5, sm: 2, xs: 0 },
                textWrap: "nowrap",
                "&:hover": { background: "transparent" },
              }}
              onClick={() => {
                setInputValue("");
                if (selectedTab === "My Inbox") {
                  if (messagesTabValue === 0) {
                    dispatch(
                      getInboxMessages({
                        unread: false,
                        page: 1,
                        per_page: 10,
                      })
                    );
                    clearTags();
                  } else {
                    dispatch(
                      getInboxMessages({
                        unread: true,
                        page: 1,
                        per_page: 10,
                      })
                    );
                    clearTags();
                  }
                } else if (selectedTab === "Team Inbox") {
                  if (messagesTabValue === 0) {
                    dispatch(
                      getTeamInboxMessages({
                        unread: false,
                        page: 1,
                        per_page: 10,
                      })
                    );
                    clearTags();
                  } else {
                    dispatch(
                      getTeamInboxMessages({
                        unread: true,
                        page: 1,
                        per_page: 10,
                      })
                    );
                    clearTags();
                  }
                } else if (selectedTab === "My Outbox") {
                  dispatch(
                    getOutboxMessages({
                      unread: false,
                      page: 1,
                      per_page: 10,
                    })
                  );
                  clearTags();
                } else {
                  dispatch(
                    getSentMessages({
                      unread: false,
                      page: 1,
                      per_page: 10,
                    })
                  );
                  clearTags();
                }
              }}
            >
              Clear Filters
            </Button>
          </Grid>
        </div>
        <div
          className={
            themeMode === "dark"
              ? "messagesContainer"
              : "messagesContainerLight"
          }
        >
          <Box
            sx={{
              width: "100%",
              paddingBottom:
                selectedTab === "My Inbox" || selectedTab === "Team Inbox"
                  ? "0px"
                  : "12px",
            }}
          >
            {(selectedTab === "My Inbox" || selectedTab === "Team Inbox") && (
              <Tabs
                value={messagesTabValue}
                onChange={handleMessagesTabChange}
                aria-label="message tabs"
                className="mb-6"
                sx={{
                  "& .MuiTab-root": {
                    color: themeMode === "dark" ? "#FFF" : "black",
                    fontWeight: "bold",
                    color: themeMode === "dark" ? "#FFF" : "black",
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: "13px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    textTransform: "none",
                  },
                  "& .MuiTab-root.Mui-selected": {
                    color: themeMode === "dark" ? "#FFF" : "black",
                    fontWeight: "bold",
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: "13px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "normal",
                  },
                  "& .MuiTabs-indicator": {
                    backgroundColor:
                      themeMode === "dark" ? "whitesmoke" : "#0C0404",
                  },
                }}
              >
                <Tab
                  disableRipple
                  label="All Messages"
                  value={0}
                  onClick={() => dispatch(getInboxMessages({ unread: false }))}
                  sx={{ textTransform: "capitalize" }}
                />
                <Box sx={{ mx: 2, my: 2 }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2"
                    height="20"
                    viewBox="0 0 2 20"
                    fill="none"
                  >
                    <path
                      d="M1 0V40"
                      stroke={themeMode === "dark" ? "#FFF" : "#000"}
                      strokeWidth="1"
                    />
                  </svg>
                </Box>

                <Tab
                  disableRipple
                  label="Unread"
                  value={1}
                  onClick={() => dispatch(getInboxMessages({ unread: true }))}
                  sx={{ textTransform: "capitalize" }}
                />
              </Tabs>
            )}
          </Box>

          {messagesTabValue === 0 && (
            <AllMessagesTable
              messages={inboxMessages}
              handleOpenMessage={handleOpenMessage}
              handleClickOpenDelete={handleClickOpen}
            />
          )}
          {messagesTabValue === 1 && (
            <AllMessagesTable
              messages={inboxMessages}
              handleOpenMessage={handleOpenMessage}
              handleClickOpenDelete={handleClickOpen}
            />
          )}

          <MessageDialog
            openMessageShow={openMessageShow}
            closeMessageShow={handleCloseMessage}
            messageId={messageId}
          />
          <DeleteMessageDialog
            open={open}
            handleClose={handleClose}
            handleClickOpen={handleClickOpen}
          />
        </div>
      </Container>
    </div>
  );
};
