import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useSelector } from "react-redux";

const SaveChanges = () => {
    const themeMode = useSelector((state) => state.themeMode.mode);

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    maxWidth: 800,
                    padding: '20px',
                    color: '#fff',
                    marginTop: '20px',
                }}
            >
                <Typography variant="h4" gutterBottom sx={{ color: themeMode === "dark" ? 'white': 'black' }}>
                    Save Optimization
                </Typography>
                <Typography variant="body1" sx={{ color: themeMode === "dark" ? 'white': 'black', marginBottom: '40px' }}>
                    Saving all the changes will result in a integration of optimization in your campaign.
                </Typography>
                <Box sx={{ display: 'flex', gap: '20px' }}>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#b11b1b',
                            color: '#fff',
                            borderRadius: '30px',
                            border: '2px solid black',
                            padding: '10px 40px',
                            '&:hover': {
                            backgroundColor: '#9b1717',
                            },
                        }}
                    >
                        Save Settings
                    </Button>
                    <Button
                        variant="outlined"
                        sx={{
                            borderRadius: '30px',
                            border: themeMode === "dark" ? '2px solid white' : '2px solid black',
                            background: themeMode === "dark" ? 'rgba(0, 0, 0, 0.6)': '#dedede99',
                            color: themeMode === "dark" ? 'white': 'black',
                            padding: '10px 40px',
                            '&:hover': {
                            border: themeMode === "dark" ? '2px solid white' : '2px solid black',
                            backgroundColor: '#f0f0f099',
                            },
                        }}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        </>
    );
};
export default SaveChanges;