import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Avatar, IconButton } from "@mui/material";
import { LinkedIn } from "@mui/icons-material";

export default function OutOfNetworkTable() {
  const searchProspects = useSelector(
    (state) => state.campaign.searchProspects
  );
  const themeMode = useSelector((state) => state.themeMode.mode);
  return (
    <TableContainer sx={{ width: "100%", overflowX: "auto" }}>
      {searchProspects?.filter(
        (item) => item.network_distance === "OUT_OF_NETWORK"
      ).length == 0 && (
        <div className="flex w-full items-center justify-center text-center py-8">
          <span
            style={{
              color: themeMode === "dark" ? "white" : "black",
            }}
          >
            There are no records
          </span>{" "}
        </div>
      )}
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              className={
                themeMode === "dark"
                  ? "table-col-title"
                  : "table-col-title-light"
              }
              align="left"
            >
              Name{" "}
            </TableCell>
            <TableCell
              className={
                themeMode === "dark"
                  ? "table-col-title"
                  : "table-col-title-light"
              }
              align="left"
            >
              Current Job Title{" "}
            </TableCell>
            <TableCell
              className={
                themeMode === "dark"
                  ? "table-col-title"
                  : "table-col-title-light"
              }
              align="left"
            >
              Current Company{" "}
            </TableCell>
            <TableCell
              className={
                themeMode === "dark"
                  ? "table-col-title"
                  : "table-col-title-light"
              }
              align="left"
            >
              Location{" "}
            </TableCell>
            <TableCell
              className={
                themeMode === "dark"
                  ? "table-col-title"
                  : "table-col-title-light"
              }
              align="left"
            >
              Actions{" "}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {searchProspects
            ?.filter((item) => item.network_distance === "OUT_OF_NETWORK")
            .map((row, index) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                className="cursor-pointer"
              >
                <TableCell
                  className={
                    themeMode === "dark"
                      ? "table-row-content"
                      : "table-row-content-light"
                  }
                >
                  <span className="flex items-center space-x-2">
                    <IconButton href={row.profile_url} target="_blank">
                      <LinkedIn
                        sx={{ color: themeMode === "dark" ? "#FFF" : "#000" }}
                      />
                    </IconButton>
                    <Avatar
                      sx={{ width: 32, height: 32 }}
                      src={row.profile_picture_url}
                    />{" "}
                    <span> {row.name}</span>
                  </span>
                </TableCell>
                <TableCell
                  className={
                    themeMode === "dark"
                      ? "table-row-content"
                      : "table-row-content-light"
                  }
                  align="left"
                >
                  {row.current_job_title}
                </TableCell>
                <TableCell
                  className={
                    themeMode === "dark"
                      ? "table-row-content"
                      : "table-row-content-light"
                  }
                  align="left"
                >
                  {row.current_company}
                </TableCell>
                <TableCell
                  className={
                    themeMode === "dark"
                      ? "table-row-content"
                      : "table-row-content-light"
                  }
                  align="left"
                >
                  <span> {row.location}</span>
                </TableCell>
                <TableCell
                  className={
                    themeMode === "dark"
                      ? "table-row-stats"
                      : "table-row-stats-light"
                  }
                  align="left"
                >
                  <span style={{ color: "#FF0000" }} className="font-bold">
                    Automation Disabled
                  </span>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
